import Avatar from "images/banner_images/banner_1.png";
import CheckMarkImg from "images/home/check.svg";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import { withRouter } from "react-router";

function Benefits(props: any) {
  let { getValue } = props;
  return (
    <div className="w-full">
      <div className="w-full">
        <img src={Avatar} className="h-400 w-full" alt="" />
      </div>
      {/* <div>
        <p className=" whitespace_prewrap text-md text-neutral-500 font-bold py-4">
          {getValue(1)}
        </p>
      </div> */}
      <div className="py-4">
        <h2 className="text-xl font-medium text-neutral-600">
          <span className=""> {getValue(1)}</span>
        </h2>
      </div>
      <div className="flex py-8">
        {/* 1st */}
        <div className=" w-1/3">
          <div className="flex">
            {/* <div> */}
              <img src={CheckMarkImg} alt="" className="benefits-checkmark" />
            {/* </div> */}
            <span className="px-2 text-base md:text-lg text-neutral-500 dark:text-neutral-400 benefits-checkmark-title">
              {getValue(2)}
            </span>
          </div>
          <div>
            <p className="whitespace_prewrap text-md text-neutral-500 py-4">
              {getValue(5)}
            </p>
          </div>
        </div>
        {/* 2nd */}
        <div className=" w-1/3 px-4">
          <div className="flex">
            {/* <div> */}
              <img src={CheckMarkImg} alt="" className="benefits-checkmark" />
            {/* </div> */}
            <span className="px-2 text-base md:text-lg text-neutral-500 dark:text-neutral-400 benefits-checkmark-title">
              {getValue(3)}
            </span>
          </div>
          <div>
            <p className="whitespace_prewrap text-md text-neutral-500 py-4 ">
              {getValue(8)}
            </p>
          </div>
        </div>
        {/* 3rd */}
        <div className=" w-1/3 px-4">
          <div className="flex">
            {/* <div> */}
              <img src={CheckMarkImg} alt="" className="benefits-checkmark" />
            {/* </div> */}
            <span className="px-2 text-base md:text-lg text-neutral-500 dark:text-neutral-400 benefits-checkmark-title">
              {getValue(4)}
            </span>
          </div>
          <div>
            <p className="whitespace_prewrap text-md text-neutral-500 py-4">
              {getValue(9)}
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <SectionCalculateBenefit
          title={
            getValue ? getValue(10) : "How we can help you to reach label A ?"
          }
          searchText={getValue ? getValue(6) : "Search Address"}
          buttonText={getValue ? getValue(7) : "Mijn diagnose"}
          modelText={getValue ? getValue(197) : ""}
        />
      </div>
      {/* <div className="container relative py-16 mt-0 w-2/3">
        <SectionHomeTestimonials getValue={[]} />
      </div> */}
    </div>
  );
}

export default withRouter(Benefits);
