import React, { FC, useEffect } from "react";

// import House from "images/resultPage/house.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { loadModules } from 'esri-loader';
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
export interface SectionHeroProps {
  className?: string;
  getResultContent?: any;
  openModal?: any;
  allRenovationRecord?: any;
  userStatus?: any;
  loggedInUser?: any;
  refinementCall3Record?: any;
  isCallWaitng?: any;
  isAdminView?: any
}

const SectionHouse: FC<SectionHeroProps> = ({
  className,
  getResultContent,
  openModal,
  allRenovationRecord,
  userStatus,
  loggedInUser,
  refinementCall3Record,
  isCallWaitng,
  isAdminView
}) => {
  
  const getBuildingName = (value: any) => {
    switch (value) {
      case "terraced_building":
        return getResultContent(139);
      case "detached_building":
        return getResultContent(137);
      case "semi_detached_building":
        return getResultContent(138);
      default:
        return "";
    }
  };

  const house_detail = [
    {
      key: getResultContent ? getResultContent(4) : "",
      icon: "la-key",
      value:
        refinementCall3Record &&
        refinementCall3Record?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.construction_year !== null
          ? parseInt(refinementCall3Record?.construction_year)
          : allRenovationRecord?.construction_year !== null
          ? allRenovationRecord?.construction_year
          : 0,
      show: allRenovationRecord?.construction_year !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(7) : "",
      icon: "la-smoking",
      value:
        refinementCall3Record &&
        refinementCall3Record?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.num_residents !== null
          ? Number(refinementCall3Record?.residents)
          : allRenovationRecord?.num_residents !== null
          ? allRenovationRecord?.num_residents
          : 0,
      show: allRenovationRecord?.num_residents !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(6) : "",
      icon: "la-shower",
      value:
        refinementCall3Record &&
        refinementCall3Record?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.floor_area !== null
          ? Math.round(refinementCall3Record?.floor_area)
          : allRenovationRecord?.floor_area !== null
          ? Math.round(allRenovationRecord?.floor_area)
          : 0,
      show: allRenovationRecord?.floor_area !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(5) : "",
      icon: "la-luggage-cart",
      value:
        refinementCall3Record &&
        refinementCall3Record?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.floors !== null
          ? refinementCall3Record?.floors
          : allRenovationRecord?.floors,
      show: allRenovationRecord?.floors !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(3) : "",
      icon: "la-key",
      value:
        refinementCall3Record &&
        refinementCall3Record?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.building_type !== null
          ? getBuildingName(refinementCall3Record?.building_type)
          : getBuildingName(allRenovationRecord?.builiding_type),
      // refinementCall3Record &&
      // refinementCall3Record?.building_type !== undefined
      //   ? buildingSavedValue[0] + " " + buildingSavedValue[1]
      //   : allRenovationRecord?.builiding_type !== null
      //   ? buildingDefaultValue[0] + " " + buildingDefaultValue[1]
      //   : "-",
      show: allRenovationRecord?.builiding_type !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(15) : "",
      icon: "la-smoking",
      value:
        refinementCall3Record && allRenovationRecord?.attic_storage !== null
          ? allRenovationRecord?.attic_storage
          : 0,
      show: allRenovationRecord?.attic_storage !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(12) : "",
      icon: "la-smoking",
      value:
        refinementCall3Record &&
        refinementCall3Record?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.construction_year !== null &&
        refinementCall3Record?.has_basement === 1
          ? refinementCall3Record?.has_basement
          : allRenovationRecord?.has_basement !== null
          ? allRenovationRecord?.has_basement
          : 0,
      show:
        allRenovationRecord?.has_basement !== null &&
        allRenovationRecord?.has_basement !== 0
          ? 1
          : 0,
    },
  ];

  const map3D =  () => {
     loadModules([
      "esri/config",
      "esri/WebScene",
      "esri/views/SceneView",
      "esri/core/urlUtils",
    ]).then(([esriConfig, WebScene, SceneView, urlUtils]) => {
				esriConfig.apiKey = "AAPK681f6a2259734ec2be5f85609746fb3aTjzO_GWRDB7cvtXHfG6qlEtwGDavH5vvD_hOQErEZYoxvxcqcndd1WSIr3ok9iA0";
        const webscene = new WebScene({
          portalItem: {
            id: "654e383568764254b7da41d9c0f898a4",
          },
        });

        const view = new SceneView({
          container: "viewDiv",
          map: webscene,
          environment: {
            atmosphere: {
              quality: "high",
            },
            lighting: {
              directShadowsEnabled: true,
            },
          },
          highlightOptions: {
            haloColor: [255, 38, 150],
            color: [255, 255, 255],
            fillOpacity: 0.3,
          },
        });

        let highlight: any = null;
        let objectIds: any = [];
        

        var qObject = urlUtils.urlToObject(document.URL.split('&')[0] ? document.URL.split('&')[0] : document.URL );
        var whereDict;
        var whereStr: any;
        function convert(obj: any) {
          whereDict = Object.keys(obj).map((key) => ({
            name: key,
            value: obj[key],
          }));
          whereStr = "";
          whereDict.forEach((element) => {
            if (isNaN(element.value)) {
              whereStr = whereStr.concat(
                element.name,
                "='",
                element.value,
                "'"
              );
            } else {
              whereStr = whereStr.concat(element.name, "=", element.value);
            }
          });
          return whereStr;
        }
        webscene.when(() => {
          const buildingLayer = webscene.layers.find((l: any) => {
            return l.title === "AGC Buildings";
          });
          const defquery = buildingLayer.createQuery();
          defquery.where = "1=1";
          if (qObject.query) {
            convert(qObject.query);
            defquery.where = whereStr;
          }
          defquery.outFields = ["*"];
          defquery.returnGeometry = true;
          buildingLayer.popupEnabled = false;
          // retrieve the layer view of the building layer
          view.whenLayerView(buildingLayer).then((buildingLayerView: any) => {
            // Wait for the layer view to finish updating
            buildingLayerView.watch("updating", (val: any) => {
              if (!val) {
                buildingLayer
                  .when(function () {
                    return buildingLayer.queryFeatures(defquery);
                  })
                  .then((response: any) => {
                    response.features.forEach((feature: any) => {
                      objectIds.push(feature.getObjectId());
                    });
                  });
                buildingLayer
                  .when(function () {
                    return buildingLayer.queryExtent(defquery);
                  })
                  .then((response: any) => {
                    if (response.extent) {
                      view
                        .goTo(
                          {
                            target: response.extent.expand(4),
                            speedFactor: 0.5,
                            tilt: 60,
                          },
                          {
                            duration: 1000,
                            easing: "out-expo",
                          }
                        )
                        .catch((error: any) => {
                          if (error.name != "AbortError") {
                            console.error(error);
                          }
                        });
                    }
                  });
                // buildingLayerView;
                if (highlight) {
                  highlight.remove();
                }
                // Highlight the feature passing the objectId to the method
                let fLen = objectIds.length;
               
                objectIds.forEach((element: any) => {
                  highlight = buildingLayerView.highlight([element]);
                });
              }
            });
          });
        });
        view.ui.remove(["attribution", "navigation-toggle", "compass", "zoom"]);
      })
      .catch((err) => {
        console.log("🚀 ~ file: SectionHouse.tsx ~ line 274 ~ map3D ~ err", err)
        console.error(err);
      });
  };

  const addressHandler = (name: any) => {
    const house = name?.split(/(\d+)/);
    const street = name?.split(/[0-9A-Z]/)[0];
    let firstAddress: any = "";
    let lastElement: any = "";
    house?.map((item: any) => {
      if (firstAddress === "" && item === street) {
        firstAddress = street;
        lastElement = house[house?.length - 2] + " " + house[house?.length - 1];
        // house[house?.length - 3] = ",";
        house[house?.length - 2] = "";
        house[house?.length - 1] = "";
      } else {
        firstAddress = firstAddress + item;
      }
    });
    firstAddress = firstAddress?.replace(" -",", ")
    return {
      firstAddress: firstAddress?.replace(" ,",", "),
      lastAddress: lastElement,
    };
  };

  useEffect(() => {
    map3D();
  }, []);
  return (
    <>
      <div
        className={`listingSection__wrap house_container_result section_width mx-2 h-auto p-0 ${className} flex flex-col justify-between`}
      >
        <div className="flex flex-col lg:flex-row xl:flex-row justify-between">
          {/* left side */}
          <div className="house_map">
            <div className="w-full">
              {/* <img src={House} alt="" /> */}
              <div id="viewDiv"></div>
            </div>
          </div>
          {/* right side */}
          <div className="grid grid-cols-1 lg:grid-cols-1 text-sm text-neutral-700 dark:text-neutral-300 w-1/2 px-3 lg:px-6 ">
            <div className="pt-4">
              <h4 className="font-medium text-2xl">
                {
                  addressHandler(allRenovationRecord?.formatted_address)
                    ?.firstAddress
                }
                <br />
                {
                  addressHandler(allRenovationRecord?.formatted_address)
                    ?.lastAddress
                }
              </h4>
            </div>
            {house_detail?.map((item, i) => (
              <React.Fragment key={i}>
                {item?.show === 1 && (
                  <div key={i} className={`flex py-2 space-x-3 `}>
                    {/* <Avatar sizeClass="h-10 w-10" radius="rounded-full" /> */}
                    <div className="flex">
                      <span className="font-semibold">{item?.key}: </span>
                      <span className="px-2">{item?.value}</span>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        {/* footer */}
        <div className=" flex mt-0 w-full">
          <div className="flex justify-end mt-0 px-8 py-8 bg-neutral-100 w-full border-b-r-10 border-b-l-10">
            {isCallWaitng ? 
            <ButtonPrimary  className="outline-primary-button"> 
            <Spin indicator={antIcon} />
            </ButtonPrimary> :
             ((userStatus?.data?.status < 3) || userStatus?.data == null || userStatus?.data?.status == undefined) && (
              !isAdminView && <ButtonPrimary
                className={"outline-primary-button"}
                onClick={() => {
                  openModal("refine-information");
                }}
              >
                {getResultContent(22)}
              </ButtonPrimary>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionHouse;
