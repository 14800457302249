import Avatar from "images/renovations/banner/1.png";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import Window from "images/renovations_images/window_double.png";
import Glazing from "images/renovations/Glazing.gif";
import { withRouter } from "react-router";

function Benefits(props: any) {
  let { getValue } = props;
  return (
    <div className="w-full">
      <div className="w-full">
        <img src={Avatar} className="h-400 w-full" alt="" />
      </div>
      <div>
        <h2 className="text-xl text-neutral-600 py-4">
          <span className="whitespace_prewrap">{getValue(1)}</span>
        </h2>
      </div>
      <div>
        <p className="text-md text-neutral-500 py-4">{getValue(27)}</p>
      </div>
      <div className={getValue(28) && "flex py-8"}>
        {/* 1st */}
        <div className=" w-1/2 pr-4">
          <div className="flex">
            <span className="text-base md:text-lg font-bold text-neutral-500 dark:text-neutral-400">
              {getValue(28)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4 whitespace_prewrap">{getValue(29)}</h2>
          </div>
        </div>
        {/* 2nd */}
        <div className=" w-1/2">
          <div className="flex justify-between full-width-responsive">
            <div>
              <div>
                <img src={Window} alt="" className="h-40" />
              </div>
              <div>
                <h4 className="text-md text-neutral-500 py-4 text-center">
                  {" "}
                  {getValue(31)}
                </h4>
              </div>
            </div>
            <div>
              <div>
                <img src={Glazing} alt="" className="h-40" />
              </div>
              <div>
                <h4 className="text-md text-neutral-500 py-4 text-center">
                  {" "}
                  {getValue(33)}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={getValue(34) && "flex py-8"}>
        {/* 1st */}
        <div className=" w-1/2 pr-4">
          {getValue(34) && <div className="flex">
            <span className="text-base md:text-lg font-bold text-neutral-500 dark:text-neutral-400">
              {getValue(34)}
            </span>
          </div>}
          {getValue(35) && <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">{getValue(35)}</span>
            </h2>
          </div>}
        </div>
        {/* 2nd */}
        {getValue(36) && <div className=" w-1/2 px-4">
          <div className="flex">
            <span className="text-base md:text-lg font-bold text-neutral-500 dark:text-neutral-400">
              {getValue(36)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4 ">
              <span className="whitespace_prewrap">{getValue(37)}</span>
            </h2>
          </div>
        </div>}
      </div>
      <div className="flex justify-center items-center">
        <SectionCalculateBenefit
          title={
            getValue ? getValue(38) : "How we can help you to reach label A ?"
          }
          searchText={getValue ? getValue(41) : "Search Address"}
          buttonText={getValue ? getValue(39) : "Mijn diagnose"}
          modelText={getValue ? getValue(146) : ""}
        />
      </div>
    </div>
  );
}

export default withRouter(Benefits);
