import SideNav from "./SideNav/SideNav";
import Neighborhood from "./SectionsBenefits/Neighborhood";
import SupportSubsidies from "./SectionsBenefits/SupportSubsidies";
import ClassicRenovations from "./SectionsBenefits/ClassicRenovations";
import Process from "./SectionsBenefits/how-it-works";
import { useEffect, useState } from "react";
import { GetAllFaqsByService } from "../../services/faq";
import axios from "axios";

function Benefits(props: any) {
  const { getValue } = props;

  const [benefitsPage, setBenefitsPage] = useState("neighborhood");
  const [content, setContent] = useState([]);
  const [faqContent, setFaqContent] = useState([]);
  const [faqTitles, setFaqTitles]: any = useState();

  const getActiveTabContent = (value: any) => {
    let filteredData = faqContent?.filter(
      (single: any) => single?.category === value
    );
    setContent(filteredData);
  };

  const benefitsPageHandler = (value: any) => {
    switch (value) {
      case "neighborhood":
        return <Neighborhood getValue={getValue} content={content} />;
      case "support-subsidies":
        return <SupportSubsidies getValue={getValue} content={content} />;
      case "renovations":
        return <ClassicRenovations getValue={getValue} content={content} />;
      case "how-it-works":
        return <Process getValue={getValue} content={content} />;
      default:
        return <Neighborhood getValue={getValue} content={content} />;
    }
  };

  useEffect(() => {
    GetAllFaqsByService().then((response: any) => {
      setFaqContent(response?.data);
    });
  }, []);

  useEffect(() => {
    getActiveTabContent(1);
  }, [faqContent]);

  useEffect(() => {
    // api call to get all menus
    // PageContentByService(1, "menu")
    let payload = { city_id: 1, datatype: "menu" };
    axios
      .post(process.env.REACT_APP_APP_URL + `/getCityDataByType`, payload)
      .then((res) => {
        setFaqTitles(res?.data?.data?.pages[5]?.page_contents);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="nc-faq-container relative overflow-hidden flex">
      <div className="w-33p">
        <SideNav
          getActiveTabContent={getActiveTabContent}
          setBenefitsPage={setBenefitsPage}
          benefitsPage={benefitsPage}
          faqTitles={faqTitles}
          setFaqContent={setFaqContent}
        />
      </div>
      <div className="w-67p py-4">{benefitsPageHandler(benefitsPage)}</div>
    </div>
  );
}

export default Benefits;
