import { withRouter } from "react-router-dom";
import logoImg from "images/white_light.svg";
import React, { useEffect, useState } from "react";

const MenuBox = (props: any) => {
  let { history, header, menus } = props;
  const location = history?.location?.pathname;
  const [activePage, setActivePage] = useState(null);
  const pageHandler = (key: string) => {
    switch (key) {
      case "Home-Main":
        return "/";
      case "Benefits":
        return "/benefits";
      case "What we renovate for you":
        return "/renovates";
      case "The process":
        return "/process";
      case "Who we are":
        return "/who-we-are";
      case "FAQ":
        return "/faq";
      case "Contact us":
        return "/contact-us";
      default:
        return "/";
    }
  };
  const activePageHandler = (key: any, index: any) => {
    if (key === "What we renovate for you" && location === "/renovates") {
      return "active-page";
    } else if (key === "Benefits" && location === "/benefits") {
      return "active-page";
    } else if (key === "The process" && location === "/process") {
      return "active-page";
    } else if (key === "Who we are" && location === "/who-we-are") {
      return "active-page";
    } else if (key === "FAQ" && location === "/faq") {
      return "active-page";
    } else if (key === "Contact us" && location === "/contact-us") {
      return "active-page";
    } else {
      return "";
    }
  };

  return (
    <>
      {header === "header-added" && (
        <div
          className={`nc-MenuBox bg-primary-400 nc-Header shadow-lg lg:sticky w-full lg:left-0 lg:right-0 z-30 hidden lg:flex items-center homepage-menuBox-container relative`}
          data-nc-id="MenuBox"
        >
          <div className="container flex justify-center flex-column">
            <div className="logo-container flex items-center cursor-pointer">
              <img
                src={logoImg}
                alt=""
                className="h-8"
                onClick={() => {
                  history.push("/");
                }}
              />
            </div>
            <ul className="flex justify-center items-center w-full">
              {menus?.map((menu: any, index: any) => (
                <React.Fragment>
                  {menu?.title === "Home-Main" || menu?.title === "Profile" ? (
                    ""
                  ) : (
                    <li
                      key={index}
                      className={`${activePageHandler(
                        menu?.decription,
                        index
                      )}`}
                      onClick={() => {
                        history.push(pageHandler(menu?.decription));
                        setActivePage(index);
                      }}
                    >
                      {menu?.title}
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(MenuBox);
