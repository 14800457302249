import CheckImage from "../../images/check_3.png";
import { Button } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const Modal = (props: any) => {
  let { getResultContent, closeModal, loader } = props;
  return (
    <div className="absolute w-full z-40 top-0">
      <div className="h-full fixed w-full background_rgba flex justify-center refinement_house_modal_one_container">
        <div className="overflow_y_scroll py-8 w-full flex justify-center">
          <div className="inline-flex h-fit-content flex-col w-full lg:w-3/4 text-left align-middle transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
            <div className="px-8 py-8 flex flex-col items-center">
              <div className="px-4 text-center">
                <p className="text-center text-lg text-blue-500">
                  {getResultContent && getResultContent(231)}
                </p>
              </div>
              {loader ? (
                <div className="pt-4">
                  <Spin indicator={antIcon} />
                </div>
              ) : (
                <div className="pt-4">
                  <img src={CheckImage} alt="" />
                </div>
              )}
            </div>
            <div className="px-6 py-4 flex justify-center border-b border-neutral-100 bg-neutral-100 dark:border-neutral-800 text-center">
              {!loader && (
                <Button type="primary" size={"large"} onClick={closeModal}>
                  Verder Gaan
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
