import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { RenocityBenefitsType } from "data/types";

export interface CardCategory3Props {
  className?: string;
  taxonomy: RenocityBenefitsType;
  index: Number
}

const CardCategory3: FC<CardCategory3Props> = ({
  className = "",
  taxonomy,
  index
}) => {
  const { title, hoverText, href = "/", thumbnail } = taxonomy;
  return (
    <div
      // to={href}
      className={`nc-CardCategory6 cardCateory6_container rounded-2xl border p-1 home-page-benefits  flex flex-col ${className}`}
      data-nc-id="CardCategory3"
    >
      <div>
        <div
          className={`img-content-container flex-shrink-0 relative w-full aspect-w-3 aspect-h-3 sm:aspect-h-3 h-0 rounded-2xl group`}
        >
          <div className="hover-content z-10 hover_box rounded-2xl">
            <div className="h-full">
              <p className={`block text-center whitespace_prewrap h-full p-4`}>
                {hoverText}
              </p>
            </div>
          </div>
          <NcImage
            src={thumbnail}
            className="object-cover w-full h-full rounded-2xl "
          />
          <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
        </div>
        <div className="z-10 flex items-end justify-center">
          <h2
            className={`text-base text-center text-neutral-900 wrap-text dark:text-neutral-100 font-medium truncate pt-4`}
          >
            {title}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default CardCategory3;
