import ButtonClose from "shared/ButtonClose/ButtonClose";

const Modal = (props: any) => {
  let { setModel, getValue } = props;
  return (
    <div className="absolute w-full z-40 top-0">
      <div className="h-full fixed  mb-4 w-full background_rgba flex justify-center">
        <div className="inline-block w-full flex justify-center">
          <div className="inline-flex h-fit-content flex-col w-full lg:w-3/4 text-left align-middle transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
            <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
              <h3
                className="text-sm m-0 text-left text-gray-500"
                id="headlessui-dialog-title-70"
              >
                {getValue(75)}
              </h3>
              <span className="absolute right-3 top-3">
                <ButtonClose onClick={() => setModel(false)} />
              </span>
            </div>
            <div className="px-8 py-8 flex flex-col items-center">
              <div className="px-4 text-center text-gray-500">
                <p className="text-center text-lg"> {getValue(76)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
