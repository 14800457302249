import axios from "axios";
require("dotenv").config();

//post api for menus
export const PageContentByService = async (city_id: number, datatype: string) => {
  const data = await axios.post(process.env.REACT_APP_APP_URL + `/getCityDataByType`, {
    city_id,
    datatype,
  });
  return data;
};
//post api to gell all contents of homepage
export const GetContentByService = async (id: number) => {
  const data = await axios.post(process.env.REACT_APP_APP_URL + "/getSinglePageData", id);
  return data;
};
//post api to gell all contents of homepage
export const GetAllRenovationsByService = async (id: Object) => {
  const data =await axios.post(process.env.REACT_APP_APP_URL + "/getebecsuserdata", id);
  return data;
};
//post api to gell status of user (after login api)
export const GetAllUserProfileStatusByService = async (userId: any) => {
  const data= await axios.get(process.env.REACT_APP_APP_URL + `/user/profile/${userId}`);
  return data;
};
//post api to gell status of user (after login api)
export const SetUserProfileStatusByService = async (userData: any) => {
  const data = await axios.post(process.env.REACT_APP_APP_URL + `/user/profile`, userData);
  return data;
};

//get api to gell single user record
export const GetSingleUserBuildingStatusByService = async (
  userId: any,
  userDataId: any
) => {
 const data= await axios.get(
    process.env.REACT_APP_APP_URL + `/user/profile/${userId}/${userDataId}`
  );
  return data;
};

//post api to generate temp id against user.
export const GenerateTempIdAgainsUserService = async (request: Object) => {
const data = await axios.post(
    process.env.REACT_APP_APP_URL + "/user/generatetempid",
    request
  )
  return data;
};

//post api to generate temp id against building.
export const GenerateTempIdAgainsBuildingService = async (request: Object) => {
  const data = await axios.post(
    process.env.REACT_APP_APP_URL + "/ebec_call_one_tow_three_template",
    request
  );
  return data;
};

//put api to update renovation against user and building. (left side modal call 3)
export const UpdateBuildingRefinementCall3ByService = async (request: Object) => {
  const data = await axios.post(
    process.env.REACT_APP_APP_URL + "/ebec_call_three_filter_input",
    request,
    { timeout: 1200000 }
  );
  return data;
};

//put api to update renovation against user and building.(right side modal call 2)
export const UpdateBuildingRefinementCall2ByService = async (request: Object) => {
  const data = await axios.post(
    process.env.REACT_APP_APP_URL + "/refinement_calltwo",
    request,
    { timeout: 1200000 }
  );
  return data;
};

//post api to get loggedin User
export const getUserSelectedDataService = async (request: Object) => {
  const data = await axios.post(
    process.env.REACT_APP_APP_URL + "/getebecsuserdataloggedin",
    request
  );
  return data;
};

//get user profile 
export const GetUserProfileByService = async (userId: any) => {
console.log("🚀 ~ file: ResultPage.tsx ~ line 93 ~ GetUserProfileByService ~ userId", process.env.REACT_APP_APP_URL + `/userProfile/${userId}`)
  const data= await axios.get(process.env.REACT_APP_APP_URL + `/userProfile/${userId}`);
  return data;
};
