import React from "react";
import { Helmet } from "react-helmet";
import Benefits from "components/Benefits";

function PageHome() {
	return (
		<div className="nc-PageHome relative overflow-hidden">
			<Helmet>
				<title>Renocity</title>
			</Helmet>
			<div className="container pt-8 pb-14">
				<Benefits />
			</div>
		</div>
	);
}

export default PageHome;
