import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Step1 from "images/benefits/Step1.png";
import Step2 from "images/benefits/steps/step_2.png";
import Step3 from "images/benefits/steps/step_3.png";
import Step4 from "images/benefits/steps/step_4.png";
import Step5 from "images/benefits/steps/step__5.png";
import Step6 from "images/benefits/steps/step_6.png";
import Step7 from "images/benefits/steps/step__7.png";
import Step7b from "images/benefits/Step7bis.svg";
import NcImage from "shared/NcImage/NcImage";

export interface SectionSubscribe2Props {
  className?: string;
  sequence?: any;
  image?: any;
  getValue?: any;
  setAuditModal?: any;
  openModal?:any;
  login?:any;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({
  getValue,
  setAuditModal,
  openModal,
  login
}) => {
  return (
    <>
      <div
        className={`px-8  relative flex lg:justify-between pb-14 flex-col lg:flex-row  `}
        data-nc-id="SectionSubscribe2"
      >
        <div className="mb-10 w-1/2 lg:mb-0 lg:mr-10">
          <h2 className="font-semibold text-sm text-blue-500">
            {getValue(53)}
          </h2>
          <span className="block text-neutral-700 dark:text-neutral-400">
            {getValue(54)}
          </span>
          <ul className="space-y-4 py-4">
            <li className="flex items-center space-x-4">
              <span className="text-neutral-700 dark:text-neutral-300">
                {getValue(55)}
              </span>
            </li>
          </ul>
        </div>
        <div className="w-1/2">
          <NcImage
            src={Step1}
            className="w-1/2"
            containerClassName="flex justify-end"
          />
        </div>
      </div>
      <div
        className={`px-8 lg:justify-between flex flex-col pb-14 pt-14 lg:flex-row `}
        data-nc-id="SectionSubscribe2"
      >
        <div className="w-1/2 flex flex-row justify-center">
          <NcImage
            src={Step2}
            className=""
            containerClassName="flex justify-start"
          />
        </div>
        <div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 flex justify-center">
          <div>
            <h2 className="font-semibold text-sm text-blue-500">
              {getValue(57)}
            </h2>
            <span className="block text-neutral-700 dark:text-neutral-400">
              {getValue(58)}
            </span>
          </div>
        </div>
      </div>
      <div
        className={`px-8 lg:justify-between flex flex-col pb-14 pt-14 lg:flex-row `}
        data-nc-id="SectionSubscribe2"
      >
        <div className="mb-10 w-full lg:mb-0 lg:mr-10">
          <div>
            <h2 className="font-semibold text-sm text-blue-500">
              {getValue(60)}
            </h2>
            <span className="block text-neutral-700 dark:text-neutral-400">
              {getValue(153)}
            </span>
            <div>
              <NcImage
                src={Step3}
                className="w-full"
                containerClassName="flex justify-start"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`px-8 lg:justify-between flex flex-col pb-14 pt-14 lg:flex-row `}
        data-nc-id="SectionSubscribe2"
      >
        <div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 flex justify-center">
          <div>
            <h2 className="font-semibold text-sm text-blue-500">
              {getValue(62)}
            </h2>
            <span className="block text-neutral-700 dark:text-neutral-400">
              {getValue(63)}
            </span>
          </div>
        </div>
        <div className="w-1/2 flex flex-row justify-center">
          <NcImage
            src={Step4}
            className=""
            containerClassName="flex justify-start"
          />
        </div>
      </div>
      <div
        className={`px-8 lg:justify-between flex flex-col pb-14 pt-14 lg:flex-row `}
        data-nc-id="SectionSubscribe2"
      >
        <div className="w-1/2 flex flex-row justify-center">
          <NcImage
            src={Step5}
            className=""
            containerClassName="flex justify-start"
          />
        </div>
        <div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 flex justify-center">
          <div>
            <h2 className="font-semibold text-sm text-blue-500">
              {getValue(65)}
            </h2>
            <span className="block text-neutral-700 dark:text-neutral-400">
              {getValue(134)}
            </span>
          </div>
        </div>
      </div>
      <div
        className={`px-8  relative flex lg:justify-between pt-14 pb-14 flex-col lg:flex-row  `}
        data-nc-id="SectionSubscribe2"
      >
        <div className="mb-10 w-1/2 lg:mb-0 lg:mr-10">
          <h2 className="font-semibold text-sm text-blue-500">
            {getValue(136)}
          </h2>
          <span className="block text-neutral-700 dark:text-neutral-400">
            {getValue(137)}
          </span>
        </div>
        <div className="w-1/2">
          <NcImage
            src={Step6}
            className="w-full"
            containerClassName="flex justify-end"
          />
        </div>
      </div>
      <div
        className={`px-8  relative flex lg:justify-between pt-14 pb-14 flex-col lg:flex-row  `}
        data-nc-id="SectionSubscribe2"
      >
        <div className="w-1/2">
          <NcImage
            src={Step7}
            className="w-full"
            containerClassName="flex justify-end"
          />
        </div>
        <div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 px-4 py-2 flex">
          <div>
            <h2 className="font-semibold text-sm text-blue-500">
              {getValue(139)}
            </h2>
            <span className="block text-neutral-700 dark:text-neutral-400">
              {getValue(140)}
            </span>
          </div>
          {/* <div className="flex items-center">
            <NcImage
              src={Step7b}
              className="w-full"
              containerClassName="flex justify-end"
            />
          </div> */}
        </div>
      </div>
      <div
        className={`px-8  relative flex lg:justify-between pt-14 pb-14 flex-col lg:flex-row  `}
        data-nc-id="SectionSubscribe2"
      >
        <div className="mb-10 w-full flex flex-col items-center lg:mb-0 lg:mr-10 px-4 py-2">
          <ButtonPrimary
            onClick={() => login? setAuditModal(true) : openModal('login')}
            className="rounded bg-primary-500 flex items-center"
          >
            <span className="px-2"> {getValue(143)}</span>
          </ButtonPrimary>
          <span className="block text-neutral-700 py-4 text-base dark:text-neutral-400">
            {getValue(144)}
          </span>
        </div>
      </div>
    </>
  );
};

export default SectionSubscribe2;
