import { useState } from "react";
import { Input, Checkbox, Radio } from "antd";
import Button from "shared/Button/ButtonPrimary";
import { userSignupService, createHubsportUserByService } from "services/login";
import { withRouter } from "react-router-dom";
import TermsAndConditions from "./Terms&Conditions";
import cookie from "react-cookies";

const Modal = (props: any) => {
  let {
    getValue,
    setCurrent,
    openModal,
    isOpenModal,
    closeModal,
    setLogin,
    setFutureEpc,
    setPackLabel,
  } = props;
  const [userCredentials, setUsersCredentials] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone: "",
    contact_by_phone: "1",
    contact_by_email: "0",
  });
  const [model, setModel] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [error, setError] = useState("");
  const [succesMessage, setSuccessMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [isPrivacy, setIsPrivacy]= useState(false);
  const [isContact, setIsContect] =useState(false);

  const validateEmail = (inputText: any)=> {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(inputText.match(mailformat)){ return true; }
    else {
       return false;
       }
  }

  const validateDomain = (inputText: any)=> {
    
      if(inputText.includes('@')){ return true; }
      else {
         return false;
         }
    }


  const createUser = (e: any) => {
    e.preventDefault();   
    if(!isPrivacy || !isContact){
      if(!isPrivacy){
        setError(getValue(105));
      } else{
        setError(getValue(106));
      }      
    } else{
    setLoader(true);
    if (userCredentials?.password !== confirmPassword) {
      setPasswordValidation(true);
      setLoader(false);
    } else {
      setError('');
      const portalId = "25186371";
      const formGuid = "e55211a7-2863-4972-a5ae-797575b19ef0";
      const config = {
        // important!
        headers: {
          "Content-Type": "application/json",
        },
      };
      userSignupService(userCredentials)
        .then((response: any) => {
          cookie.save("token", response.data.token, {
            maxAge: 15000,
            path: "/",
          });
          setSuccessMessage("User Created successfully");
          setPasswordValidation(false);
          setPasswordValidation(false);
          setError("");
          setLoader(false);
          setCurrent("login");
          // states to setPack and futureEpc
          closeModal();
          setLogin(true);
          let payload = {
            portalId,
            formGuid,
            fields: [
              {
                name: "firstname",
                value: userCredentials?.first_name,
              },
              {
                name: "lastname",
                value: userCredentials?.last_name,
              },
              {
                name: "phone",
                value: userCredentials?.phone,
              },
              {
                name: "address",
                value: "",
              },
              {
                name: "city",
                value: "",
              },
              {
                name: "email",
                value: userCredentials?.email,
              },
            ],
          };
          // setFutureEpc("1");
          // setPackLabel(false);
          createHubsportUserByService(portalId, formGuid, payload, config).then(
            (res: any) => {}
          );
        })
        .catch((error: any) => {
          setPasswordValidation(false);
          if(!validateEmail(userCredentials?.email)){
            // setError(getValue(104));
          }else{
            setError(getValue(103));
          }
          
          setLoader(false);
        });
    }
  }
  };

  return (
    <>
      {model && <TermsAndConditions getValue={getValue} setModel={setModel} />}
      {error && (
        <div className="px-8 pt-8">
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{error}</span>
          </div>
        </div>
      )}
      {succesMessage && (
        <div className="px-8 pt-8">
          <div
            className="bg-green-100 border border-green-400 text-green-800 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{succesMessage}</span>
          </div>
        </div>
      )}
      <form  onSubmit={(e) => createUser(e)}>
        <div className="py-8">
          {/* first Name  and Last Name*/}
          <div className="space-y-6 py-2">
            <div className="flex">
              <div className="px-8 w-full">
                <span className="block my-2 text-black">{getValue(57)}*</span>
                <Input
                  className="w-full"
                  placeholder=""
                  required
                  type="text"
                  onChange={(e) => {
                    let duplicateData = { ...userCredentials };
                    duplicateData.first_name = e.target.value;
                    setUsersCredentials(duplicateData);
                  }}
                />
              </div>
              <div className="px-8 w-full">
                <span className="block my-2 text-black">{getValue(58)}*</span>
                <Input
                  className="w-full"
                  placeholder=""
                  required
                  type="text"
                  onChange={(e) => {
                    let duplicateData = { ...userCredentials };
                    duplicateData.last_name = e.target.value;
                    setUsersCredentials(duplicateData);
                  }}
                  
                />
              </div>
            </div>
          </div>
          {/* email and phone number*/}
          <div className="space-y-6 py-2">
            <div className="flex">
              <div className="px-8 w-full">
                <span className="block my-2 text-black">{getValue(59)}*</span>
                <Input
                  className="w-full"
                  placeholder=""
                  type="email"
                  required
                  onChange={(e) => {
                    let duplicateData = { ...userCredentials };
                    duplicateData.email = e.target.value;
                    setUsersCredentials(duplicateData);
                  }}
                  onInvalid={e => (e.target as HTMLInputElement).setCustomValidity(validateEmail(userCredentials?.email) ? '' : validateDomain(userCredentials?.email)? 'Please Enter valid domain' : 'Please Enter valid email' )}
                  onInput={e => (e.target as HTMLInputElement).setCustomValidity(validateEmail(userCredentials?.email) ? '': validateDomain(userCredentials?.email)? 'Please Enter valid domain' : 'Please Enter valid email')}
                />
              </div>
              <div className="px-8 w-full">
                <span className="block my-2 text-black">{getValue(60)}*</span>
                <Input
                  className="w-full"
                  placeholder=""
                  required
                  type="number"
                  onChange={(e) => {
                    let duplicateData = { ...userCredentials };
                    duplicateData.phone = e.target.value;
                    setUsersCredentials(duplicateData);
                  }}
                />
              </div>
            </div>
          </div>
          {/* password and confirm password*/}
          <div className="space-y-6 py-2">
            <div className="flex">
              <div className="px-8 w-full">
                <span className="block my-2 text-black">{getValue(61)}*</span>
                <Input
                  className="w-full"
                  placeholder=""
                  type="password"
                  required
                  onChange={(e) => {
                    let duplicateData = { ...userCredentials };
                    duplicateData.password = e.target.value;
                    setUsersCredentials(duplicateData);
                  }}
                />
              </div>
              <div className="px-8 w-full">
                <span className="block my-2 text-black">{getValue(61)}*</span>
                <Input
                  className="w-full"
                  placeholder=""
                  type="password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="px-8">
            {passwordValidation && (
              <span className="text-red-500">{getValue(62)}</span>
            )}
          </div>
          <div className="space-y-6 py-2">
            <div className="flex">
              <div className="px-8 w-full">
                <span className="block my-2 text-black">{getValue(63)}</span>
                <div className="flex">
                  <div className="pr-2">
                    <Radio
                      checked
                      onChange={(e) => {
                        let duplicateData = { ...userCredentials };
                        duplicateData.contact_by_email = "1";
                        setUsersCredentials(duplicateData);
                      }}
                    >
                      {getValue(64)}
                    </Radio>
                  </div>
                  <div className="pr-2">
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          let duplicateData = { ...userCredentials };
                          duplicateData.contact_by_phone = "1";
                          setUsersCredentials(duplicateData);
                        } else {
                          let duplicateData = { ...userCredentials };
                          duplicateData.contact_by_phone = "0";
                          setUsersCredentials(duplicateData);
                        }
                      }}
                    >
                      {getValue(65)}
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-8 pt-4 pb-2">
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          </div>
          {/* checkboxes */}
          <div className="space-y-6 py-2">
            <div className="flex">
              <div className="px-8 w-full">
                <div>
                  <div className="py-2">
                    <Checkbox
                    onChange={(e: any) => {
                      setIsContect(!isContact)
                    }}
                    >{getValue(66)}</Checkbox>
                  </div>
                  <div className="">
                    <Checkbox
                      onChange={(e: any) => {
                        setIsPrivacy(e.target.checked)
                        setModel(true);
                      }}
                    >
                      <a>{getValue(67)}</a>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-6 py-4 button_container flex justify-end border-b border-neutral-100 bg-neutral-100 dark:border-neutral-800 text-center">
          <Button type="submit" disabled={loader ? true : false}>
            {getValue(68)}
          </Button>
        </div>
      </form>
    </>
  );
};
export default withRouter(Modal);
