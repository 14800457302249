import Button from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { emailVerificationByService } from "services/login";
import { Input, notification } from "antd";
import { withRouter } from "react-router";
import { useEffect, useState } from "react";
import { GetContentByService } from "services/homepage";
import { Markup } from "interweave";

const Modal = (props: any) => {
  let { setForgetPassword, getValue, history } = props;
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [resultPageContent, setResultPageContent]: any = useState([]);

  const openNotification = (message: any, description: any) => {
    notification.open({
      message: message,
      description: description,
      onClick: () => {
        // console.log("Notification Clicked!");
      },
    });
  };

  const verifyEmailHandler = (e: any) => {
    e.preventDefault();
    setLoader(true);
    let payload = {
      email: email,
    };
    emailVerificationByService(payload)
      .then((response: any) => {
        setForgetPassword(false);
        setLoader(false);
        openNotification(getResultContent(101), getResultContent(102));
      })
      .catch((error) => {
        setLoader(false);
        openNotification(getResultContent(99), getResultContent(100));
      });
  };

  useEffect(()=>{
    let resultId: any = { id: 1 };
    GetContentByService(resultId)
      .then((response) => {
        setResultPageContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log("error");
      });
  },[]);

  // Method to return result page value through index.
  const getResultContent = (index: number): any => {
    return resultPageContent?.length !== 0 &&
      resultPageContent[index]?.value ? (
      <Markup content={resultPageContent[index]?.value} />
    ) : (
      ""
    );
  };

  return (
    <div className="absolute w-full z-40 top-0">
      <div className="h-full fixed w-full background_rgba flex justify-center result_common_model_container login_model_container">
        <div className="inline-block py-8 w-full flex justify-center">
          <div className="inline-flex h-fit-content flex-col w-full lg:w-1/2 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
            <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
              <h3
                className="text-sm m-0 text-left text-gray-500"
                id="headlessui-dialog-title-70"
              >
                {getResultContent(95)} 
                {/* wachtwoord vergeten */}
              </h3>
              <span className="absolute right-3 top-3">
                <ButtonClose onClick={() => setForgetPassword(false)} />
              </span>
            </div>
            <div className="px-8 py-8 w-full">
              <form
                className="w-full"
                onSubmit={(e: any) => verifyEmailHandler(e)}
              >
                <p className="text-black px-8 text-center">
                {getResultContent(96)} 
                  {/* Laten we een nieuwe instellen. Vul hieronder uw emailadres in
                  en u ontvangt een email om uw wachtwoord opnieuw in te
                  stellen. */}
                </p>
                {/* email and phone number*/}
                <div className="space-y-6 py-2 w-full">
                  <div className="flex w-full">
                    <div className="px-8 w-full">
                      <label className="block my-2 text-black">
                      {getResultContent(97)} 
                        {/* E-mailadres* */}
                      </label>
                      <Input
                        className="w-full"
                        placeholder=""
                        required
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      <div className="flex button_container justify-center py-4">
                        <Button type="submit" disabled={loader ? true : false}>
                          {loader ? getResultContent(94)  : getResultContent(98)}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Modal);
