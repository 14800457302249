import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import { Collapse, Space } from "antd";
import { Markup } from "interweave";

const { Panel } = Collapse;

function Benefits(props: any) {
  let { content, getValue } = props;
  function callback(key: any) {
    // console.log(key);
  }

  return (
    <div className="w-full faq-container">
      <div className="mt-0 pb-14 flex justify-center">
        <Space direction="vertical" className="w-full">
          {content?.map((single: any, index: any) => (
            <Collapse onChange={callback} expandIconPosition={"left"}>
              <Panel header={<Markup content={single?.title} />} key={index} className="w-full font-bold collapse_list">
                <p className="whitespace_prewrap font-normal">
                <Markup content={single?.description} />                  </p>
              </Panel>
            </Collapse>
          ))}
        </Space>
      </div>
      <div className="flex justify-center items-center">
        <SectionCalculateBenefit
           title={
            getValue ? getValue(7) : "How we can help you to reach label A ?"
          }
          searchText={getValue(4)}
          buttonText={getValue(5)}
          modelText={getValue ? getValue(8) : ""}
         />
      </div>
      {/* <div className="container relative py-16 mt-0 w-2/3">
        <SectionHomeTestimonials getValue={[]} />
      </div> */}
    </div>
  );
}

export default Benefits;
