import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { withRouter } from "react-router-dom";
import SecondaryButton from "shared/Button/ButtonSecondary";
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";
import { resetPasswordByService } from "services/login";
import { GetContentByService } from "services/homepage";
import { Markup } from "interweave";

export interface PageLoginProps {
  className?: string;
  history?: any;
  match?: any;
}

const PageLogin: FC<PageLoginProps> = ({ className = "", history, match }) => {
  // useEffect(() => {
  //   let token = cookie.load("token");
  //   if (token) {
  //     let decoded = jwt_decode(token);
  //     if (decoded) {
  //       history.push("/");
  //     }
  //   }
  // }, []);
  const [resultPageContent, setResultPageContent]: any = useState([]);

  useEffect(()=>{
    let resultId: any = { id: 1 };
    GetContentByService(resultId)
      .then((response) => {
        setResultPageContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log("error");
      });
  },[]);

  // Method to return result page value through index.
  const getResultContent = (index: number): any => {
    return resultPageContent?.length !== 0 &&
      resultPageContent[index]?.value ? (
      <Markup content={resultPageContent[index]?.value} />
    ) : (
      ""
    );
  };

  let id = match?.params?.id;
  const [userCredentials, setUsersCredentials] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const forgetPasswordHandler = (e: any) => {
    e.preventDefault();
    if (userCredentials?.newPassword !== userCredentials?.confirmPassword) {
      setError("Password not matched");
    }
    let payload = {
      password: userCredentials?.newPassword,
    };
    resetPasswordByService(id, payload)
      .then((response: any) => {
        history.push("/");
      })
      .catch((error: any) => {
        // setError("Password not matched");
      });
  };
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Renocity</title>
      </Helmet>
      <div className="container mb-24 result_common_model_container login_model_container">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        {getResultContent(90)} 
        {/* Reset Password */}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={(e) => forgetPasswordHandler(e)}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
              {getResultContent(91)}
                {/* New password */}
              </span>
              <Input
                type="password"
                placeholder=""
                className="mt-1"
                required
                onChange={(e) => {
                  let duplicateData = { ...userCredentials };
                  duplicateData.newPassword = e.target.value;
                  setUsersCredentials(duplicateData);
                  setError("");
                }}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              {getResultContent(92)}
                {/* Confirm new password */}
              </span>
              <Input
                type="password"
                className="mt-1"
                required
                onChange={(e) => {
                  let duplicateData = { ...userCredentials };
                  duplicateData.confirmPassword = e.target.value;
                  setUsersCredentials(duplicateData);
                  setError("");
                }}
              />
            </label>
            <div className="button_container w-full">
              <ButtonPrimary type="submit" className="w-full">
                {/* Submit */}
                {getResultContent(93)}
                </ButtonPrimary>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PageLogin);
