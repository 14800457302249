import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Avatar from "shared/Avatar/Avatar";
import Boiler from "images/renovations_images/boiler_gas.png";
import WindowDouble from "images/renovations_images/window_double.png";
import Glazing from "images/resultPage/renovations/WM_38_RW_A_01.gif";
import SolarPanel from "images/resultPage/renovations/solar_panels@2x.png";
import HeatPump from "images/resultPage/renovations/heatpump@2x.png";
import Roof_Attic from "images/resultPage/renovations/roof_attic@2x.png";
import Roof_Exterior from "images/renovations_images/roof_exterior.png";
import Roof_Interior from "images/renovations_images/roof_interior.png";
import VentilationC from "images/resultPage/renovations/ventilation_c@2x.png";
import VentilationD from "images/renovations_images/ventilation_d_2.png";
import Wall_Cavity from "images/renovations_images/wall_cavity.png";
import Wall_Exterior from "images/renovations_images/wall_exterior.png";

const Modal = (props: any) => {
  let {
    isOpenModal,
    closeModal,
    renovationModalContent,
    activeRenovationToolTip,
    getValue,
  } = props;

  const renovationsModalTitle = (key: any) => {
    switch (key) {
      case "windowsDoubleAndDoors":
        return getValue(188);
      case "glazing":
        return getValue(187);
      case "wallCav":
        return getValue(194);
      case "wallExt":
        return getValue(199);
      case "roofAttic":
        return getValue(202);
      case "roofIn":
        return getValue(206);
      case "roofExt":
        return getValue(210);
      case "ventC+":
        return getValue(214);
      case "ventD":
        return getValue(218);
      case "condGas":
        return getValue(222);
      case "heatPump":
        return getValue(226);
      case "solar":
        return getValue(276);
      default:
        return "";
    }
  };

  const renovationsDescription = (key: any) => {
    switch (key) {
      case "windowsDoubleAndDoors":
        return getValue(192);
      case "glazing":
        return getValue(193);
      case "wallCav":
        return getValue(197);
      case "wallExt":
        return getValue(201);
      case "roofAttic":
        return getValue(205);
      case "roofIn":
        return getValue(209);
      case "roofExt":
        return getValue(213);
      case "ventC+":
        return getValue(217);
      case "ventD":
        return getValue(221);
      case "condGas":
        return getValue(225);
      case "heatPump":
        return getValue(229);
      case "solar":
        return getValue(278);
      default:
        return "";
    }
  };

  const getImages = (key: any) => {
    switch (key) {
      case "windowsDoubleAndDoors":
        return WindowDouble;
      case "wallCav":
        return Wall_Cavity;
      case "wallExt":
        return Wall_Exterior;
      case "roofAttic":
        return Roof_Attic;
      case "roofIn":
        return Roof_Interior;
      case "roofExt":
        return Roof_Exterior;
      case "ventC+":
        return VentilationC;
      case "ventD":
        return VentilationD;
      case "condGas":
        return Boiler;
      case "heatPump":
        return HeatPump;
      case "glazing":
        return Glazing;
      case "solar":
        return SolarPanel;
      default:
        return "";
    }
  };

  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center result_common_model_container">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full">
              <div className="inline-flex flex-col w-full lg:w-1/2 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h3
                    className="text-sm m-0 text-left text-gray-500"
                    id="headlessui-dialog-title-70"
                  >
                    {renovationsModalTitle(activeRenovationToolTip)}
                  </h3>
                  <span className="absolute right-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>
                <div className="px-8 py-8 flex items-center">
                  {/* left side  */}
                  <div className="w_20p">
                    {activeRenovationToolTip ? (
                      <img
                        src={
                          activeRenovationToolTip
                            ? getImages(activeRenovationToolTip)
                            : ""
                        }
                        alt=""
                        className="w-full"
                      />
                    ) : (
                      <Avatar
                        // sizeClass="h-40 w-40"
                        radius="rounded-full"
                      />
                    )}
                  </div>
                  {/* right side */}
                  <div className="px-4 w_80p">
                    <span className="text-center">
                      {renovationsDescription(activeRenovationToolTip)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
export default Modal;
