import { FC } from "react";
import Agenda from "images/new-assets/undraw/agenda.svg";
import Agenda2 from "images/new-assets/undraw/agenda2.svg";
import Agenda3 from "images/new-assets/undraw/agenda3.svg";
import Agenda4 from "images/new-assets/undraw/agenda4.svg";
import Agenda5 from "images/new-assets/undraw/agenda5.svg";
import NcImage from "shared/NcImage/NcImage";
import CheckMarkImg from "images/home/check.svg";

export interface SectionSubscribe2Props {
  className?: string;
  sequence?: any;
  getValue?: any;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({
  className = "",
  sequence,
  getValue,
}) => {
  return (
    <div className="process-page-snake-image ">
      <div className="">
        <div
          className={`px-8  relative flex lg:justify-between flex-col lg:flex-row  ${className}`}
          data-nc-id="SectionSubscribe2"
        >
          <div className="mb-10 w-1/2 lg:mb-0 lg:mr-10">
            <h2 className="font-bold text-sm text-blue-500">
              {getValue(2)}
            </h2>
            <span className="block text-neutral-700 dark:text-neutral-400">
              {getValue(3)}
            </span>
            <ul className="space-y-4 py-4">
              <li className="flex items-center space-x-4">
                {/* <Badge name="1" /> */}
                <span className="whitespace_prewrap text-neutral-700 dark:text-neutral-300">
                  {getValue(4)}
                </span>
              </li>
            </ul>
          </div>
          <div className="w-1/2 snake-image-overlap-hide">
            <NcImage
              src={Agenda}
              className="w-1/2"
              containerClassName="flex justify-end"
            />
          </div>
        </div>
      </div>
      <div className="pt-14">
        <div
          className={`px-8 lg:justify-between flex flex-col lg:flex-row  ${className}`}
          data-nc-id="SectionSubscribe2"
        >
          <div className="w-1/2 snake-image-overlap-hide">
            <NcImage
              src={Agenda2}
              className="w-1/2"
              containerClassName="flex justify-start"
            />
          </div>
          <div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 flex justify-center snake-image-overlap-text-hide">
            <div>
              <h2 className="font-bold text-sm text-blue-500">
                {getValue(6)}
              </h2>
              <span className="block text-neutral-700 dark:text-neutral-400">
                {getValue(7)}
              </span>
              <ul className="space-y-4 py-4">
                <li className="flex items-center space-x-4">
                  {/* <Badge name="1" /> */}
                  <span className="whitespace_prewrap text-neutral-700 dark:text-neutral-300">
                    {getValue(8)}
                  </span>
                </li>
              </ul>
              <div className="flex list-container py-4">
                {/* <div> */}
                  <img src={CheckMarkImg} alt="" className="w-5 h-5" />
                {/* </div> */}
                <span className="px-2 text-neutral-700 dark:text-neutral-400">
                  {getValue(10)}
                </span>
              </div>
              <div className="flex list-container py-4">
                {/* <div> */}
                  <img src={CheckMarkImg} alt="" className="w-5 h-5" />
                {/* </div> */}
                <span className="px-2  text-neutral-700 dark:text-neutral-400">
                  {getValue(11)}
                </span>
              </div>
              <div className="flex list-container py-4">
                {/* <div> */}
                  <img src={CheckMarkImg} alt="" className="w-5 h-5" />
                {/* </div> */}
                <span className="px-2 text-neutral-700 dark:text-neutral-400">
                  {getValue(12)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-14">
        <div
          className={`px-8  relative flex lg:justify-between flex-col lg:flex-row  ${className}`}
          data-nc-id="SectionSubscribe2"
        >
          <div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 snake-image-overlap-text-hide">
            <h2 className="font-bold text-sm text-blue-500">
              {getValue(13)}
            </h2>
            <span className="block text-neutral-700 dark:text-neutral-400">
              {getValue(14)}
            </span>
            <ul className="space-y-4 py-4">
              <li className="flex items-center space-x-4">
                <span className="whitespace_prewrap text-neutral-700 dark:text-neutral-300">
                  {getValue(15)}
                </span>
              </li>
            </ul>
          </div>
          <div className="w-1/2 snake-image-overlap-hide">
            <NcImage
              src={Agenda3}
              className="w-1/2"
              containerClassName="flex justify-end"
            />
          </div>
        </div>
      </div>
      <div className="pt-14">
        <div
          className={`px-8 lg:justify-between flex flex-col lg:flex-row  ${className}`}
          data-nc-id="SectionSubscribe2"
        >
          <div className="w-1/2 snake-image-overlap-hide">
            <NcImage
              src={Agenda4}
              className="w-1/2"
              containerClassName="flex justify-start"
            />
          </div>
          <div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 flex justify-center snake-image-overlap-text-hide">
            <div>
              <h2 className="font-bold text-sm text-blue-500">
                {getValue(17)}
              </h2>
              <span className="block text-neutral-700 dark:text-neutral-400">
                {getValue(18)}
              </span>
              <ul className="space-y-4 py-4">
                <li className="flex items-center space-x-4">
                  <span className="whitespace_prewrap text-neutral-700 dark:text-neutral-300">
                    {getValue(19)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-14">
        <div
          className={`px-8  relative flex lg:justify-between flex-col lg:flex-row ${className}`}
          data-nc-id="SectionSubscribe2"
        >
          <div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 snake-image-overlap-text-hide">
            <h2 className="font-bold text-sm text-blue-500">
              {getValue(21)}
            </h2>
            <span className="block text-neutral-700 dark:text-neutral-400">
              {getValue(22)}
            </span>
            <ul className="space-y-4 py-4">
              <li className="flex items-center space-x-4">
                <span className="whitespace_prewrap text-neutral-700 dark:text-neutral-300">
                  {getValue(23)}
                </span>
              </li>
            </ul>
          </div>
          <div className="w-1/2 snake-image-overlap-hide">
            <NcImage
              src={Agenda5}
              className="w-1/2"
              containerClassName="flex justify-end"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSubscribe2;
