import Avatar from "images/banner_images/banner_4.png";
import City from "images/new-assets/undraw/UndrawCity.svg";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import { withRouter } from "react-router";

function Benefits(props: any) {
  let { getValue } = props;
  return (
    <div className="w-full">
      <div className="w-full">
        <img src={Avatar} className="h-400 w-full" alt="" />
      </div>
      <div className="py-4">
        <h2 className="text-xl font-medium text-neutral-600">
          <span className="whitespace_prewrap">{getValue(92)}</span>
        </h2>
      </div>
      <div className="pt-4 flex justify-between">
        <div className="flex flex-col">
          <div className="">
          <span className="text-greenBlue-gradient text-5xl font-bold text-neutral-500 dark:text-neutral-400">
          {getValue(193)} 
          </span>
          <span className="text-lg font-bold text-neutral-500 pt-4 pl-1">{getValue(160)}</span>
          </div>
          <span className="py-6 text-4xl text-neutral-800 dark:text-neutral-400">
            {getValue(94)}
          </span>
          <span className="whitespace_prewrap text-xl text-neutral-700 dark:text-neutral-400">
            {getValue(95)}
          </span>
        </div>
        <div>
          <img alt="" src={City} />
        </div>
      </div>
      <div className="flex pb-8">
        {/* 1st */}
        <div className=" w-1/3 pr-4 flex flex-col items-center">
          <div className="flex">
            <span className="text-greenBlue-gradient text-4xl font-bold text-neutral-500 dark:text-neutral-400 whitespace-nowrap">
            {getValue(194)} €
            </span>
          </div>
          <span className="font-bold text-neutral-500 pt-4">{getValue(161)}</span>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">{getValue(98)}</span>
            </h2>
          </div>
        </div>
        {/* 2nd */}
        <div className=" w-1/3 pr-4 flex flex-col items-center">
          <div className="flex">
            <span className="text-greenBlue-gradient text-4xl font-bold text-neutral-500 dark:text-neutral-400">
            {getValue(195)} 
            </span>
          </div>
          <span className="font-bold text-neutral-500 pt-4">{getValue(162)}</span>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">{getValue(100)}</span>
            </h2>
          </div>
        </div>
        {/* 3rd */}
        <div className=" w-1/3 pr-4 flex flex-col items-center">
          <div className="flex">
            <span className="text-greenBlue-gradient text-4xl font-bold text-neutral-500 dark:text-neutral-400">
            {getValue(196)} 
            </span>
          </div>
          <span className="font-bold text-neutral-500 pt-4">{getValue(163)}</span>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">{getValue(102)}</span>
            </h2>
          </div>
        </div>
        {/* 4th */}
        {/* <div className=" w-1/3 pr-4 flex flex-col items-center">
          <div className="flex">
            <span className="text-greenBlue-gradient text-4xl font-medium text-neutral-500 dark:text-neutral-400">
              450
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">Lorem Ipsum</span>
            </h2>
          </div>
        </div> */}
      </div>
      <div className="py-4">
        <h2 className="text-lg font-medium text-neutral-500">
          <span className="whitespace_prewrap">{getValue(105)}</span>
        </h2>
      </div>
      <div className="pt-4 flex justify-center items-center">
        <SectionCalculateBenefit
          title={
            getValue ? getValue(106) : "How we can help you to reach label A ?"
          }
          searchText={getValue ? getValue(152) : "Search Address"}
          buttonText={getValue ? getValue(107) : "Mijn diagnose"}
          modelText={getValue ? getValue(197) : ""}
        />
      </div>
      {/* <div className="container relative py-16 mt-0 w-2/3">
        <SectionHomeTestimonials getValue={[]} />
      </div> */}
    </div>
  );
}

export default withRouter(Benefits);
