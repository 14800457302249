import Avatar from "images/new-assets/Banner/benefits/3.png";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import { withRouter } from "react-router";
import CheckMarkImg from "images/home/check.svg";

function Benefits(props: any) {
  let { getValue } = props;
  return (
    <div className="w-full">
      <div className="w-full">
        <img src={Avatar} className="h-400 w-full" alt="" />
      </div>
      <div className="py-4">
        <h2 className="text-xl font-medium text-neutral-600">
          <span className="">{getValue(36)}</span>
        </h2>
        <h2 className="text-md text-neutral-500 pt-4">
          <span className="whitespace_prewrap">{getValue(37)}</span>
        </h2>
      </div>
      <div className="flex py-8">
        {/* 1st */}
        <div className=" w-1/2 pr-4">
          <div className="flex">
            <span className="text-greenBlue-gradient text-5xl font-medium text-neutral-500 dark:text-neutral-400">
            {getValue(39)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">{getValue(40)}</span>
            </h2>
          </div>
        </div>
        {/* 2nd */}
        <div className=" w-1/2 px-2">
          <div className="flex">
            <span className="text-greenBlue-gradient text-5xl font-medium text-neutral-500 dark:text-neutral-400">
            {getValue(42)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4 ">
              <span className="whitespace_prewrap">{getValue(43)}</span>
            </h2>
          </div>
        </div>
        {/* 3rd */}
        {/* <div className=" w-1/3 px-4">
          <div className="flex">
            <span className="text-greenBlue-gradient text-5xl font-medium text-neutral-500 dark:text-neutral-400">
              2.000%
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">{getValue(46)}</span>
            </h2>
          </div>
        </div> */}
      </div>
      <div>
        <h1 className="">{getValue(47)}</h1>
        <h2 className="text-md text-neutral-500">
          <span className="whitespace_prewrap">
          {getValue(48)}
          </span>
        </h2>
        {/* <h2 className="text-md text-neutral-500">
          <span className="font-bold">
            Je wil je eigen situatie ontdekken? 
          </span>
            Typ jouw adres in. 
        </h2> */}
        <div>
            <div className="flex mt-2">
              <img src={CheckMarkImg} alt="" className="landing-page-check-mark" />
              <span className="px-2 check-mark-text-vertical-centered text-base md:text-md text-neutral-500 dark:text-neutral-400">
              {getValue(164)}
              </span>
            </div>
            <div className="flex mt-2">
              <img src={CheckMarkImg} alt="" className="landing-page-check-mark" />
              <span className="px-2 check-mark-text-vertical-centered text-base md:text-md text-neutral-500 dark:text-neutral-400">
              {getValue(165)}
              </span>
            </div>
          </div>
      </div>
      <div className="flex pb-6 pt-8 overflow-auto">
      <table className="support-table">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th colSpan={2}  className="support-table-th1">{getValue(167)}</th>
          <th colSpan={2}  className="support-table-th2" style={{borderBottomRightRadius:0}}>{getValue(170)}</th>
        </tr>
        <tr>
          <th>{getValue(49)}</th>
          <th>{getValue(50)}</th>
          <th>{getValue(51)}</th>
          <th>{getValue(166)}</th>
          <th scope="col" className="support-table-th3">{getValue(168)}</th>
          <th scope="col" className="support-table-th3">{getValue(169)}</th>
          <th scope="col" className="support-table-th4">{getValue(171)}</th>
          <th scope="col" className="support-table-th4">{getValue(172)}</th>
        </tr>
        <tr>
          <td>{getValue(121)}</td>
          <td>{getValue(122)}</td>
          <td>{getValue(123)}</td>
          <td>{getValue(173)}</td>
          <td>{getValue(174)}</td>
          <td>{getValue(175)}</td>
          <td>{getValue(176)}</td>
          <td>{getValue(177)}</td>
        </tr>
        <tr>
          <td>{getValue(124)}</td>
          <td>{getValue(125)}</td>
          <td>{getValue(126)}</td>
          <td>{getValue(178)}</td>
          <td>{getValue(179)}</td>
          <td>{getValue(180)}</td>
          <td>{getValue(181)}</td>
          <td>{getValue(182)}</td>
        </tr>
        <tr>
          <td>{getValue(190)}</td>
          <td>{getValue(191)}</td>
          <td>{getValue(192)}</td>
          <td>{getValue(183)}</td>
          <td>{getValue(184)}</td>
          <td>{getValue(185)}</td>
          <td>{getValue(186)}</td>
          <td>{getValue(187)}</td>
        </tr>
      </table>
      
        {/* <ul className="border p-0 flex m-0 border-tl-radius border-tr-radius">
          <li className="w-1/4 border-r p-5"></li>
          <li className="w-1/4 border-r p-5"></li>
          <li className="w-1/4 border-r p-5"></li>
          <li className="w-1/4 p-5"></li>
        </ul>
        <ul className="border p-0 flex m-0">
          <li className="w-1/4 border-r p-5"></li>
          <li className="w-1/4 border-r p-5"></li>
          <li className="w-1/4 border-r p-5"></li>
          <li className="w-1/4 p-5"></li>
        </ul>
        <ul className="border p-0 flex m-0 border-bl-radius border-br-radius">
          <li className="w-1/4 border-r p-5"></li>
          <li className="w-1/4 border-r p-5"></li>
          <li className="w-1/4 border-r p-5"></li>
          <li className="w-1/4 p-5"></li>
        </ul> */}
      </div>
      <div className="pt-4 flex justify-center items-center">
        <SectionCalculateBenefit
          title={
            getValue ? getValue(127) : "How we can help you to reach label A ?"
          }
          searchText={getValue ? getValue(6) : "Search Address"}
          buttonText={getValue ? getValue(128) : "Mijn diagnose"}
          modelText={getValue ? getValue(197) : ""}
        />
      </div>
      {/* <div className="container relative py-16 mt-0 w-2/3">
        <SectionHomeTestimonials getValue={[]} />
      </div> */}
    </div>
  );
}

export default withRouter(Benefits);
