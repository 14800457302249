import React, { FC, useEffect } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { RenocityBenefitsType } from "data/types";
import ncNanoId from "utils/ncNanoId";
import CardCategory6 from "components/CardCategory7/CardCategory7";
import NextPrev from "shared/NextPrev/NextPrev";
import Card5 from "images/landingPage/Icon2.jpg";
import Card1 from "images/landingPage/Icon3.jpg";
import Card2 from "images/landingPage/Icon4.jpg";
import Card3 from "images/landingPage/Icon5.jpg";
import Card4 from "images/landingPage/Group-16.png";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 3 | 4 | 5;
  sliderStyle?: "style1" | "style2";
  getResultContent?: any;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  className = "",
  itemClassName = "",
  itemPerRow = 4,
  categoryCardType = "card3",
  sliderStyle = "style2",
  getResultContent,
}) => {
  const UNIQUE_CLASS = "glide_" + ncNanoId();
  const RENOCITY_BENEFITS: RenocityBenefitsType[] = [
    {
      id: "1",
      href: "#",
      name: "",
      title: getResultContent(64),
      hoverText: getResultContent(66),
      taxonomy: "category",
      count: 17288,
      // thumbnail: getResultContent(65),
      thumbnail: Card5,
    },
    {
      id: "2",
      href: "#",
      name: "",
      title: getResultContent(67),
      hoverText: getResultContent(69),
      taxonomy: "category",
      count: 2118,
      // thumbnail: getResultContent(68),
      thumbnail: Card1,
    },
    {
      id: "3",
      href: "#",
      name: "",
      title: getResultContent(70),
      hoverText: getResultContent(72),
      taxonomy: "category",
      count: 36612,
      // thumbnail: getResultContent(71),
      thumbnail: Card2,
    },
    {
      id: "4",
      href: "#",
      name: "",
      title: getResultContent(73),
      hoverText: getResultContent(82),
      taxonomy: "category",
      count: 36612,
      // thumbnail: getResultContent(81),
      thumbnail: Card3,
    },
  ];
  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      new Glide(`.${UNIQUE_CLASS}`, {
        perView: itemPerRow,
        gap: 32,
        bound: true,
        breakpoints: {
          1280: {
            perView: itemPerRow - 1,
            gap: 10,
          },
          1024: {
            gap: 10,
            perView: itemPerRow - 1,
          },
          768: {
            gap: 10,
            perView: itemPerRow - 2,
          },
          640: {
            gap: 10,
            perView: itemPerRow - 3,
          },
          500: {
            gap: 10,
            perView: 1,
          },
        },
      }).mount();
    }
  }, []);

  const renderCard = (item: RenocityBenefitsType, index: number) => {
    switch (categoryCardType) {
      case "card5":
        return <CardCategory6 taxonomy={item} />;
      default:
        return <CardCategory6 taxonomy={item} />;
    }
  };

  return (
    <div
      className={`nc-SectionSliderNewCategories result_page_carousel timeline_container rounded-2xl border px-4 pt-8 pb-2 ${className}`}
    >
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          className="mb-0 heading_container"
          desc={getResultContent(63)}
          hasNextPrev={sliderStyle === "style1"}
        >
          {getResultContent(62)}
        </Heading>
        <div className="glide__track pt-12" data-glide-el="track">
          <ul className="glide__slides flex justify-between">
            {RENOCITY_BENEFITS.map((item, index) => (
              <li
                key={index}
                className={`glide__slide slider_li ${itemClassName}`}
              >
                {renderCard(item, index)}
              </li>
            ))}
          </ul>
        </div>

        {/* {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-4" />
        )} */}
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
