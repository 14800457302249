import Avatar from "images/renovations/banner/6.png";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import VentilationC from "images/renovations_images/ventilation_c.png";
import VentilationD from "images/renovations_images/ventilation_d_1.png";
import { withRouter } from "react-router";

function Benefits(props: any) {
  let { getValue } = props;
  return (
    <div className="w-full">
      <div className="w-full">
        <img src={Avatar} className="h-400 w-full" alt="" />
      </div>
      <div>
        <h2 className="text-xl text-neutral-600 py-4">
          <span className="whitespace_prewrap">{getValue(115)}</span>
        </h2>
      </div>
      <div>
        <p className="text-md text-neutral-500 py-4 whitespace_prewrap">{getValue(116)}</p>
      </div>
      {getValue(117) && <div className="flex py-8">
        {/* 1st */}
        <div className=" w-1/2 pr-4">
          <div className="flex">
            <span className="text-base md:text-lg font-bold text-neutral-500 dark:text-neutral-400">
              {getValue(117)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4 whitespace_prewrap">{getValue(118)}</h2>
          </div>
        </div>
        {/* 2nd */}
        <div className=" w-1/2">
          <div className="flex justify-between full-width-responsive">
            <div>
              <div>
                <img src={VentilationC} alt="" className="h-40" />
              </div>
              <div>
                <h4 className="text-md text-neutral-500 py-4 text-center">
                  {getValue(120)}
                </h4>
              </div>
            </div>
            <div>
              <div>
                <img src={VentilationD} alt="" className="h-40" />
              </div>
              <div>
                <h4 className="text-md text-neutral-500 py-4 text-center">
                  {getValue(122)}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {getValue(123) && <div className="flex py-8">
        {/* 1st */}
        <div className=" w-1/2 pr-4">
          <div className="flex">
            <span className="text-base md:text-lg font-bold text-neutral-500 dark:text-neutral-400">
              {getValue(123)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">{getValue(124)}</span>
            </h2>
          </div>
        </div>
        {/* 2nd */}
        <div className=" w-1/2 px-4">
          <div className="flex">
            <span className="text-base md:text-lg font-bold text-neutral-500 dark:text-neutral-400">
              {getValue(125)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4 ">
              <span className="whitespace_prewrap">{getValue(126)}</span>
            </h2>
          </div>
        </div>
      </div>}
      <div className="flex justify-center items-center">
        <SectionCalculateBenefit
          title={
            getValue ? getValue(127) : "How we can help you to reach label A ?"
          }
          searchText={getValue ? getValue(130) : "Search Address"}
          buttonText={getValue ? getValue(128) : "Mijn diagnose"}
          modelText={getValue ? getValue(146) : ""}
        />
      </div>
      {/* <div className="container relative py-16 mt-0 w-2/3">
        <SectionHomeTestimonials getValue={[]} />
      </div> */}
    </div>
  );
}

export default withRouter(Benefits);
