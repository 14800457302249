import React, { FC } from "react";
import MainNav1 from "./MainNav1";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "shared/Modal/index";
import { withRouter } from "react-router";
// import cookie from "react-cookies";
// import jwt_decode from "jwt-decode";

const Header = (props: any) => {
  let {
    header,
    menus,
    login,
    logoutHandler,
    setLogin,
    getValue,
    setAuditModal,
  } = props;
  const [isTop, setisTop] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeModal, setActiveModal] = useState("");

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  }, []);

  function scrollFunction() {
    const $head = document.getElementById("nc-chifis-header");
    if (!$head) return;
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      !!isTop && setisTop(false);
    } else {
      setisTop(true);
    }
  }

  function openModal(activeModal: any) {
    setIsOpenModal(true);
    setActiveModal(activeModal);
  }

  function closeModal() {
    setIsOpenModal(false);
    setActiveModal("");
  }

  return (
    <div
      id="nc-chifis-header"
      className="nc-Header bg-white lg:sticky lg:top-0 w-full lg:left-0 lg:right-0 z-40"
    >
      <Helmet>
        <title>AGC</title>
      </Helmet>
      <Modal
        activeModal={activeModal}
        closeModal={closeModal}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        setLogin={setLogin}
      />
      {/* NAV */}
      <MainNav1
        login={login}
        logoutHandler={logoutHandler}
        isTop={isTop}
        header={header}
        menus={menus}
        setLogin={setLogin}
        openModal={openModal}
        getValue={getValue}
        setAuditModal={setAuditModal}
      />
    </div>
  );
};

export default withRouter(Header);
