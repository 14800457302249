import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import AutoCompleteSearch from "shared/AutoCompleteSearch/AutoCompleteSearch";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import UserIcon from "images/landingPage/user.svg";
import Phone from "images/resultPage/userDetail/telephone.png";
import Profile from "images/resultPage/userDetail/user.png";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { withRouter } from "react-router-dom";
import { Button } from "antd";

const MainNav1 = (props: any) => {
  let {
    isTop,
    header,
    menus,
    logoutHandler,
    login,
    openModal,
    getValue,
    setLogin,
    setAuditModal,
    history,
  } = props;
  return (
    <div
      className={`nc-MainNav1 ant_buttons_container shadow-lg relative bg-white z-10 result_common_model_container login_model_container  ${
        isTop ? "onTop " : "notOnTop backdrop-filter "
      } ${header === "header-added" && " shadow-lg"}`}
    >
      <div className="container py-4 relative flex justify-between items-center space-x-4 px-2 lg:px-0">
        <Logo />
        {header === "single-header" && <Navigation menus={menus} />}
        {header === "header-added" && (
          <div className="hidden lg:flex justify-start">
            <AutoCompleteSearch
              getValue={getValue}
              bottomText=""
              searchText={getValue ? getValue(6) : "Search Address"}
              buttonText={getValue ? getValue(38) : "Mijn diagnose"}
              modelText={getValue ? getValue(83) : ""}
            />
          </div>
        )}
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className=" items-center flex space-x-1">
            <div className="px-1" />
            {login ? (
              <React.Fragment>
                {/* <div
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    history.push("/profile");
                  }}
                >
                  <Avatar
                    imgUrl={Profile}
                    sizeClass="h-12 w-12 text-lg"
                    radius=""
                  />
                </div> */}
                <div className="icons_container">
                  <Button
                    className="rounded ant_secondary_button ml-2 p-0 lg-500 flex border-neutral-400 p-2 cursor-pointer items-center h-fit-content"
                    onClick={() => {
                      history?.push("/profile");
                      openModal("contact-info");
                    }}
                  >
                    <img src={Profile} alt="" className="h-6 w-6 " />
                  </Button>
                </div>
                <div>
                  <ButtonPrimary
                    onClick={() => {
                      setAuditModal(true);
                    }}
                    className="rounded bg-primary-500 flex items-center"
                  >
                    <span className="px-2">
                      {getValue ? getValue(79) : "Book an Audit"}
                    </span>
                  </ButtonPrimary>
                <Button
                danger
                className="rounded ml-1 h-full flex items-center"
                onClick={() => {
                  logoutHandler();
                }}
              >
                <span className="px-2">{getValue(110)}</span>
              </Button>
                </div>
              </React.Fragment>
            ) : (
              <>
                <div>
                  <ButtonSecondary
                    onClick={() => {
                      openModal("login");
                    }}
                    className="rounded  flex items-center"
                  >
                    {/* <img src={UserIcon} alt="" className="h-5 w-5" /> */}
                    <span className="px-2">
                      {getValue ? getValue(77) : "Login"}
                    </span>
                  </ButtonSecondary>
                </div>
                <div>
                  <ButtonPrimary
                    onClick={() => {
                      openModal("login");
                    }}
                    className="rounded bg-primary-500 flex items-center"
                  >
                    <span className="px-2">
                      {getValue ? getValue(79) : "Book an Audit"}
                    </span>
                  </ButtonPrimary>
                </div>
              </>
            )}
          </div>
          {/* <div className="flex items-center xl:hidden">
            <MenuBar />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(MainNav1);
