import React, { FC, Fragment } from "react";
import Avatar from "shared/Avatar/Avatar";
import informationImg from "images/information.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SecondaryButton from "shared/Button/ButtonSecondary";
import Boiler from "images/renovations_images/boiler_gas.png";
import WindowDouble from "images/renovations_images/window_double.png";
import Glazing from "images/resultPage/renovations/WM_38_RW_A_01.gif";
import SolarPanel from "images/resultPage/renovations/solar_panels@2x.png";
import HeatPump from "images/resultPage/renovations/heatpump@2x.png";
import Roof_Attic from "images/resultPage/renovations/roof_attic@2x.png";
import Roof_Exterior from "images/renovations_images/roof_exterior.png";
import Roof_Interior from "images/renovations_images/roof_interior.png";
import VentilationC from "images/resultPage/renovations/ventilation_c@2x.png";
import VentilationD from "images/renovations_images/ventilation_d_2.png";
import Wall_Cavity from "images/renovations_images/wall_cavity.png";
import Wall_Exterior from "images/renovations_images/wall_exterior.png";
import SelectedImage from "images/resultPage/status_selected.png";
import { pricesDotHandler } from "containers/Results/constants";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

export interface SectionHeroProps {
  className?: string;
  getResultContent?: any;
  resultPageContent?: any;
  renovations?: any;
  onChange?: any;
  showAllRenovations?: any;
  custom?: any;
  openModal?: any;
  setRenovationModalContent?: any;
  selectCardHandler?: any;
  epcA?: any;
  setActiveRenovationToolTip?: any;
  selectedPack?: any;
  isCallWaitng?: any;
}

const SectionOurRecomendation: FC<SectionHeroProps> = ({
  className,
  getResultContent,
  custom,
  renovations,
  onChange,
  showAllRenovations,
  openModal,
  setRenovationModalContent,
  selectCardHandler,
  setActiveRenovationToolTip,
  epcA,
  selectedPack,
  isCallWaitng,
}) => {
  const getNames = (key: any) => {
    switch (key) {
      case "windowsDoubleAndDoors":
        return getResultContent(230);
      case "wallCav":
        return getResultContent(31);
      case "wallExt":
        return getResultContent(29);
      case "roofAttic":
        return getResultContent(32);
      case "roofIn":
        return getResultContent(28);
      case "roofExt":
        return getResultContent(33);
      case "ventC+":
        return getResultContent(34);
      case "ventD":
        return getResultContent(35);
      case "condGas":
        return getResultContent(274);
      case "heatPump":
        return getResultContent(36);
      case "glazing":
        return getResultContent(27);
      case "solar":
        return getResultContent(30);
      default:
        return "";
    }
  };
  const getImages = (key: any) => {
    switch (key) {
      case "windowsDoubleAndDoors":
        return WindowDouble;
      case "wallCav":
        return Wall_Cavity;
      case "wallExt":
        return Wall_Exterior;
      case "roofAttic":
        return Roof_Attic;
      case "roofIn":
        return Roof_Interior;
      case "roofExt":
        return Roof_Exterior;
      case "ventC+":
        return VentilationC;
      case "ventD":
        return VentilationD;
      case "condGas":
        return Boiler;
      case "heatPump":
        return HeatPump;
      case "glazing":
        return Glazing;
      case "solar":
        return SolarPanel;
      default:
        return "";
    }
  };

  const activeDots = (value: number) => {
    let allDots = [];
    for (let i = 1; i < 6; i++) {
      if (i <= value) {
        allDots?.push({
          id: i,
          show: "active",
        });
      } else {
        allDots?.push({
          id: i,
          show: "inActive",
        });
      }
    }
    return (
      <>
        {allDots?.map((single: any, i: any) => (
          <div className="flex" key={i}>
            {single?.show === "active" ? (
              <span className="greenDot"></span>
            ) : (
              <span className="grayDot"></span>
            )}
          </div>
        ))}
      </>
    );
  };

  const getComfort = (key: any): any => {
    switch (key) {
      case "solar":
        return activeDots(0);
      case "windowsDoubleAndDoors":
        return activeDots(4);
      case "glazing":
        return activeDots(4);
      case "wallCav":
        return activeDots(2);
      case "wallExt":
        return activeDots(2);
      case "roofAttic":
        return activeDots(3);
      case "roofIn":
        return activeDots(3);
      case "roofExt":
        return activeDots(3);
      case "ventC+":
        return activeDots(2);
      case "ventD":
        return activeDots(4);
      case "condGas":
        return activeDots(1);
      case "heatPump":
        return activeDots(1);
      default:
        return activeDots(0);
    }
  };

  const getComplexity = (key: any): any => {
    switch (key) {
      case "solar":
        return activeDots(1);
      case "windowsDoubleAndDoors":
        return activeDots(3);
      case "glazing":
        return activeDots(1);
      case "wallCav":
        return activeDots(2);
      case "wallExt":
        return activeDots(4);
      case "roofAttic":
        return activeDots(2);
      case "roofIn":
        return activeDots(2);
      case "roofExt":
        return activeDots(4);
      case "ventC+":
        return activeDots(4);
      case "ventD":
        return activeDots(5);
      case "condGas":
        return activeDots(1);
      case "heatPump":
        return activeDots(2);
      default:
        return activeDots(0);
    }
  };

  const getSavings = (key: any): any => {
    switch (key) {
      case "solar":
        return activeDots(5);
      case "windowsDoubleAndDoors":
        return activeDots(2);
      case "glazing":
        return activeDots(2);
      case "wallCav":
        return activeDots(3);
      case "wallExt":
        return activeDots(3);
      case "roofAttic":
        return activeDots(4);
      case "roofIn":
        return activeDots(4);
      case "roofExt":
        return activeDots(4);
      case "ventC+":
        return activeDots(1);
      case "ventD":
        return activeDots(3);
      case "condGas":
        return activeDots(4);
      case "heatPump":
        return activeDots(4);
      default:
        return activeDots(0);
    }
  };

  const renovationPricesHandler = (value: any) => {
    let price = pricesDotHandler(value);
    return price;
  };

  return (
    <div className="listingSection__wrap recomendation_container !space-y-6">
      {/* 1 */}
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {/* a */}
          {selectedPack === "packA" && (
            <h2 className="fs_22 m-0 font-semibold">{getResultContent(23)}</h2>
          )}
          {/* b */}
          {selectedPack === "packB" && (
            <h2 className="fs_22 m-0 font-semibold">{getResultContent(237)}</h2>
          )}
          {/* c */}
          {selectedPack === "customPack" && (
            <h2 className="fs_22 m-0 font-semibold">{getResultContent(238)}</h2>
          )}
          {selectedPack === "baseOffer" && (
            <h2 className="fs_22 m-0 font-semibold">{getResultContent(238)}</h2>
          )}
          <span
            className="px-3 cursor-pointer"
            onClick={() => {
              openModal("pack-info");
            }}
          >
            <Avatar
              imgUrl={informationImg}
              sizeClass="h-5 w-5"
              radius="rounded-full"
            />
          </span>
        </div>
        {isCallWaitng && (
          <div className="flex">
            <Spin indicator={antIcon} />
          </div>
        )}
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {/* 2 */}
      {renovations?.length !== 0 ? (
        renovations?.map((renovations: any, index: any) => (
          <Fragment key={index}>
            {/* for desktop */}
            <div
              className={`listingSection__wrap card_container hidden lg:block`}
              
            >
              <div className="selected-img">
                {renovations?.active === 1 ? (
                  <img src={SelectedImage} alt="" />
                ) : (
                  ""
                )}
              </div>
              <div className="w-full flex">
                {/* left side  */}
                <Avatar
                  sizeClass="h-32 w-32"
                  radius="rounded"
                  imgUrl={getImages(renovations?.renovation)}
                />
                {/* right side */}
                <div className="w-full pl-4">
                  {/* header */}
                  <div className="w-full flex flex-row justify-between">
                    <h6 className="text-lg text-black flex items-center">
                      {getNames(renovations?.renovation)}
                      <span
                        className="px-3 cursor-pointer"
                        onClick={() => {
                          setActiveRenovationToolTip(renovations?.renovation);
                          setRenovationModalContent(renovations);
                          openModal("renovation-information");
                        }}
                      >
                        <Avatar
                          imgUrl={informationImg}
                          sizeClass="h-5 w-5"
                          radius="rounded-full"
                        />
                      </span>
                    </h6>
                    <div className="flex flex-col text-sm px-2">
                      <span className="text-lg text-blue-500 font-medium fs_22 text-right">
                        {renovationPricesHandler(
                          parseInt(renovations?.investment_cost)
                        )}
                        €
                      </span>
                      <span className="text-sm text-gray-400">
                        {getResultContent(41)}
                      </span>
                    </div>
                  </div>
                  {/* content */}
                  <div className="flex flex-row ">
                    {/* left side */}
                    <div className="w-1/2">
                      <div className="flex">
                        <h6 className="text-sm m-0">
                          {getResultContent(37)} :
                        </h6>
                        <span className="text-sm px-2">
                          {renovationPricesHandler(
                            parseInt(renovations.total_subsidy)
                          )}
                          €
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between  savings_complexity_comfort flex-row py-6">
                    {/* left side */}
                    <div className="">
                      <div className="flex items-center ">
                        <h6 className="text-sm m-0 responsive_text">
                          {getResultContent(38)} :
                        </h6>
                        <div className="flex items-center px-2">
                          <div className="flex items-center pl-2">
                            {getSavings(renovations?.renovation)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="br_r"></div>
                    <div className="pl-2">
                      <div className="flex">
                        <h6 className="text-sm m-0 responsive_text">
                          {getResultContent(39)} :
                        </h6>
                        <div className="flex items-center px-2">
                          {getComplexity(renovations?.renovation)}
                        </div>
                      </div>
                    </div>
                    <div className="br_r"></div>
                    <div className="pl-2">
                      <div className="flex">
                        <h6 className="text-sm m-0 responsive_text">
                          {getResultContent(40)} :
                        </h6>
                        <div className="flex items-center px-2">
                          <div className="flex items-center pl-2">
                            {getComfort(renovations?.renovation)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {custom && (
                    <React.Fragment>
                      {renovations?.active === 1 ? (
                        <div className="flex justify-end px-4">
                          <SecondaryButton
                            className={"outline-secondary-button"}
                            onClick={() =>
                              selectCardHandler(renovations, index, 0)
                            }
                          >
                            {getResultContent(280)}
                          </SecondaryButton>
                        </div>
                      ) : (
                        <div className="flex justify-end px-4">
                          <ButtonPrimary
                            className={"outline-primary-button"}
                            onClick={() =>
                              selectCardHandler(renovations, index, 1)
                            }
                          >
                            {getResultContent(279)}
                          </ButtonPrimary>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            {/* for mobile */}
            <div
              className={`listingSection__wrap card_container block lg:hidden`}
              key={index}
            >
              <div className="selected-img">
                {renovations?.active === 1 ? (
                  <img src={SelectedImage} alt="" />
                ) : (
                  ""
                )}
              </div>
              <div className="w-full flex">
                {/* left side  */}
                <Avatar
                  sizeClass="h-32 w-32"
                  radius="rounded"
                  imgUrl={getImages(renovations?.renovation)}
                />
                {/* right side */}
                <div className="w-full pl-2">
                  {/* header */}
                  <div className="w-full">
                    <h6 className="text-lg text-black flex items-center">
                      {getNames(renovations?.renovation)}
                      <span
                        className="px-3 cursor-pointer"
                        onClick={() => {
                          setActiveRenovationToolTip(renovations?.renovation);
                          setRenovationModalContent(renovations);
                          openModal("renovation-information");
                        }}
                      >
                        <Avatar
                          imgUrl={informationImg}
                          sizeClass="h-5 w-5"
                          radius="rounded-full"
                        />
                      </span>
                    </h6>
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-sm text-gray-400">
                        {getResultContent(41)}
                      </span>
                      <span className="text-lg text-blue-500 font-medium fs_22 text-right">
                        {renovationPricesHandler(
                          parseInt(renovations?.investment_cost)
                        )}
                        €
                      </span>
                    </div>
                  </div>
                  {/* content */}
                  <div className="flex flex-row ">
                    {/* left side */}
                    <div className="py-2">
                      <div className="flex">
                        <h6 className="text-sm m-0">
                          {getResultContent(37)} :
                        </h6>
                        <span className="text-sm px-2">
                          {renovationPricesHandler(
                            parseInt(renovations.total_subsidy)
                          )}
                          €
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="savings_complexity_comfort py-4">
                    {/* left side */}
                    <div className="py-2">
                      <div className="flex items-center ">
                        <h6 className="text-sm m-0 responsive_text ">
                          {getResultContent(38)} :
                        </h6>
                        <div className="flex items-center px-2">
                          <div className="flex items-center pl-2">
                            {getSavings(renovations?.renovation)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-2">
                      <div className="flex">
                        <h6 className="text-sm m-0 responsive_text">
                          {getResultContent(39)} :
                        </h6>
                        <div className="flex items-center px-2">
                          {getComplexity(renovations?.renovation)}
                        </div>
                      </div>
                    </div>
                    <div className="py-2">
                      <div className="flex">
                        <h6 className="text-sm m-0 responsive_text">
                          {getResultContent(40)} :
                        </h6>
                        <div className="flex items-center px-2">
                          <div className="flex items-center pl-2">
                            {getComfort(renovations?.renovation)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {custom && (
                    <React.Fragment>
                      {renovations?.active === 1 ? (
                        <div className="flex justify-end px-4">
                          <SecondaryButton
                            className={"outline-secondary-button"}
                            onClick={() =>
                              selectCardHandler(renovations, index, 0)
                            }
                          >
                            {getResultContent(280)}
                          </SecondaryButton>
                        </div>
                      ) : (
                        <div className="flex justify-end px-4">
                          <ButtonPrimary
                            className={"outline-primary-button"}
                            onClick={() =>
                              selectCardHandler(renovations, index, 1)
                            }
                          >
                            {getResultContent(279)}
                          </ButtonPrimary>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        ))
      ) : (
        <p className="py-2 text-center">{getResultContent(292)}</p>
      )}
      {/* 5 */}
      <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
    </div>
  );
};

export default SectionOurRecomendation;
