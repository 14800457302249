import React from "react";
import MyRouter from "routers/index";
import { withRouter } from "react-router-dom";
import { HubspotProvider } from "react-use-hubspot-form";
import "antd/dist/antd.css";
import { setDefaultOptions } from 'esri-loader';
setDefaultOptions({ version: '4.22' })

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      {/* <CommingSoon /> */}
      <HubspotProvider>
        <MyRouter />
      </HubspotProvider>
    </div>
  );
}

export default withRouter(App);
