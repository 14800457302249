import React, { FC } from "react";
import { Button } from "antd";
import { withRouter } from "react-router";

const SectionDiagnosis = (props: any) => {
  let { history, className, userScan, getValue } = props;
  const statusHandler = (status: any) => {
    switch (parseInt(status)) {
      case 1:
        return "Simulation";
      case 2:
        return "Audit Booked";
      case 3:
        return "Audit done";
      case 4:
        return "Waiting for offer";
      case 5:
        return "Offer received";
    }
  };
  return (
    <div
      className={`listingSection__wrap profile-section-container h-auto section_width mx-2 mt-4  p-0 ${className}`}
    >
      <div className="flex justify-between px-8 pt-4 pb-2 bg-neutral-100 border-t-r-10 border-t-l-10">
        <div className="flex flex-col lg:flex-row items-center w-full">
          <div className="w-full lg:w-1/2 flex">
            <h4 className="fs_22 text-neutral-500">{getValue(10)}</h4>
          </div>
        </div>
      </div>
      <div className="pb-8 table_body">
        {userScan?.map((scan: any, index: any) => (
          <div className="px-8 py-2 flex justify-between " key={index}>
            <span className="text-neutral-500 fs_16 py-2 pr-2 w-1/4">
              {scan?.formatted_address}
            </span>
            <span className="text-neutral-500 fs_16 px-2 py-2 w-1/4">
              {statusHandler(scan?.status)}
            </span>
            <span className="text-neutral-500 fs_16 py-2 w-1/4">
              {scan?.createdAt?.split(" ")[0]}
            </span>
            <div className="button-container fs_16 w-1/4">
              <Button
                onClick={() =>
                  history.push(
                    "/listing-renovate-detail/"+scan?.unique_id + "?gmlid=GRB_OIDN_" + scan?.GRB_OIDN_
                  )
                }
              >
                {getValue(16)}
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(SectionDiagnosis);
