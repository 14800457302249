import React from "react";
import NavigationItem from "./NavigationItem";

function Navigation(props: any) {
	let { menus } = props;
	return (
		<ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
			{menus.map((menu: any) => (
				<NavigationItem key={menu?.id} menuItem={menu} />
			))}
		</ul>
	);
}

export default Navigation;
