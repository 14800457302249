import axios from "axios";
require("dotenv").config();

//post api for login
export const updateUserRecordByService = (id: any, payload: Object) => {
  return axios.put(
    process.env.REACT_APP_APP_URL + `/user/update/${id}`,
    payload
  );
};
