// import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      <div className="container py-16">
        <SectionHero
          heading="Cookie policy"
        />
      </div>
    </div>
  );
};

export default PageAbout;
