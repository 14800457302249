import React, { FC, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Avatar from "shared/Avatar/Avatar";
import Savings from "images/resultPage/icon_color/savings_2.png";
import Star from "images/star.png";
import StarGrey from "images/star_grey.svg";
import Cloud from "images/resultPage/icon_color/cloud.png";
import HouseValue from "images/resultPage/icon_color/house_value.png";
import Renting from "images/resultPage/icon_color/savings.png";
import informationImg from "images/information.png";
import { currentComfort, GetUserStatus } from "utils/renovations";
import { pricesDotHandler } from "containers/Results/constants";
import { SetUserProfileStatusByService } from "services/ResultPage";
import { match } from "interweave";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  openModal?: any;
  getResultContent?: any;
  renovations?: any;
  futureEpc?: any;
  loggedInUser?: any;
  userStatus?: any;
  houseDetails?: any;
  setTotalValue?: any;
  userSelectedData?: any;
  selectedInd?: any;
  refinementCall2Record?: any;
  allRenovationRecord?: any;
  customStars?: any;
  customPackSelected?: any;
  setRenovationModalContent?: any;
  setUserStatus: any;
  selectedUserCombination: any;
  setCustom: any;
  selectedPack: any;
  isAdminView: any;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  className = "",
  itemClassName = "",
  openModal,
  getResultContent,
  renovations,
  futureEpc,
  loggedInUser,
  userStatus,
  houseDetails,
  setTotalValue,
  userSelectedData,
  selectedInd,
  refinementCall2Record,
  allRenovationRecord,
  customStars,
  customPackSelected,
  setRenovationModalContent,
  setUserStatus,
  selectedUserCombination,
  setCustom,
  selectedPack,
  isAdminView
}) => {
  //
  let totalBudgetValue: any;
  let totalSubsidyValue: any;

  const renovationPricesHandler = (value: any) => {
    let price = pricesDotHandler(value);
    return price;
  };
  // total budget
  const totalBudget = () => {
    let totalBudget: any = 0;
    renovations?.map((total: any) => {
      if (total?.investment_cost !== null && total?.active === 1) {
        totalBudget = totalBudget + parseInt(total?.investment_cost);
      }
    });
    totalBudgetValue = totalBudget;
    return renovationPricesHandler(parseInt(totalBudget));
  };

  const totalSubsidy = () => {
    let totalSubsidy: any = 0;
    renovations?.map((total: any) => {
      if (
        total?.total_subsidy !== null &&
        total?.total_subsidy &&
        total?.active === 1
      ) {
        totalSubsidy = totalSubsidy + parseInt(total?.total_subsidy);
      }
    });

    totalSubsidyValue = totalSubsidy;
    return renovationPricesHandler(parseInt(totalSubsidy));
  };
  //   total yearly savings
  const totalYearlySavings = () => {
    let totalYearlySavings: any = 0;
    renovations?.map((total: any) => {
      if (total?.yearly_savings !== null && total?.active === 1) {
        totalYearlySavings =
          totalYearlySavings + parseInt(total?.yearly_savings);
      }
    });
    totalYearlySavings = renovationPricesHandler(totalYearlySavings);
    return totalYearlySavings;
  };
  //   total co2
  const totalCO2 = () => {
    let totalCo2: any = 0;
    renovations?.map((total: any) => {
      if (total?.co2_savings !== null && total?.active === 1) {
        totalCo2 = totalCo2 + parseInt(total?.co2_savings);
      }
    });
    totalCo2 = renovationPricesHandler(totalCo2);
    return totalCo2 ? totalCo2 : 0;
  };
  //   total = budget - subsidy
  const total = () => {
    let total = parseInt(totalBudgetValue) - parseInt(totalSubsidyValue);
    setTotalValue(total);
    let TotalValue = renovationPricesHandler(total);
    return TotalValue;
    // return totalBudget() - totalSubsidy();
  };
  const currentEpcHandler = (epc_label: any) => {
    let epc: any = parseInt(epc_label);
    if (epc <= 100) {
      return "A";
    } else if (epc <= 200) {
      return "B";
    } else if (epc <= 300) {
      return "C";
    } else if (epc <= 400) {
      return "D";
    } else if (epc <= 500) {
      return "E";
    } else {
      return "F";
    }
  };

  const baseFutureEpcHandler = (epc_label: any) => {
    if (epc_label < 150) {
      return 5;
    } else if (epc_label < 200) {
      return 4;
    } else if (epc_label < 300) {
      return 3;
    } else if (epc_label < 400) {
      return 2;
    } else if (epc_label < 500) {
      return 1;
    } else if (epc_label < 600) {
      return 1;
    } else {
      return 1;
    }
  };

  const currentEpcClassHandler = (epc_label: any) => {
    let epc: any = parseInt(epc_label);
    if (epc <= 100) {
      return "status-A";
    } else if (epc <= 200) {
      return "status-B";
    } else if (epc <= 300) {
      return "status-C";
    } else if (epc <= 400) {
      return "status-D";
    } else if (epc <= 500) {
      return "status-E";
    } else {
      return "status-F";
    }
  };

  const updateUserStatus = () => {
    const payload = {
      userId: userStatus?.data.userId,
      userDataId: userStatus?.data.userDataId,
      status: 4,
      base_offer: JSON.stringify([selectedUserCombination]),
    };
    SetUserProfileStatusByService(payload)
      .then((profileResponse: any) => {
        setUserStatus(profileResponse);
        setCustom(false);
      })
      .catch((error: any) => {});
  };

  const modelHandler = (userStatus: any) => {
    switch (parseInt(userStatus)) {
      case 1:
        return loggedInUser ? openModal("book-an-audit") : openModal("login");
      case 2:
        return loggedInUser ? openModal("audit-waiting") : openModal("login");
      case 3:
        return loggedInUser ? updateUserStatus() : openModal("login");
      case 4:
        return loggedInUser ? openModal("audit-waiting") : openModal("login");
      case 5:
        return loggedInUser ? openModal("") : openModal("login");
      default:
        return loggedInUser ? openModal("book-an-audit") : openModal("login");
    }
  };

  const buttonTextHandler = (userStatus: any) => {
    switch (parseInt(userStatus)) {
      case 1:
        return getResultContent(56);
      case 2:
        return getResultContent(249);
      case 3:
        return getResultContent(250);
      case 4:
        return getResultContent(249);
      case 5:
        return getResultContent(251);
      default:
        return getResultContent(56);
    }
  };

  const activeStars = (value: number) => {
    let allStars = [];
    for (let i = 1; i < 6; i++) {
      if (i <= value) {
        allStars?.push({
          id: i,
          show: "active",
        });
      } else {
        allStars?.push({
          id: i,
          show: "inActive",
        });
      }
    }

    return (
      <>
        <div className="flex items-center px-4">
          {allStars?.map((single: any, i: any) => (
            <React.Fragment key={i}>
              {single.show === "active" ? (
                <img className="h-6 w-6" src={Star} alt="" />
              ) : (
                <img className="h-6 w-6" src={StarGrey} alt="" />
              )}
            </React.Fragment>
          ))}
        </div>
      </>
    );
  };

  const selectedRenovationsTotal = (
    total: any,
    selectedRenovationsValue: any
  ) => {
    selectedRenovationsValue?.map((item: any) => {
      if (item?.renovation === "windowsDoubleAndDoors") {
        return (total = total + 4);
      }
      if (item?.renovation === "glazing") {
        return (total = total + 4);
      }
      if (item?.renovation === "wallCav") {
        return (total = total + 2);
      }
      if (item?.renovation === "wallExt") {
        return (total = total + 2);
      }
      if (item?.renovation === "roofAttic") {
        return (total = total + 3);
      }
      if (item?.renovation === "roofIn") {
        return (total = total + 3);
      }
      if (item?.renovation === "roofExt") {
        return (total = total + 3);
      }
      if (item?.renovation === "ventC+") {
        return (total = total + 2);
      }
      if (item?.renovation === "ventD") {
        return (total = total + 4);
      }
      if (item?.renovation === "condGas") {
        return (total = total + 1);
      }
      if (item?.renovation === "heatPump") {
        return (total = total + 1);
      }
    });
    return total;
  };

  const unSelectedRenovationsTotal = (
    total: any,
    unSelectedRenovationsValue: any,
    response: any
  ) => {
    unSelectedRenovationsValue?.map((item: any) => {
      if (
        item?.renovation === "windowsDoubleAndDoors" ||
        item?.renovation === "glazing"
      ) {
        // glazing , ordinary_double ,single
        if (response?.window_type === "single") {
          total = total + 0;
        } else if (
          response?.window_type === "ordinary_double" ||
          response?.window_type === "hr_double_before_2000"
        ) {
          total = total + 2;
        } else if (
          response?.window_type === "triple" ||
          response?.window_type === "hr_double_after_2000"
        ) {
          total = total + 4;
        }
      }
      if (item?.renovation === "wallCav" || item?.renovation === "wallExt") {
        // cavity wall
        let cavity = response?.wall?.u_value;
        if (cavity !== "" && cavity !== undefined) {
          if (cavity === "") {
            total = total + 0;
          } else if (
            cavity === 0.65 ||
            cavity === 0.55 ||
            cavity === 0.4 ||
            cavity === 0.3
          ) {
            total = total + 1;
          } else if (cavity === 0.2) {
            total = total + 2;
          }
        } else {
          // cavity wall
          // if(cavity true ** value < 0.6 {total = 2
          if (
            response?.u_value_wall !== null &&
            parseInt(response?.has_cavity) === 1 &&
            parseInt(response?.u_value_wall) < 0.6
          ) {
            total = total + 2;
          }
          // else if cavity true ** value >=0.6 && < 1.65 {total =1
          else if (
            response?.u_value_wall !== null &&
            parseInt(response?.has_cavity) === 1 &&
            parseInt(response?.u_value_wall) >= 0.6 &&
            parseInt(response?.u_value_wall) < 0.7
          ) {
            total = total + 1;
          }
          // else if cavity true ** value > 1.65 {total =0
          else if (
            response?.u_value_wall !== null &&
            parseInt(response?.has_cavity) === 1 &&
            parseInt(response?.u_value_wall) >= 0.7
          ) {
            total = total + 0;
          }
          // cavity false conditions starts from here
          // if(cavity false ** value < 0.6 {total = 2
          if (
            response?.u_value_wall !== null &&
            parseInt(response?.has_cavity) === 0 &&
            parseInt(response?.u_value_wall) < 0.25
          ) {
            total = total + 2;
          }
          // else if cavity false ** value >=0.6 && < 1.65 {total =1
          else if (
            response?.u_value_wall !== null &&
            parseInt(response?.has_cavity) === 0 &&
            parseInt(response?.u_value_wall) >= 0.25 &&
            parseInt(response?.u_value_wall) < 0.45
          ) {
            total = total + 1;
          }
          // else if cavity false ** value > 1.65 {total =0
          else if (
            response?.u_value_wall !== null &&
            parseInt(response?.has_cavity) === 0 &&
            parseInt(response?.u_value_wall) >= 0.45
          ) {
            total = total + 0;
          }
        }
      }
      // roof
      if (
        item?.renovation === "roofAttic" ||
        item?.renovation === "roofIn" ||
        item?.renovation === "roofExt"
      ) {
        // roof
        let roofValue = response?.roof;
        if (roofValue !== "" && roofValue !== undefined) {
          if (roofValue === "") {
            total = total + 0;
          } else if (
            roofValue?.u_value === 0.8 ||
            (roofValue?.u_value === 0.4 && roofValue?.roof_type === "exterior")
          ) {
            total = total + 1;
          } else if (
            roofValue?.u_value === 0.4 ||
            (roofValue?.u_value === 0.2 && roofValue?.roof_type === "exterior")
          ) {
            total = total + 2;
          } else if (
            roofValue?.u_value === 0.2 ||
            (roofValue?.u_value === 0.15 && roofValue?.roof_type === "exterior")
          ) {
            total = total + 3;
          }
        } else {
          // roof conditions for call zero
          // < 0.25
          if (parseInt(response?.u_value_roof) < 0.25) {
            total = total + 3;
          }
          //
          else if (
            parseInt(response?.u_value_roof) >= 0.25 &&
            parseInt(response?.u_value_roof) < 0.45
          ) {
            total = total + 2;
          }
          //
          else if (
            parseInt(response?.u_value_roof) >= 0.45 &&
            parseInt(response?.u_value_roof) < 0.85
          ) {
            total = total + 1;
          } else if (parseInt(response?.u_value_roof) >= 0.85) {
            total = total + 0;
          }
        }
      }
      // Ventillation
      if (item?.renovation === "ventC+" || item?.renovation === "ventD") {
        if (response?.mechanical_system_type === null) {
          total = total + 0;
        } else if (response?.mechanical_system_type === "system_c") {
          total = total + 1;
        } else if (response?.mechanical_system_type === "system_cplus") {
          total = total + 2;
        } else if (response?.mechanical_system_type === "system_d") {
          total = total + 4;
        }
      }
      // heatPump & condGas
      if (item?.renovation === "condGas" || item?.renovation === "heatPump") {
        let heating = response?.heating;
        if (heating !== "" && heating !== undefined) {
          if (
            (heating?.heating_type === "boiler" &&
              heating?.subtype === "condensing") ||
            heating?.heating_type === "heat_pump"
          ) {
            total = total + 1;
          }
        } else {
          if (
            (response?.heating_type === "boiler" &&
              response?.heating_sub_type === "condensing") ||
            response?.heating_type === "heat_pump"
          ) {
            total = total + 1;
          }
        }
      }
    });
    return total;
  };

  const furtureEPC = () => {
    let selectedRenovationsValue: any = [];
    let unSelectedRenovationsValue: any = [];
    let selecdtedtotal = 0;
    let unSelectedTotal = 0;
    renovations?.map((item: any) => {
      if (item?.active === 1) {
        selectedRenovationsValue.push(item);
      } else {
        unSelectedRenovationsValue.push(item);
      }
    });
    // selected renovations
    let selectedTotalValue = selectedRenovationsTotal(
      selecdtedtotal,
      selectedRenovationsValue
    );
    let unSelectedTotalValue = unSelectedRenovationsTotal(
      unSelectedTotal,
      unSelectedRenovationsValue,
      refinementCall2Record && refinementCall2Record?.length !== 0
        ? refinementCall2Record
        : allRenovationRecord
    );
    // console.log("selectedTotalValue", selectedTotalValue);
    // console.log("unSelectedTotalValue", unSelectedTotalValue);
    let allSelectedValues: any =
      ((selectedTotalValue + unSelectedTotalValue) / 14) * 100;
    // console.log("unSelectedTotalValue", allSelectedValues);
    // 5 star logic for customStars && customPackSelected || Pack A selected || if Pack A is selected in BaseOffer
    if (
      (customStars === 5 && customPackSelected === true) ||
      (customStars === 5 && selectedPack === "baseOffer") ||
      (customStars === 5 && selectedPack === "packA")
    ) {
      return activeStars(5);
    }
    // 4 star logic for Pack B and if no renovations exist and pack b is selected then we have to show 5 stars
    if (selectedPack === "packB") {
      if (customStars === 4 && selectedPack === "packB") {
        return activeStars(4);
      } else {
        return activeStars(5);
      }
    }
    // logic for base offer
    else if (selectedPack === "baseOffer") {
      customStars = baseFutureEpcHandler(
        parseInt(selectedUserCombination?.epc_ind)
      );
      return activeStars(customStars);
    }

    // custom select and remove logic
    else if (customStars === 0 && parseInt(allSelectedValues) > 0) {
      if (allSelectedValues <= 20) {
        return activeStars(1);
      } else if (allSelectedValues <= 40) {
        return activeStars(2);
      } else if (allSelectedValues <= 60) {
        return activeStars(3);
      } else if (allSelectedValues <= 80) {
        return activeStars(4);
      } else if (allSelectedValues <= 100 || allSelectedValues >= 100) {
        return activeStars(5);
      }
    } else {
      return activeStars(1);
    }
  };

  const currentEPC = (number: any) => {
    if (number === "1") {
      return activeStars(5);
    } else if (number === "2") {
      return activeStars(4);
    } else if (number === "3") {
      return activeStars(3);
    } else if (number === "4") {
      return activeStars(2);
    } else if (number === "5") {
      return activeStars(1);
    } else if (number === "6") {
      return activeStars(1);
    }
  };
  /**
   * Find number of itteration for renting and saling value.
   * @param value is current ind and future ind
   * @returns itterations.
   */
  const findNumberOftterations = (value: any) => {
    const vlu: any = parseInt(value);
    if (vlu > 500) return 0;
    else if (vlu > 400) return 1;
    else if (vlu > 300) return 2;
    else if (vlu > 200) return 3;
    else if (vlu > 100) return 4;
    else if (vlu < 100) return 5;
    else return undefined;
  };
  /**
   * Calculate renting and saling value.
   * @param key is string (renting or sales)
   * @returns renting and saling value.
   */
  const caluculateSalesAndRenting = (key: any) => {
    const currentItteration: any = findNumberOftterations(
      userSelectedData[0]?.epc_ind
    );
    const futureIteration: any = findNumberOftterations(selectedInd);
    let numberOfItteration =
      currentItteration -
      (futureIteration ? futureIteration : currentItteration);
    if (numberOfItteration < 0) {
      numberOfItteration = numberOfItteration * -1;
    }
    if (key === "sales") {
      // const salesValue: any =
      //   ((Math.round(userSelectedData[0]?.epc_ind) - selectedInd) / 100) * 2.3;
      return (numberOfItteration * 2.3).toFixed(1);
    } else if (key === "renting") {
      // const rentingValue: any =
      //   ((Math.round(userSelectedData[0]?.epc_ind) - selectedInd) / 100) * 3.2;
      return (numberOfItteration * 3.2).toFixed(1);
      // rentingValue >= 0 ? Math.round(rentingValue) : 0;
    }
  };

  /* index for bad:18 , medium:19 , good:20 */
  const currentEpcStatus = (epc_label: any) => {
    switch (epc_label) {
      case "1":
        return getResultContent(154);
      // return getResultContent(20);
      case "2":
        return getResultContent(155);
      // return getResultContent(20);
      case "3":
        return getResultContent(156);
      // return getResultContent(19);
      case "4":
        return getResultContent(157);
      // return getResultContent(18);
      case "5":
        return getResultContent(158);
      // return getResultContent(18);
      case "6":
        return getResultContent(158);
      default:
        return getResultContent(158);
    }
  };

  return (
    <div className="listingSection__wrap section_fixedBar shadow-xl bg-neutral-100 px-3">
      {/* PRICE */}
      <div className="flex justify-between items-center py-1 space-x-3 w-full">
        <span className="py-1">{getResultContent(42)}</span>
        <div
          className={`epc-label-status  ${
            (futureEpc && parseInt(futureEpc)) <
            parseInt(userSelectedData[0]?.epc_ind)
              ? currentEpcClassHandler(futureEpc)
              : currentEpcClassHandler(userSelectedData[0]?.epc_ind)
          }
          `}
        >
          {/* current EPC = 400 , future epc === 800*/}
          {(futureEpc && parseInt(futureEpc)) <
          parseInt(userSelectedData[0]?.epc_ind)
            ? currentEpcHandler(futureEpc)
            : currentEpcHandler(userSelectedData[0]?.epc_ind)}
          {/* {currentEpcHandler(futureEpc)} */}
        </div>
      </div>
      <div className="flex mt-0 pt-2 pb-6 justify-between items-center space-x-3 w-full">
        <span className="py-1">{getResultContent(43)}</span>
        {(futureEpc && parseInt(futureEpc)) <
        parseInt(userSelectedData[0]?.epc_ind)
          ? furtureEPC()
          : currentEPC(userSelectedData[0]?.epc_label)}
      </div>
      {/* SUM */}
      <div className="flex flex-col bg-white space-y-4 mt-0 p-4 border-b-r-10 border-b-l-10">
        <div className="flex justify-between">
          <div className="flex items-center py-1 space-x-3 w-1/2">
            <Avatar sizeClass="h-10 w-10" radius="" imgUrl={Renting} />
            <div className="flex flex-col text-sm">
              <span className="font-semibold">
                + {caluculateSalesAndRenting("renting")} %
              </span>
              <span className="py-1">{getResultContent(45)}</span>
            </div>
          </div>
          <div className="flex items-center py-1 space-x-3 w-1/2">
            <Avatar sizeClass="h-10 w-10" radius="" imgUrl={HouseValue} />
            <div className="flex flex-col text-sm">
              <span className="font-semibold">
                + {caluculateSalesAndRenting("sales")} %
              </span>
              <span className="py-1">{getResultContent(46)}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center py-1 space-x-3 w-1/2">
            <Avatar sizeClass="h-10 w-10" radius="" imgUrl={Savings} />
            <div className="flex flex-col text-sm">
              {/* <span className="font-semibold">{totalYearlySavings()} €</span> */}
              <span className="font-semibold">
                {selectedUserCombination?.yearly_savings
                  ? parseInt(selectedUserCombination?.yearly_savings)
                  : 0}{" "}
                €
              </span>
              <span className="py-1">{getResultContent(47)}</span>
            </div>
          </div>
          <div className="flex items-center py-1 space-x-3 w-1/2">
            <Avatar sizeClass="h-10 w-10" radius="" imgUrl={Cloud} />
            <div className="flex flex-col text-sm">
              <span className="font-semibold">
                {selectedUserCombination?.co2_savings
                  ? parseInt(selectedUserCombination?.co2_savings)
                  : 0}{" "}
                kg
              </span>
              {/* <span className="font-semibold">{totalCO2()} kg</span> */}
              <span className="py-1">{getResultContent(48)}</span>
            </div>
          </div>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <span>{getResultContent(49)}</span>
        </div>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <div className="flex">
            <span>{getResultContent(50)}</span>
          </div>
          <span>{totalBudget()} €</span>
        </div>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <div className="flex items-center">
            <span>{getResultContent(51)}</span>
            <span
              onClick={() => {
                openModal("total-modal");
                setRenovationModalContent("subsidy");
              }}
              className="cursor-pointer px-2"
            >
              <Avatar
                imgUrl={informationImg}
                sizeClass="h-5 w-5"
                radius="rounded-full"
              />
            </span>
          </div>
          <span>-{totalSubsidy()} €</span>
        </div>
        <div className="flex text-xl text-blue-500 justify-between">
          <div className="flex items-center">
            <span className="pr-2 text-lg">{getResultContent(54)}</span>
            <span
              className="cursor-pointer"
              onClick={() => {
                openModal("total-modal");
                setRenovationModalContent("total");
              }}
            >
              <Avatar
                imgUrl={informationImg}
                sizeClass="h-5 w-5"
                radius="rounded-full"
              />
            </span>
          </div>
          <span className="text-lg">{total()} €</span>
        </div>

        {userStatus?.data?.status == 5 ? (
         !isAdminView && <a
            href={userStatus?.data?.fileUrl}
            target="_blank"
            download="offer.pdf"
            className="w-full"
          >
            <ButtonPrimary
              className={`bg-primary-5000 hover:bg-primary-700 rounded text-neutral-50 w-full `}
            >
              {buttonTextHandler(userStatus?.data?.status)}
            </ButtonPrimary>
          </a>
        ) : (
          !isAdminView &&<ButtonPrimary
            className={`bg-primary-5000 hover:bg-primary-700 rounded text-neutral-50 `}
            onClick={() => {
              modelHandler(userStatus?.data?.status);
            }}
          >
            {buttonTextHandler(userStatus?.data?.status)}
          </ButtonPrimary>
        )}

        <span className="text-center">{getResultContent(79)}</span>
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
