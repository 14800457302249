import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "shared/Header/ProfileHeader";
import Profile from "components/SectionProfile/SectionProfile";
import SectionScans from "components/SectionScans/SectionScans";
import Heading from "components/Heading/Heading";
import Content from "components/Content/Content";
import Image from "images/profile/neighbourhood.svg";
import { GetAllUserProfileStatusByService, GetUserProfileByService } from "services/ResultPage";
import jwt_decode from "jwt-decode";
import cookie from "react-cookies";
import { GetContentByService } from "../../services/homepage";
import { withRouter } from "react-router";
import ContactInfo from 'shared/Modal/ContactInfo';
import { Markup } from "interweave";

function PageHome(props: any) {
  let { history, match } = props;
  const [sectionContent, setSectionContent]: any = useState([]);
  const [userScan, setUserScans] = useState([]);
  const [userProfile, setUserProfile]= useState();
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [isOpen, setIsOpen] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	}

	const closesModal = () => {
		setIsOpen(false);
	}
  useEffect(() => {
    let token = cookie.load("token");
    if (token) {
      let decoded: any = jwt_decode(token);
      if (decoded) {
        setLoggedInUserDetail(decoded);
        GetAllUserProfileStatusByService(decoded?.id)
          .then((response: any) => {
            setUserScans(response?.data);
          })
          .catch((error) => {
            console.log(error);
          });

          GetUserProfileByService(decoded?.id)
          .then((response: any) => {
            setUserProfile(response?.data?.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      let cityId: any = { id: 180 };
      GetContentByService(cityId)
        .then((response) => {
          setSectionContent(response?.data?.data?.page_contents);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  
  // Method to return value through index.

  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Renocity</title>
      </Helmet>
	  <ContactInfo 
			isOpenModal={isOpen} 
			closeModal={closesModal} 
			getValue={getValue} 
			/>
	  <Header getValue={getValue} openModal={openModal} />
	   <div className="container profile_page_container relative space-y-24 mb-24 lg:space-y-32 pt-14 lg:mb-32 result_common_model_container">
        <div className="profile_sections_container">
          <div className="lg:w-5/6">
            <div className="flex flex-col lg:flex-row justify-between px-4">
              <div className="section_one_container">
                <Heading className="fs_22 items-start" desc="">
                  {getValue(0)}
                </Heading>
                <Content desc={getValue(1)}></Content>
              </div>
              <div className="flex justify-end ">
                <div>
                  <img src={Image} alt="" />
                </div>
              </div>
            </div>
            <Profile
              getValue={getValue}
              userProfile={userProfile}
              setUserProfile={setUserProfile}
            />
            <SectionScans getValue={getValue} userScan={userScan} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PageHome);
