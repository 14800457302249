import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import WhoWeAreSection from "components/WhoWeAreSection/WhoWeAreSection";
import { GetContentByService } from "../../services/homepage";
import { withRouter } from "react-router";
import { Markup } from "interweave";

function PageHome() {
  const [sectionContent, setSectionContent]: any = useState([]);
  useEffect(() => {
    let cityId: any = { id: 5 };
    GetContentByService(cityId)
      .then((response) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Renocity</title>
      </Helmet>
      <div className="">
        <WhoWeAreSection getValue={getValue} />
      </div>
    </div>
  );
}

export default withRouter(PageHome);
