import React, { FC } from "react";
import imagePng from "images/landingPage/MapDutch.png";
import EPC1 from "images/landingPage/epc/e2.png";

export interface SectionHeroProps {
  className?: string;
  getValue?: any;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "", getValue }) => {
  return (
    <div
      className={`nc-SectionCity flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      {/* <div className="flex flex-col lg:flex-row"> */}
      <div className="flex flex-col lg:flex-row sectionCity_content_container">
        {/* <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pt-11 pb-14 lg:pb-60 xl:pr-14 lg:mr-10 xl:mr-0"> */}
        <div className="left-side-content z-10 flex left_side justify-center items-center">
          <div className="h-fit-content image_container">
            <img className="" src={imagePng} alt="hero" />
          </div>
        </div>
        <div className="img-content-container flex-shrink-0 right_side flex items-start space-y-8 sm:space-y-10 pb-14 px-5 py-2">
          <div className="content-container flex h-full justify-center flex-col">
            <h2 className="text-3xl md:text-4xl font-semibold pb-2">
              {getValue(32)}
            </h2>
            <p className="whitespace_prewrap mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
              {getValue(33)}
            </p>
            {/* <p className="whitespace_prewrap form-info text-base lg:text-md md:text-md text-neutral-500 dark:text-neutral-400">
              {getValue(49)}
            </p> */}
            <div className="flex items-center pt-8">
              <img className="h-12" src={EPC1} alt="hero" />
              <p className="font-semibold px-2 m-0">
                Gemiddeld EPC in Sint-Anna
              </p>
            </div>
            <div className="flex items-center py-4">
              <img className="h-12" src={EPC1} alt="hero" />
              <p className="font-semibold px-2 m-0">
                Gemiddeld EPC in Hof Somergem
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
