import React, { FC } from "react";
import defaultImg from "images/new-assets/illustration_banner/undraw_discount_d-4-bd.svg";
import AutoCompleteSearch from "shared/AutoCompleteSearch/AutoCompleteSearch";

export interface SectionHeroProps {
  className?: string;
  getValue?: any;
  title?: any;
  searchText?: any;
  buttonText?: any;
  image?: any;
  modelText?: any;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  getValue,
  title,
  searchText,
  buttonText,
  image,
  modelText
}) => {
  return (
    <div
      className={`nc-SectionHero bg-primary-300 shadow-lg rounded-3xl benefits-search-box-div flex flex-col-reverse lg:flex-col relative ${className} w-full`}
      data-nc-id="SectionHero"
    >
      <div>
        <div className="">
          <h2 className="font-medium text-xl pt-10 pb-4 text-left m-0 px-10">
            <span className="text-white">{title}</span>
          </h2>
          <div className="flex flex-col lg:flex-row xl:flex-row pb-16">
            <div className="w-full px-10">
              <div className="mt-1">
                <AutoCompleteSearch
                  className="w-full"
                  searchText={searchText}
                  buttonText={buttonText}
                  modelText={modelText}
                />
              </div>
            </div>
            {/* <div className="w-2/3">
            <div className="mt-1">
              <AutoCompleteSearch
                className="w-full"
                searchText={searchText}
                buttonText={buttonText}
              />
            </div>
          </div> */}
            {/* <div className="flex justify-center">
              <img
                className="benefits-search-box-image"
                src={image ? image : defaultImg}
                alt="hero"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHero;