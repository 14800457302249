import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Button from "shared/Button/ButtonPrimary";
import { Input, Menu, Checkbox } from "antd";
import { userLoginService } from "services/login";
import cookie from "react-cookies";
import SignUpModal from "./SignupModal";
import { GetContentByService } from "../../services/homepage";
import { withRouter } from "react-router";
import { Markup } from "interweave";

const Modal = (props: any) => {
  let {
    isOpenModal,
    closeModal,
    setLogin,
    setFutureEpc,
    setPackLabel,
    openModal,
    setForgetPassword,
    forgetPassword,
    setResultPageLoader,
    history,
  } = props;
  const [current, setCurrent] = useState("login");
  const [error, setError] = useState("");
  const [sectionContent, setSectionContent]: any = useState([]);
  useEffect(() => {
    let cityId: any = { id: 1 };
    GetContentByService(cityId)
      .then((response) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };

  const handleClick = (e: any) => {
    setCurrent(e.key);
  };
  const [userCredentials, setUsersCredentials] = useState({
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);

  const loginHandler = (e: any) => {
    e.preventDefault();
    setLoader(true);
    userLoginService(userCredentials)
      .then((response: any) => {
        cookie.save("token", response.data.token, { maxAge: 15000, path: "/" });
        setError("");
        setLoader(false);
        closeModal();
        setLogin(true);
        if (history?.location?.pathname === "/benefits") {
          history.push("/benefits");
        }
        if (history?.location?.pathname === "/") {
          history.push("/");
        }
        if (history?.location?.pathname === "/renovates") {
          history.push("/renovates");
        }
        if (history?.location?.pathname === "/process") {
          history.push("/process");
        }
        if (history?.location?.pathname === "/who-we-are") {
          history.push("/who-we-are");
        }
        if (history?.location?.pathname === "/contact-us") {
          history.push("/contact-us");
        }
      })
      .catch((error: any) => {
        setError("incorrect email/pass");
        setLoader(false);
      });
  };
  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center result_common_model_container login_model_container">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full">
              <div className="inline-flex flex-col w-full lg:w-1/2 text-left align-middle transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h3
                    className="text-sm m-0 text-left text-gray-500"
                    id="headlessui-dialog-title-70"
                  >
                    {getValue(51)}
                  </h3>
                  <span className="absolute right-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>
                {/* menu */}
                <div className="px-8">
                  <Menu
                    onClick={handleClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                  >
                    <Menu.Item key="login">{getValue(53)}</Menu.Item>
                    <Menu.Item key="signup">{getValue(52)}</Menu.Item>
                  </Menu>
                </div>
                {current === "signup" && (
                  <SignUpModal
                    setCurrent={setCurrent}
                    getValue={getValue}
                    openModal={openModal}
                    isOpenModal={isOpenModal}
                    //
                    closeModal={closeModal}
                    setLogin={setLogin}
                    setFutureEpc={setFutureEpc}
                    setPackLabel={setPackLabel}
                  />
                )}
                {current === "login" && (
                  <>
                    {error && (
                      <div className="px-8 pt-8">
                        <div
                          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                          role="alert"
                        >
                          <span className="block sm:inline">{error}</span>
                        </div>
                      </div>
                    )}
                    <form onSubmit={(e) => loginHandler(e)}>
                      <div className="py-8">
                        {/* email and phone number*/}
                        <div className="space-y-6 py-2">
                          <div className="flex">
                            <div className="px-8 w-full">
                              <span className="block my-2 text-black">
                                {getValue(54)}*
                              </span>
                              <Input
                                className="w-full"
                                placeholder=""
                                required
                                onChange={(e) => {
                                  let duplicateData = { ...userCredentials };
                                  duplicateData.email = e.target.value;
                                  setUsersCredentials(duplicateData);
                                }}
                              />
                            </div>
                            <div className="px-8 w-full">
                              <span className="block my-2 text-black">
                                {getValue(55)}*
                              </span>
                              <Input
                                className="w-full"
                                placeholder=""
                                type="password"
                                required
                                onChange={(e) => {
                                  let duplicateData = { ...userCredentials };
                                  duplicateData.password = e.target.value;
                                  setUsersCredentials(duplicateData);
                                }}
                              />
                              <div
                                className="pt-4 cursor-pointer"
                                // onClick={() => closeModal()}
                              >
                                <p
                                  onClick={() => {
                                    setForgetPassword(true);
                                    closeModal();
                                  }}
                                >
                                  <a><u>
                                  {/* wachtwoord vergeten */}
                                  {getValue(111)}
                                  </u>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-6 py-4 button_container flex justify-end border-b border-neutral-100 bg-neutral-100 dark:border-neutral-800 text-center">
                        <Button type="submit" disabled={loader ? true : false}>
                          {getValue(56)}
                        </Button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
export default withRouter(Modal);
