import { useEffect, useState } from "react";
import axios from "axios";
import Avatar from "shared/Avatar/Avatar";
import City from "images/new-assets/vector/city.svg";
import Computer from "images/new-assets/vector/computer.svg";
import HigherComfort from "images/new-assets/vector/higher_comfort.svg";
import Process from "images/new-assets/vector/process.svg";
import Stronger_together from "images/new-assets/vector/stronger_together.svg";
import Subsidies from "images/new-assets/vector/subsidies.svg";
import { Markup } from 'interweave';

function SideNav(props: any) {
  let { setBenefitsPage, benefitsPage } = props;
  const [benefitsTitles, setBenefitsTitles]: any = useState()
  const [navs, setNavs]: any = useState()
  

   // Method to return value through index.
   const getValue = (index: number): any => {
    return benefitsTitles?.length !== 0 && benefitsTitles[index]?.value
      ? benefitsTitles[index]?.value
      : "";
  };

  useEffect(() => {
    // api call to get all menus
    // PageContentByService(1, "menu")
    let payload = { city_id: 1, datatype: "menu" };
    axios
      .post(process.env.REACT_APP_APP_URL + `/getCityDataByType`, payload)
      .then((res) => {
        setBenefitsTitles(res?.data?.data?.pages[1]?.page_contents);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    if(benefitsTitles)
      setNavs([
        {
          id: 1,
          name: benefitsTitles[154].value,
          class: benefitsPage === "energy-bills" ? "active" : "inActive",
          image: HigherComfort,
        },
        {
          id: 2,
          name: benefitsTitles[155].value,
          class: benefitsPage === "stronger-together" ? "active" : "inActive",
          image: Stronger_together,
        },
        {
          id: 3,
          name: benefitsTitles[156].value,
          class: benefitsPage === "support-subsidies" ? "active" : "inActive",
          image: Subsidies,
        },
        {
          id: 4,
          name: benefitsTitles[157].value,
          class: benefitsPage === "classic-renovations" ? "active" : "inActive",
          image: Computer,
        },
        {
          id: 5,
          name: benefitsTitles[158].value,
          class: benefitsPage === "process" ? "active" : "inActive",
          image: Process,
        },
        {
          id: 6,
          name: benefitsTitles[159].value,
          class: benefitsPage === "unique-oppertunity" ? "active" : "inActive",
          image: City,
        },
      ])

  },[benefitsTitles,benefitsPage])

  const setPageHandler = (value: any) => {
    switch (value) {
      case benefitsTitles[154].value:
        return setBenefitsPage("energy-bills");
      case benefitsTitles[155].value:
        return setBenefitsPage("stronger-together");
      case benefitsTitles[156].value:
        return setBenefitsPage("support-subsidies");
      case benefitsTitles[157].value:
        return setBenefitsPage("classic-renovations");
      case benefitsTitles[158].value:
        return setBenefitsPage("process");
      case benefitsTitles[159].value:
        return setBenefitsPage("unique-oppertunity");
      default:
        return setBenefitsPage("energy-bills");
    }
  };
  return (
    <div className="nc-sidenav-components">
      {navs?.map((nav:any) => (
        <div
          className={`rounded-xl flex hover_shadow_lg my-4 py-4 px-4 nav_box ${nav?.class}`}
          onClick={() => {
            setPageHandler(nav?.name);
          }}
        >
          <div>
            <Avatar sizeClass="h-18 w-20" radius="" imgUrl={nav?.image} />
          </div>
          <div className="px-4">
            <span className="side-nav-text-center"><Markup content={nav?.name} /></span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SideNav;
