import axios from "axios";
require("dotenv").config();

//post api for login
export const userLoginService = (payload: Object) => {
  return axios.post(process.env.REACT_APP_APP_URL + `/user/login`, payload);
};
//post api for login
export const userSignupService = (payload: Object) => {
  return axios.post(process.env.REACT_APP_APP_URL + `/user/signup`, payload);
};
//post api for login
export const emailVerificationByService = (payload: Object) => {
  return axios.post(
    process.env.REACT_APP_APP_URL + `/user/getuserbyemail`,
    payload
  );
};
//post api for reset password
export const resetPasswordByService = (id: any, payload: Object) => {
  return axios.post(
    process.env.REACT_APP_APP_URL + `/user/resetpassword/${id}`,
    payload
  );
};
// post call to save user on Hubspot
export const createHubsportUserByService = (
  portalId: any,
  formGuid: any,
  payload: any,
  config: any
) => {
  return axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    payload,
    config
  );
};
