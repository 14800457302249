const Notification = (props: any) => {
  let { title, content, textColor, className } = props;
  return (
    <div
      className={`notification-box absolute flex my-4 py-4 px-4 right-0 bg-white shadow-lg rounded-lg z-10  ${className}`}
    >
      <span
        className={`font-medium text-4xl landing-notification-title pb-0 ${textColor}`}
      >
       {title}
      </span>
      <span className="text-neutral-600 landing-notification-content">{content}</span>
    </div>
  );
};

export default Notification;
