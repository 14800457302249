import SideNav from "./SideNav/SideNav";
import EnergyBills from "./SectionsBenefits/EnergyBills";
import StrongerTogether from "./SectionsBenefits/StrongerTogether";
import SupportSubsidies from "./SectionsBenefits/SupportSubsidies";
import ClassicRenovations from "./SectionsBenefits/ClassicRenovations";
import Process from "./SectionsBenefits/Process";
import UniqueOpprtunity from "./SectionsBenefits/UniqueOppertunity";
import { GetContentByService } from "../../services/homepage";
import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Markup } from "interweave";
import BookAnAuditModal from "shared/Modal/BookAnAuditForm";
import Modal from "shared/Modal/index";
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";

function Benefits() {
  const [benefitsPage, setBenefitsPage] = useState("energy-bills");
  const [sectionContent, setSectionContent]: any = useState([]);
  const [auditModal, setAuditModal] = useState(false);
  const [isTop, setisTop] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [login, setLogin] = useState(false);
  useEffect(() => {
    let token = cookie.load("token");
    if (token) {
      let decoded = jwt_decode(token);
      if (decoded) {
        setLogin(true);
      }
    }

    let cityId: any = { id: 2 };
    GetContentByService(cityId)
      .then((response) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cookie?.load("token")]);

  function openModal(activeModal: any) {
    setIsOpenModal(true);
    setActiveModal(activeModal);
  }
  function closeModal() {
    setIsOpenModal(false);
    setActiveModal("");
  }

  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };
  const benefitsPageHandler = (value: any) => {
    switch (value) {
      case "energy-bills":
        return <EnergyBills getValue={getValue} />;
      case "stronger-together":
        return <StrongerTogether getValue={getValue} />;
      case "support-subsidies":
        return <SupportSubsidies getValue={getValue} />;
      case "classic-renovations":
        return <ClassicRenovations getValue={getValue} />;
      case "process":
        return <Process getValue={getValue} />;
      case "unique-oppertunity":
        return (
          <UniqueOpprtunity
            getValue={getValue}
            setAuditModal={setAuditModal}
            openModal={openModal}
            login={login}
          />
        );
      default:
        return <EnergyBills getValue={getValue} />;
    }
  };
  return (
    <div className="nc-PageHome relative overflow-hidden flex">
      {auditModal && (
        <BookAnAuditModal setModal={setAuditModal} modal={auditModal} getValue={getValue} />
      )}
      <Modal
        activeModal={activeModal}
        closeModal={closeModal}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        setLogin={setLogin}
      />
      <div className="w-33p">
        <SideNav
          setBenefitsPage={setBenefitsPage}
          benefitsPage={benefitsPage}
        />
      </div>
      <div className="w-67p py-4">{benefitsPageHandler(benefitsPage)}</div>
    </div>
  );
}

export default withRouter(Benefits);
