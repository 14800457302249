import { Helmet } from "react-helmet";
import Banner from "images/banner_images/banner_3.png";
import SectionOppertunity from "components/SectionUniqueOppertunity/SectionUniqueOppertunity";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import { withRouter } from "react-router";

function PageHome(props: any) {
  let { getValue, setAuditModal, openModal, login } = props;
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Renocity</title>
      </Helmet>
      <div className=" relative space-y-16">
        {/* SECTION HERO */}
        <div className="w-full">
          <img src={Banner} width="100%" alt="" />
          <div className="py-4">
            <h2 className="text-xl font-medium text-neutral-600">
              <span className="">{getValue(189)}</span>
            </h2>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full">
            <div className="">
              <SectionOppertunity setAuditModal={setAuditModal} getValue={getValue} openModal={openModal} login={login} />
            </div>
            <div className="pt-14 flex justify-center items-center">
              <SectionCalculateBenefit
                title={
                  getValue
                    ? getValue(145)
                    : "How we can help you to reach label A ?"
                }
                searchText={getValue ? getValue(6) : "Search Address"}
                buttonText={getValue ? getValue(146) : "Mijn diagnose"}
                modelText={getValue ? getValue(197) : ""}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PageHome);
