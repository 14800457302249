import React, { FC } from "react";
import { Button } from "antd";
import HandImg from "../../images/hands/hand_3.png";

export interface SectionHeroProps {
  className?: string;
  getResultContent: any;
  selectedPack: any;
  setSelectedPack: any;
  onChange: any;
  showAllRenovations: any;
  custom: any;
  setCustom: any;
  baseOfferRenovation: any;
  userStatus: any;
}

const SectionOurRecomendation: FC<SectionHeroProps> = ({
  className,
  getResultContent,
  selectedPack,
  setSelectedPack,
  onChange,
  showAllRenovations,
  custom,
  setCustom,
  baseOfferRenovation,
  userStatus,
}) => {
  return (
    <div className="listingSection__wrap packs_container !space-y-6">
      <div className="flex justify-center">
        <p className="fs_22 font_semibold m-0">
          {" "}
          {getResultContent && getResultContent(291)}
        </p>
      </div>
      <div className="flex justify-center flex-wrap">
        {userStatus?.data &&
        userStatus?.data &&
        userStatus?.data?.status &&
        parseInt(userStatus?.data?.status) > 4 ? (
          ""
        ) : (
          <>
            <div className="px-2 py-2">
              <Button
                className={
                  selectedPack === "packA"
                    ? "primary_button"
                    : "secondary_button"
                }
                size={"large"}
                onClick={() => {
                  onChange(false);
                  setSelectedPack("packA");
                  setCustom(false);
                }}
              >
                {getResultContent && getResultContent(24)}
              </Button>
            </div>
            <div className="px-2 py-2">
              <Button
                size={"large"}
                className={
                  selectedPack === "packB"
                    ? "primary_button"
                    : "secondary_button"
                }
                onClick={() => {
                  onChange(true);
                  setSelectedPack("packB");
                  setCustom(false);
                }}
              >
                {" "}
                {getResultContent && getResultContent(25)}
              </Button>
            </div>
            <div className="px-2 py-2">
              <Button
                size={"large"}
                className={
                  selectedPack === "customPack"
                    ? "primary_button"
                    : "secondary_button"
                }
                onClick={() => {
                  showAllRenovations(true);
                  setSelectedPack("customPack");
                }}
              >
                {" "}
                {getResultContent && getResultContent(26)}
              </Button>
            </div>
          </>
        )}
        {userStatus?.data?.status && parseInt(userStatus?.data?.status) > 3 && (
          <div className="px-2">
            <Button
              size={"large"}
              className={
                selectedPack === "baseOffer"
                  ? "primary_button"
                  : "secondary_button"
              }
              onClick={() => {
                setSelectedPack("baseOffer");
                baseOfferRenovation();
              }}
            >
              Base for offer
            </Button>
          </div>
        )}
      </div>
      <div className="flex justify-center">
        {/* a */}
        {selectedPack === "packA" && (
          <p className="text-center">
            {getResultContent && getResultContent(234)}
          </p>
        )}
        {/* b */}
        {selectedPack === "packB" && (
          <p className="text-center">
            {getResultContent && getResultContent(235)}
          </p>
        )}
        {/* custom */}
        {selectedPack === "customPack" && (
          <p className="text-center">
            {getResultContent && getResultContent(236)}
          </p>
        )}
        {/* baseOffer */}
        {selectedPack === "baseOffer" && (
          <p className="text-center">
            {getResultContent && getResultContent(236)}
          </p>
        )}
      </div>
      {/* <div className="absolute hand_image">
        <img src={HandImg} className="" />
      </div> */}
    </div>
  );
};

export default SectionOurRecomendation;
