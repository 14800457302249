import LinkedIn from "images/social/linkedin.svg";
import Instagram from "images/social/insta.svg";
import Facebook from "images/social/fb.svg";
import logoImg from "images/agc_color.png";
import { Input } from "antd";
import { useHubspotForm } from "react-use-hubspot-form";

function PageHome(props: any) {
  let { getValue } = props;
  const { loaded, error, formCreated } = useHubspotForm({
    region: "eu1",
    portalId: "25186371",
    formId: "62de4135-18ec-4899-b66d-65c165d120d7",
    target: "#my-hubspot-form",
  });
  return (
    <>
      <div className="nc-PageHome relative contactUs-container overflow-hidden flex justify-between py-4">
        {/* left side */}
        <div className="w-1/3 full-width-responsive">
          <div className="contactUs-left-container px-2">
            <p className="font-medium text-lg">{getValue(0)}</p>
            <div className="flex flex-col py-2 text-sm">
              <span className="py-2">{getValue(1)}</span>
              <span className="py-4">{getValue(2)}</span>
              <span className="py-2">{getValue(3)}</span>
            </div>
            {/* <div className="flex py-2">
              <div>
                <img
                  src={getValue(8) ? getValue(8) : Facebook}
                  alt=""
                  className="h-6 w-6"
                />
              </div>
              <div className="px-2">
                <img
                  src={getValue(9) ? getValue(9) : Instagram}
                  alt=""
                  className="h-6 w-6"
                />
              </div>
            </div> */}
            <div className="py-4">
              <img src={getValue(23)} alt="" className="h-12" />
            </div>
          </div>
          <div className="py-8 pr-4">
            <span className="text-left whitespace_prewrap">{getValue(21)}</span>
          </div>
        </div>
        {/* middle box */}
        <div className="shadow-lg w-1/3 rounded-lg py-4 px-4 full-width-responsive">
          <div id="my-hubspot-form"></div>
          {/* checkboxes */}
          {/* <div className="flex justify-between">
            <Radio>Contact Renocity</Radio>
            <Radio>Contact City / Subsidy</Radio>
          </div>
          <div className="py-4 form-container">
            <div className="w-full py-2">
              <Input className="w-full rounded-lg" placeholder="First name" />
            </div>
            <div className="w-full py-2">
              <Input className="w-full rounded-lg" placeholder="Last name *" />
            </div>
            <div className="w-full py-2">
              <Input className="w-full rounded-lg" placeholder="Email *" />
            </div>
            <div className="w-full py-2">
              <Input className="w-full rounded-lg" placeholder="Phone number" />
            </div>
            <div className="w-full py-2">
              <TextArea
                rows={4}
                className="w-full rounded-lg"
                placeholder="Message"
              />
            </div>
          </div>
          <div className="w-full">
            <Button className="w-full rounded" type="primary" size={"large"}>
              Send
            </Button>
          </div> */}
        </div>
        {/* right side */}
        <div className=" w-1/3 full-width-responsive">
          <div className="contactUs-right-container px-2 flex flex-col items-end">
            <p className="font-medium text-lg">{getValue(4)}</p>
            <div className="flex flex-col py-2 text-sm items-end">
              <span className="py-2">{getValue(5)}</span>
              <span className="py-4">{getValue(6)}</span>
              <span className="py-2">{getValue(7)}</span>
            </div>
            {/* <div className="flex justify-end py-2 ">
              <div>
                <img
                  src={getValue(8) ? getValue(8) : Facebook}
                  alt=""
                  className="h-6 w-6"
                />
              </div>
              <div className="px-2">
                <img
                  src={getValue(10) ? getValue(10) : LinkedIn}
                  alt=""
                  className="h-6 w-6"
                />
              </div>
            </div> */}
            <div className="py-4 flex justify-end">
              <img src={getValue(24)} alt="" className="h-12" />
            </div>
          </div>
          <div className="py-8 pl-4 text-right">
            <span className="whitespace_prewrap">{getValue(22)}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHome;
