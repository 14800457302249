import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import Arrow from "images/resultPage/Path6.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";
import TickArrow from "images/resultPage/header/check_3.svg";
import Number2 from "images/resultPage/header/number_2.svg";
import Number3 from "images/resultPage/header/number_3.svg";
import Number4 from "images/resultPage/header/number_4.svg";
// import Profile from "images/profile/cta_profile.svg";
import Avatar from "shared/Avatar/Avatar";
import UserIcon from "images/landingPage/user.svg";
import { withRouter } from "react-router";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Phone from "images/resultPage/userDetail/telephone.png";
import Profile from "images/resultPage/userDetail/user.png";
import { Button } from "antd";

const MainNav1: FC<any> = (props: any) => {
  let {
    isTop,
    history,
    openModal,
    userStatus,
    setLoggedInUser,
    getResultContent,
    setRefinementCall2Record,
    setRefinementCall3Record,
    //
    setFutureEpc,
    setPackLabel,
    setLoader,
  } = props;
  const [login, setLogin] = useState(false);
  useEffect(() => {
    let token = cookie.load("token");
    if (token) {
      let decoded = jwt_decode(token);
      if (decoded) {
        setLogin(true);
      }
    }
  }, [cookie?.load("token")]);

  const logoutHandler = () => {
    let token = cookie.load("token");
    if (token) {
      cookie.remove("token", { maxAge: 15000, path: "/" });
      setLogin(false);
      setLoggedInUser({});
      setRefinementCall2Record();
      setRefinementCall3Record();
      setFutureEpc("1");
      setPackLabel(false);
      setLoader(true);
    }
  };

  const menu_status = [
    {
      name: getResultContent(74),
      icon: TickArrow,
      textColor: "text-blue-500",
      arrowImg: 1,
    },
    {
      name: getResultContent(75),
      icon: userStatus?.data?.status >= 2 ? TickArrow : Number2,
      textColor:
        userStatus?.data?.status >= 2 ? "text-blue-500" : " text-neutral-300",
      arrowImg: 1,
    },
    {
      name: getResultContent(76),
      icon: userStatus?.data?.status >= 4 ? TickArrow : Number3,
      textColor:
        userStatus?.data?.status >= 4 ? "text-blue-500" : " text-neutral-300",
      arrowImg: 1,
    },
    {
      name: getResultContent(77),
      icon: userStatus?.data?.status === 4 ? TickArrow : Number4,
      textColor:
        userStatus?.data?.status === 4 ? "text-blue-500" : " text-neutral-300",
      arrowImg: 0,
    },
  ];

  return (
    <div
      className={`nc-MainNav1  relative bg-white z-10 shadow-lg  ${
        isTop ? "onTop " : "notOnTop backdrop-filter "
      }`}
    >
      {/* container */}
      <div className="px-8  ant_buttons_container result-header-container relative hidden lg:flex justify-around items-center space-x-4 xl:space-x-8">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="py-2">
            <Logo />
          </div>
          
        </div>
        <div className="flex flex-col lg:flex-row justify-between px-8">
            {menu_status?.map((menu: any, i: any) => (
              <React.Fragment key={i}>
                <div className="flex items-center px-4 py-2">
                  <Avatar
                    imgUrl={menu?.icon}
                    sizeClass="h-6 w-6 text-lg"
                    radius="rounded-full"
                  />
                  <span className={`${menu?.textColor} px-2 text-lg `}>
                    {menu?.name}
                  </span>
                </div>
                {menu?.arrowImg === 1 && (
                  <div className="hidden lg:flex">
                    <img src={Arrow} alt="" />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <div className="px-1" />
            {/* {login ? ( */}
            <div className="flex  items-center">
              <div className="icons_container">
                <Button
                  className="rounded ant_secondary_button ml-2 p-0 lg-500 flex border-neutral-400 p-2 cursor-pointer items-center h-fit-content"
                  onClick={() => {
                    openModal("contact-info");
                  }}
                >
                  <img src={Phone} alt="" className="h-6 w-6 " />
                </Button>
              </div>
              {login ? (
                <div className="icons_container ">
                  <Button
                    className="rounded ant_secondary_button ml-2 p-0 lg-500 flex border-neutral-400 px-2 py-2 cursor-pointer items-center h-fit-content"
                    onClick={() => {
                      history?.push("/profile");
                      openModal("contact-info");
                    }}
                  >
                    <img src={Profile} alt="" className="h-6 w-6 " />
                  </Button>
                  
                
                </div>
              ) : (
                <div className="icons_container  ml-2">
                  <Button
                    onClick={() => {
                      openModal("login");
                    }}
                    className="rounded flex items-center ant_secondary_button"
                  >
                    <span className="px-2">{getResultContent(1)}</span>
                  </Button>
                </div>
              )}
            </div>

            {(parseInt(userStatus?.data?.status) < 2 || !login) && (
              <ButtonPrimary
                className="rounded ml-2 p-10 bg-primary-500 flex items-center"
                onClick={() => {
                  login ? openModal("book-an-audit") : openModal("login");
                }}
              >
                <span className="px-2">{getResultContent(0)}</span>
              </ButtonPrimary>
            )}
           { login && <Button
                danger
                className="rounded ml-1 h-full flex items-center"
                onClick={() => {
                  logoutHandler();
                }}
              >
                <span className="px-2">
                  {getResultContent(294)}
                </span>
              </Button>}
          </div>
        </div>
        {/* <div className="flex items-center xl:hidden">
            <MenuBar />
          </div> */}
      </div>
      {/* responisve button for login and profile page */}
      <div className="py-2 lg:hidden px-2">
        <div className="flex justify-between items-center ">
          {/* logo */}
          <div className="py-2">
            <Logo />
          </div>
          {/* login and profile button */}
          <div>
            {login ? (
              <div className="icons_container ">
                <Button
                  className="rounded ant_secondary_button ml-2 p-0 lg-500 flex border-neutral-400 px-2 py-2 cursor-pointer items-center h-fit-content"
                  onClick={() => {
                    history?.push("/profile");
                    openModal("contact-info");
                  }}
                >
                  <img src={Profile} alt="" className="h-6 w-6 " />
                </Button>
                
              </div>
            ) : (
              <div className="icons_container  ml-2">
                <Button
                  onClick={() => {
                    openModal("login");
                  }}
                  className="rounded flex items-center ant_secondary_button"
                >
                  <span className="px-2">{getResultContent(1)}</span>
                </Button>
                
              </div>
            )}
          </div>
        </div>
        {/* active status */}
        <div className="flex flex-wrap justify-center px-4">
          {menu_status?.map((menu: any, i: any) => (
            <React.Fragment key={i}>
              <div className="flex items-center px-2 py-2 w-1/2">
                <Avatar
                  imgUrl={menu?.icon}
                  sizeClass="h-6 w-6 text-lg"
                  radius="rounded-full"
                />
                <span className={`${menu?.textColor} px-2 text-lg `}>
                  {menu?.name}
                </span>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withRouter(MainNav1);
