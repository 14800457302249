import SideNav from "./SideNav/SideNav";
import WindowAndDoors from "./SectionsRenovates/Window&Doors";
import SolarPanel from "./SectionsRenovates/SolarPanel";
import Roof from "./SectionsRenovates/Roofs";
import WallInsulation from "./SectionsRenovates/WallInsulation";
import HeatingSystem from "./SectionsRenovates/HeatingSystem";
import VentilationSystem from "./SectionsRenovates/VentilationSystem";
import { GetContentByService } from "../../services/homepage";
import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Markup } from "interweave";

function Benefits() {
  const [CurrentPage, setCurrentPage] = useState("Window-doors");
  const [sectionContent, setSectionContent]: any = useState([]);
  useEffect(() => {
    let cityId: any = { id: 3 };
    GetContentByService(cityId)
      .then((response) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };

  const CurrentPageHandler = (value: any) => {
    switch (value) {
      case "Window-doors":
        return <WindowAndDoors getValue={getValue} />;
      case "Solar-panel":
        return <SolarPanel getValue={getValue} />;
      case "roofs":
        return <Roof getValue={getValue} />;
      case "Wall-insulation":
        return <WallInsulation getValue={getValue} />;
      case "Heating-system":
        return <HeatingSystem getValue={getValue} />;
      case "Ventilation-system":
        return <VentilationSystem getValue={getValue} />;
      default:
        return <WindowAndDoors getValue={getValue} />;
    }
  };
  return (
    <div className="nc-PageHome relative overflow-hidden flex">
      <div className="w-33p">
        <SideNav
          getValue={getValue}
          setCurrentPage={setCurrentPage}
          CurrentPage={CurrentPage}
        />
      </div>
      <div className="w-67p py-4">{CurrentPageHandler(CurrentPage)}</div>
    </div>
  );
}

export default withRouter(Benefits);
