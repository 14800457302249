import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Banner from "images/banner-process.png";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import SectionProcess from "components/SectionProcess/SectionProcess";
import { GetContentByService } from "../../services/homepage";
import { withRouter } from "react-router";
import { Markup } from "interweave";

function PageHome() {
  const [sectionContent, setSectionContent]: any = useState([]);
  useEffect(() => {
    let cityId: any = { id: 4 };
    GetContentByService(cityId)
      .then((response) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };
  return (
    <div className="nc-process-container relative overflow-hidden">
      <Helmet>
        <title>Renocity</title>
      </Helmet>
      <div className=" relative space-y-24 mb-24 lg:space-y-32 lg:mb-14">
        {/* SECTION HERO */}
        <div className="nc-process-banner-img flex items-center justify-center">
          <span className="banner-heading">{getValue ? getValue(1) : ""}</span>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-64p">
            <SectionProcess getValue={getValue} />
          </div>
        </div>
        <div className="container flex justify-center">
          {/* <div className="flex justify-center items-center"> */}
          <div className="w-2/3 flex justify-center items-center">
            <SectionCalculateBenefit
              title={
                getValue
                  ? getValue(24)
                  : "How we can help you to reach label A ?"
              }
              searchText={getValue ? getValue(25) : "Search Address"}
              buttonText={getValue ? getValue(26) : "Mijn diagnose"}
              modelText={getValue ? getValue(36) : ""}
            />
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* <div className="container relative py-24 mt-0 w-2/3">
				<SectionHomeTestimonials getValue={[]} />
			</div> */}
    </div>
  );
}

export default withRouter(PageHome);
