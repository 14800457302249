// import Heading from "components/Heading/Heading";
import ContactUs from "images/contact-us/contactus.png";
import SectionContatUs from "components/SectionContactUs/SectionContactUs";
import React, { FC, useEffect, useState } from "react";
import { GetContentByService } from "../../services/homepage";
import { Markup } from 'interweave';

function PageHome() {
  const [sectionContent, setSectionContent]: any = useState([]);
  useEffect(() => {
    let cityId: any = { id: 7 };
    GetContentByService(cityId)
      .then((response: any) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log("🚀 ~ file: ContactUs.tsx ~ line 17 ~ useEffect ~ error", error)
      });
  }, []);

  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value
      ? <Markup content={sectionContent[index]?.value} /> 
      : "";
  };
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className=" relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <div className="bg-gray-300 bg-neutral-100 dark:bg-black dark:bg-opacity-20 ">
          {/* <Heading desc="" isCenter className="mb-0">
              Get in touch
            </Heading> */}
            {/* 20 */}
          {/* <img src={ContactUs} width="100%" alt="" /> */}
          <div className="contact-banner-img flex items-center justify-center">
            <span className="banner-heading">{getValue(25)}</span>
          </div>
        </div>
        {/* SECTION PromotedCity */}
        <div className="container mt-0 py-8">
          <SectionContatUs getValue={getValue} />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
