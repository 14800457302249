import Avatar from "images/banner_images/banner_2.png";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import Wall_Cavity from "images/renovations_images/wall_cavity.png";
import Wall_Exterior from "images/renovations_images/wall_exterior.png";
import { withRouter } from "react-router";

function Benefits(props: any) {
  let { getValue } = props;
  return (
    <div className="w-full">
      <div className="w-full">
        <img src={Avatar} className="h-400 w-full" alt="" />
      </div>
      <div>
        <h2 className="text-xl text-neutral-600 py-4">
          <span className="whitespace_prewrap">{getValue(21)}</span>
        </h2>
      </div>
      <div>
        <p className="text-md text-neutral-500 py-4 whitespace_prewrap">{getValue(22)}</p>
      </div>
      {getValue(23) && <div className="flex py-8">
        {/* 1st */}
        <div className=" w-1/2 pr-4">
          <div className="flex">
            <span className="whitespace_prewrap text-base font-bold md:text-lg text-neutral-500 dark:text-neutral-400">
              {getValue(23)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4 whitespace_prewrap">{getValue(24)}</h2>
          </div>
        </div>
        {/* 2nd */}
        <div className=" w-1/2">
          <div className="flex justify-between full-width-responsive">
            <div>
              <div>
                <img src={Wall_Cavity} alt="" className="h-40" />
              </div>
              <div>
                <h4 className="text-md text-neutral-500 py-4 text-center">
                  {getValue(26)}
                </h4>
              </div>
            </div>
            <div>
              <div>
                <img src={Wall_Exterior} alt="" className="h-40" />
              </div>
              <div>
                <h4 className="text-md text-neutral-500 py-4 text-center">
                  {getValue(74)}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {getValue(75) && <div className="flex py-8">
        {/* 1st */}
        <div className=" w-1/2 pr-4">
          <div className="flex">
            <span className="text-base md:text-lg font-bold text-neutral-500 dark:text-neutral-400">
              {getValue(75)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">{getValue(76)}</span>
            </h2>
          </div>
        </div>
        {/* 2nd */}
        <div className=" w-1/2 px-4">
          <div className="flex">
            <span className="text-base md:text-lg font-bold text-neutral-500 dark:text-neutral-400">
              {getValue(77)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4 ">
              <span className="whitespace_prewrap">{getValue(78)}</span>
            </h2>
          </div>
        </div>
      </div>}
      <div className="flex justify-center items-center">
        <SectionCalculateBenefit
          title={
            getValue ? getValue(79) : "How we can help you to reach label A ?"
          }
          searchText={getValue ? getValue(82) : "Search Address"}
          buttonText={getValue ? getValue(80) : "Mijn diagnose"}
          modelText={getValue ? getValue(146) : ""}
        />
      </div>
      {/* <div className="container relative py-16 mt-0 w-2/3">
        <SectionHomeTestimonials getValue={[]} />
      </div> */}
    </div>
  );
}

export default withRouter(Benefits);
