import { Input } from "antd";
import { useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Button from "shared/Button/ButtonPrimary";
import { SendEmailByService } from "services/homepage";

const Modal = (props: any) => {
  let { setModal, getValue } = props;
  const [email, setEmail] = useState("");
  const sendEmailHandler = (e: any) => {
    e.preventDefault();
    let payload = {
      email: email,
    };
    SendEmailByService(payload).then((response: any) => {});
  };
  return (
    <div className="absolute w-full z-50 top-0 ">
      <div className="h-full fixed w-full background_rgba top-0 flex justify-center px-2 result_common_model_container login_model_container">
        <div className="inline-block py-8 w-full flex justify-center">
          <div className="inline-flex h-fit-content flex-col w-full lg:w-1/2 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
            <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 pt-4 pb-8 border-b border-neutral-200 dark:border-neutral-800 text-center">
              <span className="absolute right-3 top-3 ">
                <ButtonClose onClick={() => setModal(false)} />
              </span>
            </div>
            <div className="px-8 py-8 flex flex-col items-center">
              <div className="px-2 text-center">
                <p className="text-center text-gray-500">{getValue(107)}</p>
              </div>
              <form
                className="px-4 w-full"
                onSubmit={(e: any) => sendEmailHandler(e)}
              >
                <span className="block my-2 text-gray-500">
                  {" "}
                  {getValue(108)}
                </span>
                <Input
                  className="w-full"
                  placeholder=""
                  type="email"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <div className="px-5 button_container flex justify-center py-5">
                  <Button type="submit" className="button">
                    {getValue(109)}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
