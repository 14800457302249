import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import EPCImg from "images/landingPage/label/gradient.png";

export interface SectionGridAuthorBoxProps {
  className?: string;
  getValue?: any;
}

const SectionGridAuthorBox: FC<SectionGridAuthorBoxProps> = ({
  className = "",
  getValue,
}) => {
  return (
    <div
      className={`nc-SectionGridAuthorBox flex justify-center labelA-container relative ${className}`}
      data-nc-id="SectionGridAuthorBox"
    >
      <div className="flex flex-col lg:flex-row justify-between label_content_image_container">
        <div className="content_container">
          <div className=" flex items-center">
            <h4 className="text-3xl md:text-4xl font-semibold">
              {getValue(17)}
            </h4>
          </div>
          <p className="whitespace_prewrap mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            {getValue(18)}
          </p>
        </div>
        <div className="flex image_contianer justify-center">
          <div className="flex-grow img-container flex justify-center">
            <NcImage className={"label-img"} src={EPCImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridAuthorBox;
