import Avatar from "images/renovations/banner/2.png";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import { withRouter } from "react-router";

function Benefits(props: any) {
  let { getValue } = props;
  return (
    <div className="w-full">
      <div className="w-full">
        <img src={Avatar} className="h-400 w-full" alt="" />
      </div>
      <div>
        <h2 className="text-xl text-neutral-600 py-4">
          <span className="whitespace_prewrap">{getValue(3)}</span>
        </h2>
      </div>
      <div>
        <p className="text-md text-neutral-500 py-4">{getValue(4)}</p>
      </div>
      <div className="flex py-8">
        {/* 1st */}
        <div className=" w-1/2 pr-4">
          <div className="flex">
            <span className="text-base md:text-lg font-bold text-neutral-500 dark:text-neutral-400">
              {getValue(5)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">{getValue(6)}</span>
            </h2>
          </div>
        </div>
        {/* 2nd */}
        <div className=" w-1/2 px-4">
          <div className="flex">
            <span className="text-base md:text-lg font-bold text-neutral-500 dark:text-neutral-400">
              {getValue(7)}
            </span>
          </div>
          {/* <div>
            <h2 className="text-md text-neutral-500 py-4 ">
              <span className="whitespace_prewrap">{getValue(10)}</span>
            </h2>
          </div> */}
        </div>
      </div>
      <div className="flex justify-center items-center">
        <SectionCalculateBenefit
          title={
            getValue ? getValue(49) : "How we can help you to reach label A ?"
          }
          searchText={getValue ? getValue(52) : "Search Address"}
          buttonText={getValue ? getValue(50) : "Mijn diagnose"}
          modelText={getValue ? getValue(146) : ""}
        />
      </div>
      {/* <div className="container relative py-16 mt-0 w-2/3">
        <SectionHomeTestimonials getValue={[]} />
      </div> */}
    </div>
  );
}

export default withRouter(Benefits);
