import { GetContentByService } from "../../services/homepage";
import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Markup } from "interweave";
import BookAnAuditModal from "shared/Modal/BookAnAuditForm";
import Modal from "shared/Modal/index";
import cookie from "react-cookies";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import jwt_decode from "jwt-decode";

function Benefits() {
  const [sectionContent, setSectionContent]: any = useState([]);
  const [auditModal, setAuditModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [login, setLogin] = useState(false);
  useEffect(() => {
    let token = cookie.load("token");
    if (token) {
      let decoded = jwt_decode(token);
      if (decoded) {
        setLogin(true);
      }
    }
    let cityId: any = { id: 1 };
    GetContentByService(cityId)
      .then((response) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cookie?.load("token")]);

  function openModal(activeModal: any) {
    setIsOpenModal(true);
    setActiveModal(activeModal);
  }
  function closeModal() {
    setIsOpenModal(false);
    setActiveModal("");
  }
  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };

  return (
    <div className="">
      {auditModal && (
        <BookAnAuditModal setModal={setAuditModal} modal={auditModal} />
      )}
      <Modal
        activeModal={activeModal}
        closeModal={closeModal}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        setLogin={setLogin}
      />
      <div className="flex justify-center py-4">
        <ButtonPrimary
          onClick={() => {
            login ? setAuditModal(true) : openModal("login");
          }}
          className="rounded bg-primary-500 flex items-center"
        >
          <span className="px-2">
            {getValue ? getValue(79) : "Book an Audit"}
          </span>
        </ButtonPrimary>
      </div>
    </div>
  );
}

export default withRouter(Benefits);
