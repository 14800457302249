import { Fragment, useState, useEffect } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Button, Select, Tooltip, Divider } from "antd";
import { UpdateBuildingRefinementCall2ByService } from "services/ResultPage";
import { GetContentByService } from "../../services/homepage";
import informationImg from "images/information.png";
import Avatar from "shared/Avatar/Avatar";
import {
  ventillationValuesHandler,
  roofValuesHandler,
  heatingValuesToNumbersHandler,
  heatingNumbersToValuesHandler,
  wallValuesHandler,
  windowTypeHandler,
  mechanicalValuesHandler,
  updateRenovations,
} from "./constant";
import { renovationPostProcessingForPackB } from "../../containers/Results/constants";
import { Markup } from "interweave";

const { Option } = Select;
const colors = [
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
];
const Modal = (props: any) => {
  let {
    isOpenModal,
    closeModal,
    callTwo,
    userBuildingTemPId,
    setActiveModal,
    allRenovationRecord,
    setLoader,
    refinementCall2Record,
    setRefinementCall2Record,
    setHouseCallRecordUpdated,
    houseCallRecordUpdated,
    userData,
    // updateRenovations,
    setAllUserSelectedData,
    setEpcA,
    renovationsInvestmentPrice,
    defaultRenovations,
    setRenovations,
    userModalRenovations,
    houseDetails,
    setUserExistRenovations,
    openModal,
    setSelectedUserCombination,
    setSelectedPack,
    setFutureEpc,
    setCustomStars,
    setIsCallWaiting,
    setDownloadedRecord,
  } = props;
  const [sectionContent, setSectionContent]: any = useState([]);

  const [buildingValues, setBuildingValues] = useState({
    window_type:
      // after refinement
      refinementCall2Record && refinementCall2Record.length !== 0
        ? refinementCall2Record.window_type === "hr_double_before_2000"
          ? "ordinary_double"
          : refinementCall2Record.window_type
        : // default values
        allRenovationRecord.window_type !== null
        ? allRenovationRecord.window_type === "hr_double_before_2000"
          ? "ordinary_double"
          : allRenovationRecord.window_type
        : "",
    wall_insulation_year:
      refinementCall2Record && refinementCall2Record.length !== 0
        ? refinementCall2Record.wall.u_value === 1.6 &&
          refinementCall2Record.wall.has_cavity === true
          ? 1
          : refinementCall2Record.wall.u_value === 0.65 &&
            refinementCall2Record.wall.has_cavity === true
          ? 2
          : refinementCall2Record.wall.u_value === 0.55 &&
            refinementCall2Record.wall.has_cavity === true
          ? 3
          : refinementCall2Record.wall.u_value === 0.3 &&
            refinementCall2Record.wall.has_cavity === true
          ? 4
          : refinementCall2Record.wall.u_value === 2.04 &&
            refinementCall2Record.wall.has_cavity === null
          ? 5
          : refinementCall2Record.wall.u_value === 0.4 &&
            refinementCall2Record.wall.has_cavity === false
          ? 6
          : refinementCall2Record.wall.u_value === 0.3 &&
            refinementCall2Record.wall.has_cavity === false
          ? 7
          : refinementCall2Record?.wall?.u_value === 0.2 &&
            refinementCall2Record?.wall?.has_cavity === false
          ? 8
          : ""
        : allRenovationRecord?.u_value_wall
        ? allRenovationRecord?.u_value_wall < 0.6 &&
          allRenovationRecord?.has_cavity === 1
          ? 4
          : allRenovationRecord?.u_value_wall < 0.65 &&
            allRenovationRecord?.u_value_wall >= 0.6 &&
            allRenovationRecord?.has_cavity === 1
          ? 3
          : allRenovationRecord?.u_value_wall < 0.7 &&
            allRenovationRecord?.u_value_wall >= 0.65 &&
            allRenovationRecord?.has_cavity === 1
          ? 2
          : allRenovationRecord?.u_value_wall < 1.65 &&
            allRenovationRecord?.u_value_wall >= 0.7 &&
            allRenovationRecord?.has_cavity === 1
          ? 1
          : allRenovationRecord?.u_value_wall >= 1.65 &&
            allRenovationRecord?.has_cavity === 1
          ? ""
          : allRenovationRecord?.u_value_wall < 0.25 &&
            allRenovationRecord?.has_cavity === 0
          ? 8
          : allRenovationRecord?.u_value_wall < 0.35 &&
            allRenovationRecord?.u_value_wall >= 0.25 &&
            allRenovationRecord?.has_cavity === 0
          ? 7
          : allRenovationRecord?.u_value_wall < 0.45 &&
            allRenovationRecord?.u_value_wall >= 0.35 &&
            allRenovationRecord?.has_cavity === 0
          ? 6
          : allRenovationRecord?.u_value_wall < 2.45 &&
            allRenovationRecord?.u_value_wall >= 0.45 &&
            allRenovationRecord?.has_cavity === 0
          ? 5
          : allRenovationRecord?.u_value_wall >= 2.45 &&
            allRenovationRecord?.has_cavity === 0
          ? ""
          : ""
        : //  allRenovationRecord.u_value_wall > 0.6 &&
          //   allRenovationRecord.has_cavity === 1
          //   ? 1
          //   : allRenovationRecord.u_value_wall === 0.6 &&
          //     allRenovationRecord.has_cavity === 1
          //   ? 2
          //   : allRenovationRecord.u_value_wall === 0.55 &&
          //     allRenovationRecord.has_cavity === 1
          //   ? 3
          //   : allRenovationRecord.u_value_wall === 0.2 &&
          //     allRenovationRecord.has_cavity === 1
          //   ? 4
          //   : allRenovationRecord.u_value_wall > 0.6 &&
          //     allRenovationRecord.has_cavity === 0
          //   ? 5
          //   : allRenovationRecord.u_value_wall === 0.4 &&
          //     allRenovationRecord.has_cavity === 0
          //   ? 6
          //   : allRenovationRecord.u_value_wall === 0.3 &&
          //     allRenovationRecord.has_cavity === 0
          //   ? 7
          //   : allRenovationRecord?.u_value_wall === 0.2 &&
          //     allRenovationRecord?.has_cavity === 0
          //   ? 8
          //   : ""
          "",
    roof_insulation_year:
      // if user record exist in DB
      refinementCall2Record && refinementCall2Record.length !== 0
        ? refinementCall2Record.roof.u_value > 0.8
          ? 1
          : refinementCall2Record.roof.u_value > 0.4 ||
            refinementCall2Record.roof.u_value === 0.8
          ? 2
          : refinementCall2Record.roof.u_value > 0.2 ||
            refinementCall2Record.roof.u_value === 0.4
          ? 3
          : refinementCall2Record.roof.u_value > 0.15 ||
            refinementCall2Record.roof.u_value === 0.2
          ? 4
          : 10
        : // if record not exist in DB
        allRenovationRecord && allRenovationRecord.u_value_roof
        ? allRenovationRecord.u_value_roof < 0.25
          ? 7
          : allRenovationRecord.u_value_roof < 0.45 &&
            allRenovationRecord.u_value_roof >= 0.25
          ? 6
          : allRenovationRecord.u_value_roof < 0.85 &&
            allRenovationRecord.u_value_roof >= 0.45
          ? 5
          : 1
        : "",
    //  allRenovationRecord?.u_value_roof > 0.8 || allRenovationRecord?.roof_insulation_year == null
    //   ? 1
    //   : allRenovationRecord.u_value_roof > 0.4 ||
    //     allRenovationRecord.u_value_roof === 0.8
    //   ? 2
    //   : allRenovationRecord.u_value_roof > 0.2 ||
    //     allRenovationRecord.u_value_roof === 0.4
    //   ? 3
    //   : allRenovationRecord?.u_value_roof > 0.15 ||
    //     allRenovationRecord?.u_value_roof === 0.2
    //   ? 4
    //   : 10
    // : "",
    heating_type:
      refinementCall2Record && refinementCall2Record.length !== 0
        ? heatingValuesToNumbersHandler(
            refinementCall2Record.heating.heating_type,
            refinementCall2Record.heating.subtype,
            refinementCall2Record.heating.fuel
          )
        : // default if user record not exits in DB
        allRenovationRecord && allRenovationRecord.heating_type !== null
        ? heatingValuesToNumbersHandler(
            allRenovationRecord.heating_type,
            allRenovationRecord.heating_sub_type,
            allRenovationRecord.heating_fuel
          )
        : "",
    mechanical_system_type:
      refinementCall2Record && refinementCall2Record.length !== 0
        ? refinementCall2Record.mechanical_system_type === ""
          ? "natural"
          : refinementCall2Record.mechanical_system_type
        : allRenovationRecord &&
          allRenovationRecord.mechanical_system_type !== null
        ? allRenovationRecord.mechanical_system_type === ""
          ? "natural"
          : allRenovationRecord.mechanical_system_type
        : "",
    photovoltaics_attributes: "",
    solar:
      refinementCall2Record &&
      refinementCall2Record?.solar?.number_panels !== undefined &&
      refinementCall2Record?.solar?.number_panels
        ? "YES"
        : "",
    number_panels:
      refinementCall2Record &&
      refinementCall2Record?.solar?.number_panels !== undefined
        ? refinementCall2Record?.solar?.number_panels
        : "",
  });

  useEffect(() => {
    let cityId: any = { id: 167 };
    GetContentByService(cityId)
      .then((response) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };

  const submitRenovationData = () => {
    setIsCallWaiting(true);
    let refinementForm: any = {
      building_id: allRenovationRecord?.building_id,
      refinement_form: {
        window_type: buildingValues?.window_type,
        window_u_value: windowTypeHandler(buildingValues?.window_type),
        wall: wallValuesHandler(buildingValues?.wall_insulation_year),
        roof: roofValuesHandler(buildingValues?.roof_insulation_year),
        heating: heatingNumbersToValuesHandler(buildingValues?.heating_type),
        mechanical_system_type: ventillationValuesHandler(
          buildingValues?.mechanical_system_type
        ),
        ventilation_type: mechanicalValuesHandler(
          buildingValues?.mechanical_system_type
        ),
        solar:
          buildingValues?.solar == "YES"
            ? { number_panels: buildingValues?.number_panels }
            : {},
      },
    };

    if (userData?.id != undefined) {
      refinementForm.userId = userData.id;
    } else {
      const temp: any = localStorage.getItem("user_temporary_id");
      const local_storage: any = JSON.parse(temp);
      refinementForm.user_temporary_id =
        local_storage[allRenovationRecord?.building_id]["user_temporary_id"];
    }

    UpdateBuildingRefinementCall2ByService(refinementForm)
      .then((response: any) => {
        setIsCallWaiting(false);
        setActiveModal("call2&3");
        // to update the state to get current data from response and to show inside form  using this state condition
        let duplicate = { ...houseCallRecordUpdated };
        duplicate.rightSide = "updated";
        setHouseCallRecordUpdated(duplicate);
        setRefinementCall2Record(response?.data?.data?.renovation_refinements);
        //
        // userRenovations
        let data = {
          response: {
            data: [response.data.data],
          },
        };
        let downloadResponse = {
          call_two_input_request: response?.data?.call_two_input_request,
          callTwoResponse: response?.data?.callTwoResponse,
        };
        setDownloadedRecord(downloadResponse);
        // var zip = new JSZip();
        // zip.file("Call2-Response.txt", JSON.stringify(downloadResponse));
        // zip.generateAsync({ type: "blob" }).then(function (content) {
        //   saveAs(content, "call-2");
        // });
        let callzeroHouseRecord = {
          data: [allRenovationRecord],
        };
        // updateRenovations(data?.response, userModalRenovations);
        updateRenovations(
          data?.response,
          userModalRenovations,
          callzeroHouseRecord,
          setAllUserSelectedData,
          setEpcA,
          renovationPostProcessingForPackB,
          renovationsInvestmentPrice,
          defaultRenovations,
          setRenovations,
          setUserExistRenovations,
          setSelectedUserCombination,
          response?.data?.data?.renovation_refinements,
          setFutureEpc,
          setCustomStars,
          setSelectedPack
        );
      })
      .catch((err: any) => {
        setIsCallWaiting(false);
      });
    closeModal();
  };
  return (
    <div>
      <Transition appear show={isOpenModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center result_common_model_container">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full">
                <div className="inline-flex flex-col w-full lg:w-1/2 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-sm m-0 text-left text-gray-500"
                      id="headlessui-dialog-title-70"
                    >
                      {getValue(83)}
                    </h3>
                    <span className="absolute right-3 top-3">
                      <ButtonClose onClick={closeModal} />
                    </span>
                  </div>
                  <div className="py-8">
                    <div className="px-8 w-full">
                      <span className="block my-2">{getValue(84)}</span>
                    </div>
                    {/* first Name  and Last Name*/}
                    <div className="space-y-6 py-2">
                      <div className="flex flex-col lg:flex-row">
                        <div className="px-8 lg:w-1/2">
                          <div className="flex items-center">
                            {" "}
                            <span className="block my-2 pr-2 text-black font-semibold">
                              {getValue(85)}
                            </span>
                            <Tooltip
                              title={getValue(242)}
                              color={"#000"}
                              key={1}
                            >
                              <Button className="tool_tip_button">
                                <Avatar
                                  imgUrl={informationImg}
                                  sizeClass="h-4 w-4"
                                  radius="rounded-full"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                          <Select
                            className="w-full select_option_container"
                            placeholder=""
                            value={buildingValues?.window_type}
                            onChange={(e: any) => {
                              let duplicate = { ...buildingValues };
                              duplicate.window_type = e;
                              setBuildingValues(duplicate);
                            }}
                          >
                            <Option value="">{getValue(86)}</Option>
                            <Option value="single">{getValue(87)}</Option>
                            <Option value="ordinary_double">
                              {getValue(88)} ({"<2000"})
                            </Option>
                            <Option value="hr_double_after_2000">
                              {getValue(89)} ({">2000"})
                            </Option>
                            <Option value="triple">{getValue(90)}</Option>
                          </Select>
                        </div>
                        <div className="px-8 lg:w-1/2">
                          <div className="flex items-center">
                            <span className="block my-2 pr-2 text-black font-semibold">
                              {getValue(91)}
                            </span>
                            <Tooltip
                              title={getValue(243)}
                              color={"#000"}
                              key={1}
                            >
                              <Button className="tool_tip_button">
                                <Avatar
                                  imgUrl={informationImg}
                                  sizeClass="h-4 w-4"
                                  radius="rounded-full"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                          <Select
                            className="w-full"
                            placeholder=""
                            value={buildingValues?.wall_insulation_year}
                            onChange={(e: any) => {
                              let duplicate = { ...buildingValues };
                              duplicate.wall_insulation_year = e;
                              setBuildingValues(duplicate);
                            }}
                            listHeight={200}
                          >
                            <Option value="">{getValue(92)}</Option>
                            {/* null */}
                            <Option value={1}> {getValue(93)}</Option>
                            {/* has cavity true */}
                            <Option value={2}>{getValue(94)}</Option>
                            {/* has cavity true */}
                            <Option value={3}>{getValue(95)}</Option>
                            {/* has cavity true */}
                            <Option value={4}>{getValue(96)}</Option>
                            {/* null */}
                            <Option value={5}>{getValue(97)}</Option>
                            {/* has cavity false */}
                            <Option value={6}>{getValue(98)}</Option>
                            {/* has cavity false */}
                            <Option value={7}>{getValue(99)}</Option>
                            {/* has cavity true */}
                            <Option value={8}>{getValue(100)}</Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                    {/* email and phone number*/}
                    <div className="space-y-6 py-2">
                      <div className="flex flex-col lg:flex-row">
                        <div className="px-8 lg:w-1/2">
                          <div className="flex items-center">
                            {" "}
                            <span className="block my-2 pr-2 text-black font-semibold">
                              {getValue(101)}
                            </span>
                            <Tooltip
                              title={getValue(244)}
                              color={"#000"}
                              key={1}
                            >
                              <Button className="tool_tip_button">
                                <Avatar
                                  imgUrl={informationImg}
                                  sizeClass="h-4 w-4"
                                  radius="rounded-full"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                          <Select
                            className="w-full"
                            placeholder=""
                            value={buildingValues?.roof_insulation_year}
                            onChange={(e: any) => {
                              let duplicate = { ...buildingValues };
                              duplicate.roof_insulation_year = e;
                              setBuildingValues(duplicate);
                            }}
                            listHeight={200}
                          >
                            {/* roof_type:"" 0.8 and 0.4 */}
                            {/* <Option value="">{getValue(102)}</Option> */}
                            {/* roof_type:"" */}
                            <Option value={1}>{getValue(103)}</Option>
                            <Option value={2}>{getValue(104)}</Option>
                            <Option value={3}>{getValue(105)}</Option>
                            {/* roof_type:"attic" 0.2 */}
                            <Option value={4}>{getValue(106)}</Option>
                            <Option value={5}>{getValue(107)}</Option>
                            <Option value={6}>{getValue(108)}</Option>
                            {/* roof_type:"interior" 0.2 */}
                            <Option value={7}>{getValue(109)}</Option>
                            <Option value={8}>{getValue(110)}</Option>
                            {/* roof_type:"exterior" 0.2 */}
                            <Option value={9}>{getValue(111)}</Option>
                            {/* roof_type:"exterior" 0.2 */}
                            <Option value={10}>{getValue(112)}</Option>
                          </Select>
                        </div>
                        <div className="px-8 lg:w-1/2">
                          <div className="flex items-center">
                            {" "}
                            <span className="block my-2 pr-2 text-black font-semibold">
                              {getValue(113)}
                            </span>
                            <Tooltip
                              title={getValue(245)}
                              color={"#000"}
                              key={1}
                            >
                              <Button className="tool_tip_button">
                                <Avatar
                                  imgUrl={informationImg}
                                  sizeClass="h-4 w-4"
                                  radius="rounded-full"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                          <Select
                            className="w-full"
                            placeholder=""
                            value={buildingValues?.heating_type}
                            onChange={(e: any) => {
                              let duplicate = { ...buildingValues };
                              duplicate.heating_type = e;
                              setBuildingValues(duplicate);
                            }}
                          >
                            <Option value="">{getValue(114)}</Option>
                            <Option value={1}>{getValue(115)}</Option>
                            <Option value={2}>{getValue(116)}</Option>
                            <Option value={3}>{getValue(117)}</Option>
                            <Option value={4}>{getValue(118)}</Option>
                            <Option value={5}>{getValue(119)}</Option>
                            <Option value={6}>{getValue(120)}</Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                    {/* password and confirm password*/}
                    <div className="space-y-6 py-2">
                      <div className="flex flex-col lg:flex-row">
                        <div className="px-8 lg:w-1/2">
                          <div className="w-full ">
                            <div className="flex items-center">
                              {" "}
                              <span className="block my-2 pr-2 text-black font-semibold">
                                {getValue(121)}
                              </span>
                              <Tooltip
                                title={getValue(246)}
                                color={"#000"}
                                key={1}
                              >
                                <Button className="tool_tip_button">
                                  <Avatar
                                    imgUrl={informationImg}
                                    sizeClass="h-4 w-4"
                                    radius="rounded-full"
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                            <Select
                              className="w-full common_scroll_input"
                              placeholder=""
                              value={buildingValues?.mechanical_system_type}
                              onChange={(e: any) => {
                                let duplicate = { ...buildingValues };
                                duplicate.mechanical_system_type = e;
                                setBuildingValues(duplicate);
                              }}
                              listHeight={120}
                            >
                              {/* <Option value="">{getValue(122)}</Option> */}
                              <Option value="natural">{getValue(123)}</Option>
                              <Option value="system_c">{getValue(124)}</Option>
                              <Option value="system_cplus">
                                {getValue(125)}
                              </Option>
                              <Option value="system_d">{getValue(126)}</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="px-8 lg:w-1/2">
                          <div className="flex items-center">
                            {" "}
                            <span className="block my-2 pr-2 text-black font-semibold">
                              {getValue(127)}
                            </span>
                            <Tooltip
                              title={getValue(247)}
                              color={"#000"}
                              key={1}
                            >
                              <Button className="tool_tip_button">
                                <Avatar
                                  imgUrl={informationImg}
                                  sizeClass="h-4 w-4"
                                  radius="rounded-full"
                                />
                              </Button>
                            </Tooltip>
                          </div>
                          <Select
                            className="w-full"
                            placeholder=""
                            value={
                              buildingValues?.solar
                                ? buildingValues?.solar
                                : "NO"
                            }
                            onChange={(e: any) => {
                              let duplicate = { ...buildingValues };
                              duplicate.solar = e;
                              setBuildingValues(duplicate);
                            }}
                            // solar={number_panels:""}
                          >
                            {/* <Option value="">{getValue(128)}</Option> */}
                            <Option value="NO">{getValue(129)}</Option>
                            <Option value="YES">{getValue(130)}</Option>
                          </Select>
                          {buildingValues?.solar == "YES" && (
                            <div className="">
                              <span className="block my-2 text-black font-semibold">
                                {getValue(131)}
                              </span>
                              <input
                                type="number"
                                // placeholder={getValue(132)}
                                className="w-full"
                                max={100}
                                min={1}
                                value={buildingValues?.number_panels}
                                onChange={(e: any) => {
                                  let duplicate = { ...buildingValues };
                                  duplicate.number_panels = parseInt(e.target.value) < 0 ? (-1 * parseInt(e.target.value)) : parseInt(e.target.value);
                                  setBuildingValues(duplicate);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 py-4 flex justify-between items-center border-b border-neutral-100 bg-neutral-100 dark:border-neutral-800 text-center">
                    <div className="flex items-center">
                      <Button
                        className="rounded-4xl mx-2"
                        size={"large"}
                        onClick={() => closeModal()}
                      >
                        {getValue(290)}
                      </Button>
                    </div>
                    <div>
                      <p className="text-red-500 m-0">{getValue(281)}</p>
                    </div>
                    <Button
                      type="primary"
                      size={"large"}
                      onClick={submitRenovationData}
                    >
                      {getValue(133)}
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
export default Modal;
