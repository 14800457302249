import {
  SetUserProfileStatusByService,
  GetSingleUserBuildingStatusByService,
  // GetUserBuildingStatusByService,
} from "services/ResultPage";

export const deleteHandler = (array1: any, array2: any) => {
  return array1?.filter(
    (content: any) =>
      !array2?.some((key: any) => content.renovation === key.renovation)
  );
};

export const defaultRenovations = (response: any, allRenovations: any) => {
  // glazing
  if (response?.window_type === "triple") {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "windowsDoubleAndDoors"
    );
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "glazing"
    );
  }
  // Roof
  if (response?.u_value_roof < 0.25) {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "roofIn"
    );
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "roofExt"
    );
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "roofAttic"
    );
  }
  // heat pump
  if (response?.heating_type === "heat_pump") {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "heatPump"
    );
  }
  // Boiler
  if (
    response?.heating_type === "boiler" &&
    response?.subtype === "condensing"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "condGas"
    );
  }
  // cavity wall
  if (
    (response?.u_value_wall < 0.35 &&
      response?.has_cavity &&
      response?.construction_year > 1950) ||
    (response?.u_value_wall < 0.25 && !response?.has_cavity)
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "wallCav"
    );
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "wallExt"
    );
  }
  // Ventillation
  if (
    response?.mechanical_system_type === "system_cplus" ||
    response?.mechanical_system_type === "system_d"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "ventD"
    );
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "ventC+"
    );
  }
  // Attic Floor
  // if (response?.attic_storage === 1) {
  //   allRenovations = allRenovations?.filter(
  //     (item: any) => item?.renovation !== "roofAttic"
  //   );
  // }
  return allRenovations;
};

// python logic after call 2 and 3
export const pythonLogic1AfterCall2Call3 = (
  response: any,
  allRenovations: any,
  callZeroResponse: any
) => {
  // glazing
  if (response?.window_type === "triple" || 
  response?.window_type === "hr_double_after_2000") {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("windowsDoubleAndDoors")
    );
  }
  // Roof
  if (response?.roof?.u_value < 0.25) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("roof")
    );
  }
  // heat pump
  if (response?.heating?.heating_type === "heat_pump") {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("heatPump")
    );
    
  }
  // Boiler
  if (
    response?.heating?.heating_type === "boiler" &&
    response?.heating?.subtype === "condensing"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("condGas")
    );
   
  }
  // cavity wall
  if (
    (response?.wall?.u_value < 0.6 &&
      response?.wall?.has_cavity 
      // &&
      // callZeroResponse?.construction_year > 1950
      ) ||
    (response?.wall?.u_value < 0.25 && !response?.wall?.has_cavity)
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("wall")
    );
  }
  // Ventillation
  if (
    response?.mechanical_system_type === "system_cplus" ||
    response?.mechanical_system_type === "system_d"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("vent")
    );
  }
  return allRenovations;
};

export const pythonLogicMinus1AfterCall2Call3 = (
  response: any,
  allRenovations: any,
  callZeroResponse: any
) => {
  // glazing
  if (response?.window_type === "single") {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("windowsDoubleAndDoors")
    );
  }
  // Roof
  if (response?.roof?.u_value > 0.85) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("roof")
    );
  }
  // Boiler
  if (
    response?.heating?.heating_type === "boiler" &&
    response?.heating?.subtype === "standard"
  ) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("heatPump")
    );
  }
  // wall
  if (
    (response?.wall?.u_value > 0.65 &&
      callZeroResponse?.construction_year > 1950 &&
      response?.wall?.has_cavity) ||
    (response?.wall?.u_value > 0.45 && !response.wall?.has_cavity)
  ) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("wall")
    );
  }
  // Ventillation
  if (response?.mechanical_system_type === "") {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("vent")
    );
  }
  return allRenovations;
};

// python logic for call 0
// for all renovations for which configuration is 1
export const pythonLogic1 = (response: any, allRenovations: any) => {
  // glazing
  if (response?.window_type === "triple" || 
  response?.window_type === "hr_double_after_2000" ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("windowsDoubleAndDoors")
    );
  }
  // Roof
  if (response?.u_value_roof < 0.25) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("roof")
    );
  }
  // heat pump
  if (response?.heating_type === "heat_pump") {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("heatPump")
    );
  
  }
  // Boiler  
  if (
    response?.heating_type === "boiler" &&
    response?.heating_sub_type === "condensing"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("condGas")
    );
    // allRenovations = allRenovations?.filter(
    //   (item: any) => !item?.renovation.includes("heatPump")
    // );
  }
  // cavity wall
  if (
    (response?.u_value_wall < 0.6 &&
      response?.has_cavity 
      // &&
      // response?.construction_year > 1950
      ) ||
    (response?.u_value_wall < 0.25 && !response?.has_cavity)
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("wall")
    );
  }
  // Ventillation
  if (
    response?.mechanical_system_type === "system_cplus" ||
    response?.mechanical_system_type === "system_d"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("vent")
    );
  }
  return allRenovations;
};
// for all renovations for which configuration is -1
export const pythonLogicMinus1 = (response: any, allRenovations: any) => {
  // glazing
  if (response?.window_type === "single") {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("windowsDoubleAndDoors")
    );
  }
  // Roof
  if (response?.u_value_roof > 0.85) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("roof")
    );
  }
  // Boiler
  if (
    response?.heating_type === "boiler" &&
    response?.heating_sub_type === "standard"
  ) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("heatPump")
    );
  }
  // wall
  if (
    (response?.u_value_wall > 0.65 &&
      response?.construction_year > 1950 &&
      response?.has_cavity) ||
    (response?.u_value_wall > 0.45 && !response.has_cavity)
  ) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("wall")
    );
  }
  // Ventillation
  if (response?.mechanical_system_type === "") {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("vent")
    );
  }
  return allRenovations;
};

// method to check user status if status not exist to store status
export const GetUserStatus = (
  userRecord: any,
  response?: any,
  houseRenovations?: any,
  payload?: any,
  setUserStatus?: any,
  baseOffer?: any
) => {
  if (userRecord?.id) {
    GetSingleUserBuildingStatusByService(
      userRecord?.id,
      houseRenovations.data[0].id
    )
      .then((statusResponse: any) => {
        if (
          userRecord &&
          (statusResponse?.data === null ||
            statusResponse?.data?.length === 0 ||
            statusResponse?.data?.status === null ||
            statusResponse?.data?.status === 0)
        ) {
          // api call to store status for new user if they don't have it.
          SetUserProfileStatusByService(payload)
            .then((profileResponse: any) => {
              setUserStatus(profileResponse.data.data);
            })
            .catch((error: any) => {});
        }
        setUserStatus(statusResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const currentComfort = (response: any, total: any) => {
  // glazing , ordinary_double ,single
  if (response?.window_type === "single") {
    total = total + 0;
  } else if (
    response?.window_type === "ordinary_double" ||
    response?.window_type === "hr_double_before_2000"
  ) {
    total = total + 2;
  } else if (
    response?.window_type === "triple" ||
    response?.window_type === "hr_double_after_2000"
  ) {
    total = total + 4;
  }
  // cavity wall
  let cavity = response?.wall?.u_value;
  if (cavity !== "" && cavity !== undefined) {
    if (cavity === "") {
      total = total + 0;
    } else if (
      cavity === 0.65 ||
      cavity === 0.55 ||
      cavity === 0.4 ||
      cavity === 0.3
    ) {
      total = total + 1;
    } else if (cavity === 0.2) {
      total = total + 2;
    }
  } else {
    if (
      response?.u_value_wall !== null &&
      parseInt(response?.has_cavity) === 1 &&
      parseInt(response?.u_value_wall) < 0.6
    ) {
      total = total + 2;
    }
    // else if cavity true ** value >=0.6 && < 1.65 {total =1
    else if (
      response?.u_value_wall !== null &&
      parseInt(response?.has_cavity) === 1 &&
      parseInt(response?.u_value_wall) >= 0.6 &&
      parseInt(response?.u_value_wall) < 0.7
    ) {
      total = total + 1;
    }
    // else if cavity true ** value > 1.65 {total =0
    else if (
      response?.u_value_wall !== null &&
      parseInt(response?.has_cavity) === 1 &&
      parseInt(response?.u_value_wall) >= 0.7
    ) {
      total = total + 0;
    } else if (
      response?.u_value_wall !== null &&
      parseInt(response?.has_cavity) === 1 &&
      parseInt(response?.u_value_wall) < 0.6
    ) {
      total = total + 2;
    }
    // else if cavity true ** value >=0.6 && < 1.65 {total =1
    else if (
      response?.u_value_wall !== null &&
      parseInt(response?.has_cavity) === 1 &&
      parseInt(response?.u_value_wall) >= 0.6 &&
      parseInt(response?.u_value_wall) < 0.7
    ) {
      total = total + 1;
    }
    // else if cavity true ** value > 1.65 {total =0
    else if (
      response?.u_value_wall !== null &&
      parseInt(response?.has_cavity) === 1 &&
      parseInt(response?.u_value_wall) >= 0.7
    ) {
      total = total + 0;
    }
    // cavity false conditions starts from here
    // if(cavity false ** value < 0.6 {total = 2
    else if (
      response?.u_value_wall !== null &&
      parseInt(response?.has_cavity) === 0 &&
      parseInt(response?.u_value_wall) < 0.25
    ) {
      total = total + 2;
    }
    // else if cavity false ** value >=0.6 && < 1.65 {total =1
    else if (
      response?.u_value_wall !== null &&
      parseInt(response?.has_cavity) === 0 &&
      parseInt(response?.u_value_wall) >= 0.25 &&
      parseInt(response?.u_value_wall) < 0.45
    ) {
      total = total + 1;
    }
    // else if cavity false ** value > 1.65 {total =0
    else if (
      response?.u_value_wall !== null &&
      parseInt(response?.has_cavity) === 0 &&
      parseInt(response?.u_value_wall) >= 0.45
    ) {
      total = total + 0;
    }
  }
  // roof
  let roofValue = response?.roof;

  if (roofValue !== "" && roofValue !== undefined) {
    if (roofValue === "") {
      total = total + 0;
    } else if (
      roofValue?.u_value === 0.8 ||
      (roofValue?.u_value === 0.4 && roofValue?.roof_type === "exterior")
    ) {
      total = total + 1;
    } else if (
      roofValue?.u_value === 0.4 ||
      (roofValue?.u_value === 0.2 && roofValue?.roof_type === "exterior")
    ) {
      total = total + 2;
    } else if (
      roofValue?.u_value === 0.2 ||
      (roofValue?.u_value === 0.15 && roofValue?.roof_type === "exterior")
    ) {
      total = total + 3;
    }
  } else {
    if (parseInt(response?.u_value_roof) < 0.25) {
      total = total + 3;
    }
    //
    else if (
      parseInt(response?.u_value_roof) >= 0.25 &&
      parseInt(response?.u_value_roof) < 0.45
    ) {
      total = total + 2;
    }
    //
    else if (
      parseInt(response?.u_value_roof) >= 0.45 &&
      parseInt(response?.u_value_roof) < 0.85
    ) {
      total = total + 1;
    } else if (parseInt(response?.u_value_roof) >= 0.85) {
      total = total + 0;
    }
  }
  // heat pump
  // Boiler
  let heating = response?.heating;
  if (heating !== "" && heating !== undefined) {
    if (
      heating?.heating_type === "boiler" ||
      heating?.subtype === "condensing" ||
      heating?.heating_type === "heat_pump"
    ) {
      total = total + 1;
    }
  } else {
    if (
      response?.heating_type === "boiler" ||
      response?.subtype === "condensing" ||
      response?.heating_type === "heat_pump"
    ) {
      total = total + 1;
    }
  }
  // Ventillation
  if (response?.mechanical_system_type === null) {
    total = total + 0;
  } else if (response?.mechanical_system_type === "system_c") {
    total = total + 1;
  } else if (response?.mechanical_system_type === "system_cplus") {
    total = total + 2;
  } else if (response?.mechanical_system_type === "system_d") {
    total = total + 4;
  }
  // console.log("total value after all logcs - value , after ",total , (total / 14) * 100)
  return (total / 14) * 100;
};
