import React, { FC, useEffect, useState } from "react";
import { Button, Checkbox, Radio, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { updateUserRecordByService } from "services/profile";
import cookie from "react-cookies";

export interface SectionHeroProps {
  className?: string;
  getValue?: any;
  userProfile?: any
  setUserProfile?: any
}

const SectionDiagnosis: FC<SectionHeroProps> = ({
  className,
  getValue,
  userProfile,
  setUserProfile
}) => {
  const [userName, setUserName]: any = useState();
  const [showCheckbox, setShowCheckbox] = useState(false);
  useEffect(() => {
    let duplicateData = { ...userProfile };
    let lstName: any = '';
    duplicateData?.userName?.split(" ").filter((ele:any, index:any)=> {
      if(index != 0){
        lstName = lstName+' '+ele;
      }
    })
    let names: any = {
      firstName: duplicateData?.userName?.split(" ")[0],
      lastName: lstName,
    };
    setUserName(names);
  }, [userProfile]);
  const [loader, setLoader] = useState(false);
  const [updateUserRecord, setUpdateUserRecord]: any = useState(false);
  const updateHandler = (e: any) => {
    e.preventDefault();
    setLoader(true);
    let payload = {
      userName: userName?.firstName + " " + userName?.lastName,
      phone: userProfile?.phone,
      contact_by_email: userProfile?.contact_by_email,
      contact_by_phone: userProfile?.contact_by_phone,
    };
    updateUserRecordByService(userProfile?.id, payload).then(
      (response: any) => {
        setUpdateUserRecord(false);
        setLoader(false);
        setShowCheckbox(true);
        cookie.save("token", response.data.token, { maxAge: 15000, path: "/" });
      }
    );
  };
  return (
    <div
      className={`listingSection__wrap profile-section-container h-auto section_width mx-2 my-0 p-0 ${className}`}
    >
      <div className="flex justify-between px-8 pt-4 pb-2 bg-neutral-100 border-t-r-10 border-t-l-10">
        <div className="flex flex-col lg:flex-row items-center w-full">
          <div className="w-full lg:w-1/2 flex">
            <h4 className="fs_22 text-neutral-500">{getValue(3)}</h4>
          </div>
        </div>
      </div>
      {!updateUserRecord ? (
        <div className="px-8 pb-8 mt-0 pt-4 flex flex-col fs_16">
          <span className="text-neutral-500 fs_16 py-2">
            {userName?.firstName} {userName?.lastName}
          </span>
          <span className="text-neutral-500 fs_16 py-2">
            {userProfile?.email}
          </span>
          <span className="text-neutral-500 fs_16 py-2">
            {userProfile?.phone}
          </span>
          <div className="flex py-2 justify-between">
            <div className="flex">
              <div className="pr-2 disabled_checkbox_container">
                <Checkbox
                  // checked={
                  //   loggedInUserDetail?.contact_by_email === 1 ? true : false
                  // }
                  checked
                  disabled
                >
                  <span className="text-neutral-500">{getValue(7)}</span>
                </Checkbox>
              </div>
              {/* {loggedInUserDetail?.contact_by_phone === 1 && ( */}
                {/* // {showCheckbox && loggedInUserDetail?.contact_by_phone === 1 && ( */}
                <div className="pr-2">
                  <Checkbox
                    checked={
                      userProfile?.contact_by_phone 
                    }
                  >
                    <span className="text-neutral-500">{getValue(8)}</span>
                  </Checkbox>
                </div>
              {/* // )} */}
            </div>
            <div className="button-container">
              <Button
                icon={<EditOutlined />}
                className="flex items-center py-2"
                onClick={() => setUpdateUserRecord(true)}
              >
                {getValue(9)}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <form
          className="px-8 pb-8 mt-0 pt-4 flex flex-col fs_16"
          onSubmit={(e: any) => updateHandler(e)}
        >
          <div className="py-2 flex">
            <div className="w-1/3">
              <label>{getValue(26)}</label>
              <Input
                className="rounded py-2"
                placeholder=""
                type="text"
                value={userName?.firstName}
                onChange={(e: any) => {
                  let duplicate: any = { ...userName };
                  duplicate.firstName = e.target.value;
                  setUserName(duplicate);
                }}
              />
            </div>
            <div className="mx-2 w-1/3">
            <label>{getValue(27)}</label>
              <Input
                className="rounded py-2"
                placeholder=""
                type="text"
                value={userName?.lastName}
                onChange={(e: any) => {
                  let duplicate: any = { ...userName };
                  duplicate.lastName = e.target.value;
                  setUserName(duplicate);
                }}
              />
            </div>
          </div>
          <div className="py-2 w-1/3">
          <label>{getValue(28)}</label>
            <Input
              disabled
              className=""
              placeholder=""
              type="email"
              value={userProfile?.email}
            />
          </div>
          <div className="py-2 w-1/3">
          <label>{getValue(29)}</label>
            <Input
              className=""
              placeholder=""
              type="number"
              value={userProfile?.phone}
              onChange={(e: any) => {
                let duplicate: any = { ...userProfile };
                duplicate.phone = e.target.value;
                setUserProfile(duplicate);
              }}
            />
          </div>
          <div className="flex py-2 justify-between">
            <div className="flex">
              <div className="pr-2 disabled_checkbox_container">
                <Checkbox checked disabled>
                  <span className="text-neutral-500">{getValue(7)}</span>
                </Checkbox>
              </div>
              <div className="pr-2">
                <Checkbox
                  checked={
                    userProfile?.contact_by_phone === 1 ? true : false
                  }
                  onChange={(e) => {
                    if (setUserProfile?.contact_by_phone === 0) {
                      let duplicateData = { ...userProfile };
                      duplicateData.contact_by_phone = 1;
                      setUserProfile(duplicateData);
                    } else {
                      let duplicateData = { ...userProfile };
                      duplicateData.contact_by_phone = 0;
                      setUserProfile(duplicateData);
                    }
                  }}
                >
                  <span className="text-neutral-500">{getValue(8)}</span>
                </Checkbox>
              </div>
            </div>
            <div className="primaryButton-container">
              <ButtonPrimary type="submit" disabled={loader}>
                {getValue(18)}
              </ButtonPrimary>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default SectionDiagnosis;
