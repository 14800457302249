import React, { useState, useEffect } from "react";
import { Input, AutoComplete } from "antd";
import { SelectProps } from "antd/es/select";
import { getAddressesByService } from "../../services/homepage";
import { withRouter } from "react-router";
import AddressModal from "shared/Modal/AddressesModal";

interface SearchedData {
  value: string;
  className?: string;
  label?: any;
  modelText?: any;
}

const AutoCompleteSearch = (props: any) => {
  const {
    history,
    className,
    getValue,
    bottomText,
    searchText,
    buttonText,
    modelText,
  } = props;
  const [addresses, setAddresses]: any = useState([]);
  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
  const [selectedAddress, setSelectedAddress]: any = useState();
  const [isSearch, setIsSearch]: any = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const data: any = [];

  /**
   * Sort addresses by house address
   * @param addresses list of house addresses.
   * @returns Sorted list
   */
  const sortedAddress = (addresses: any) => {
    return addresses?.sort((a: any, b: any) => {
      //  return a.street_number - b.street_number
      if (a.house_address < b.house_address) {
        return -1;
      } else if (a.house_address > b.house_address) {
        return 1;
      } else {
        // nothing to split them
        return 0;
      }
    });
  };

  useEffect(() => {
    let AllAddresses: any = [];
    getAddressesByService()
      .then((response: any) => {
        response?.data?.data?.map((addresses: any) => {
          AllAddresses.push({
            ...addresses,
            house_address: addresses?.street + " " + addresses?.street_number,
            // +
            // ", " +
            // addresses?.zipcode +
            // " " +
            // addresses?.city,
          });
        });
        if (AllAddresses) {
          setAddresses(sortedAddress(AllAddresses));
        }
      })
      .catch((error: any) => {
        console.log("res", error);
      });
  }, []);

  const searchResult = (query: string): void => {
    const filter = addresses
      ? addresses.filter((adresses: any) => {
          return (
            adresses.house_address
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1
          );
        })
      : [];

    if (filter && query !== "") {
      filter
        // .splice(0, 5)
        .map((address: any, idx: any) => {
          return data.push({
            value: address?.house_address,
            // value: address?.userdataurl + "?gmlid=GRB_OIDN_" + address?.GRB_OIDN_,
            label: address?.house_address,
            // label: (
            //   <div
            //     style={{
            //       display: "flex",
            //       justifyContent: "space-between",
            //     }}
            //   >
            //     <span>
            //       {address?.street +
            //         " " +
            //         address?.street_number +
            //         ", " +
            //         address?.zipcode +
            //         " " +
            //         address?.city}
            //     </span>
            //   </div>
            // ),
          });
        });
      setOptions(data);
    }
  };

  const showAllAddresses = () => {
    addresses.map((address: any, idx: any) => {
      return data.push({
        value: address?.house_address,
        // value: address?.userdataurl + "?gmlid=GRB_OIDN_" + address?.GRB_OIDN_,
        label: address?.house_address,
        // label: (
        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "space-between",
        //     }}
        //   >
        //     <span>
        //       {address?.street +
        //         " " +
        //         address?.street_number +
        //         ", " +
        //         address.zipcode +
        //         " " +
        //         address?.city}
        //     </span>
        //   </div>
        // ),
      });
    });
    setOptions(data);
  };

  const handleSearch = (value: string) => {
    if (!value) {
      setIsSearch(false);
      showAllAddresses();
    } else {
      setIsSearch(true);
      searchResult(value);
      let searchedRecord = addresses?.filter(
        (item: any) => item.house_address === value
      );
      setSelectedAddress(searchedRecord);
    }
  };
  const onSelect = (value: string, option: any) => {
    let searchedRecord = addresses?.filter(
      (item: any) => item.house_address === value
    );
    setSelectedAddress(searchedRecord);
  };
  const selectAddressHandler = () => {
    if (selectedAddress[0]?.building_id > 69101) {
      setIsOpenModal(true);
    } else if (selectedAddress?.length !== 0) {
      history.push(
        `/listing-renovate-detail/${
          selectedAddress[0]?.userdataurl?.split("/")[4] +
          "?gmlid=GRB_OIDN_" +
          selectedAddress[0]?.GRB_OIDN_
        }`
      );
    }
  };
  console.log("className", className);
  return (
    <>
      <AddressModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        modelText={modelText}
      />
      <div
        className={`ttnc-autocomplete-search relative flex items-center text-paragraph-base rounded-lg result_common_model_container login_model_container 
        ${className === "responsive_autoSearch" ? "responsive_autoSearch" : ""}
        `}
      >
        {/* responsive_class_for_mobile */}
        <div
          className={`mt-0.5 relative  ${
            className === "responsive_autoSearch"
              ? "responsive_autoSearch"
              : className
          }`}
        >
          <AutoComplete
            dropdownMatchSelectWidth={252}
            style={
              className === "responsive_autoSearch" || className === "w-full"
                ? { width: "100%" }
                : { width: 550 }
            }
            options={options}
            className={
              className === "responsive_autoSearch" ? "responsive_input" : ""
            }
            dropdownClassName={`${isSearch && "list-container"}`}
            onSelect={onSelect}
            onSearch={handleSearch}
            notFoundContent={"No Record Found"}
            onFocus={showAllAddresses}
          >
            <Input.Search
              size="large"
              placeholder={
                searchText?.props?.content
                  ? searchText?.props?.content
                  : searchText
              }
              enterButton={""}
            />
          </AutoComplete>
          {/* sreach Icon button */}
          <button
            className="autoComplete_search_button"
            onClick={() => {
              selectAddressHandler();
            }}
          >
            {buttonText ? buttonText : "Mijn diagnose"}
          </button>
        </div>
      </div>
      {bottomText && bottomText === "true" && (
        <div className="w-full text-right px-2 py-2">
          <span>{getValue ? getValue(39) : ""}</span>
        </div>
      )}
    </>
  );
};
export default withRouter(AutoCompleteSearch);
