import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import FAQ from "components/FAQ";
import Banner from "images/faqs/banner.png";
import { GetContentByService } from "services/homepage";
import { Markup } from "interweave";

function PageHome() {
  const [sectionContent, setSectionContent]: any = useState([]);
  useEffect(() => {
    let cityId: any = { id: 6 };
    GetContentByService(cityId)
      .then((response: any) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log("🚀 ~ file: FAQ.tsx ~ line 17 ~ useEffect ~ error", error)
      });
  }, []);
  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Renocity</title>
      </Helmet>
      <div className="faq_main_page">
        {/* <div>
					<img className="w-full" src={Banner} alt="" />
				</div> */}
        <div className="faq-banner-img flex items-center justify-center">
          <span className="banner-heading">{getValue(6)}</span>
        </div>
        <div className="container pt-8 pb-14">
          <FAQ getValue={getValue} />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
