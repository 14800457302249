import axios from "axios";
require("dotenv").config();

//get api for address
export const getAddressesByService = () => {
	return axios.get(process.env.REACT_APP_APP_URL + "/addresses");
};
//post api for menus
export const PageContentByService = (city_id: number, datatype: string) => {
	return axios.post(process.env.REACT_APP_APP_URL + `/getCityDataByType`, { city_id, datatype });
};
//post api to gell all contents of homepage
export const GetContentByService = (id: number) => {
	return axios.post(process.env.REACT_APP_APP_URL + "/getSinglePageData", id);
};
// api to send email
export const SendEmailByService = (payload: object) => {
	return axios.post(process.env.REACT_APP_APP_URL + "/mobilemail", payload);
};