import { useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import City2 from "images/faqs/vector/city2.svg";
import City3 from "images/faqs/vector/city3.svg";
import city4 from "images/faqs/vector/city4.svg";
import { Markup } from "interweave";

function SideNav(props: any) {
  let {
    setBenefitsPage,
    benefitsPage,
    getActiveTabContent,
    faqTitles,
    setFaqTitles,
  } = props;

  const [sideNav, setSideNav]: any = useState();

  // Method to return value through index.
  // const getValue = (index: number): any => {
  //   return faqTitles?.length !== 0 && faqTitles[index]?.value
  //     ? faqTitles[index]?.value
  //     : "";
  // };

  useEffect(() => {
    console.log("faqTitles", faqTitles);
    if (faqTitles)
      setSideNav([
        {
          id: 1,
          name: faqTitles[0]?.value,
          class: benefitsPage === "neighborhood" ? "active" : "inActive",
          image: city4,
        },
        {
          id: 2,
          name: faqTitles[1]?.value,
          class: benefitsPage === "support-subsidies" ? "active" : "inActive",
          image: City3,
        },
        {
          id: 3,
          name: faqTitles[2]?.value,
          class: benefitsPage === "renovations" ? "active" : "inActive",
          image: city4,
        },
        {
          id: 4,
          name: faqTitles[3]?.value,
          class: benefitsPage === "how-it-works" ? "active" : "inActive",
          image: City2,
        },
      ]);
  }, [faqTitles, benefitsPage]);

  const setPageHandler = (value: any) => {
    switch (value) {
      case faqTitles[0].value:
        setBenefitsPage("neighborhood");
        getActiveTabContent(1);
        break;
      case faqTitles[1].value:
        setBenefitsPage("support-subsidies");
        getActiveTabContent(2);
        break;
      case faqTitles[2].value:
        setBenefitsPage("renovations");
        getActiveTabContent(3);
        break;
      case faqTitles[3].value:
        setBenefitsPage("how-it-works");
        getActiveTabContent(4);
        break;
      default:
        setBenefitsPage("neighborhood");
        getActiveTabContent(1);
        break;
    }
  };
  return (
    <div className="nc-sidenav-components">
      {sideNav?.map((nav: any) => (
        <div
          className={`rounded-xl flex hover_shadow_lg my-4 py-4 px-4 nav_box flex items-center ${nav?.class}`}
          onClick={() => {
            setPageHandler(nav?.name);
          }}
        >
          <div>
            <Avatar sizeClass="h-18 w-20" radius="" imgUrl={nav?.image} />
          </div>
          <div className="px-4">
            <span className="font-bold">
              <Markup content={nav?.name} />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SideNav;
