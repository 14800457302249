import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { Helmet } from "react-helmet";
import Banner from "images/new-assets/Banner/benefits/5.png";
import Agenda from "images/new-assets/undraw/agenda.svg";
import Agenda2 from "images/new-assets/undraw/agenda2.svg";
import Agenda3 from "images/new-assets/undraw/agenda3.svg";
import Agenda4 from "images/new-assets/undraw/agenda4.svg";
import Agenda5 from "images/new-assets/undraw/agenda5.svg";
import NcImage from "shared/NcImage/NcImage";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import CheckMarkImg from "images/home/check.svg";
import { withRouter } from "react-router";

function PageHome(props: any) {
	let { getValue, className } = props;
	return (
		<div className="nc-PageHome relative overflow-hidden">
			<Helmet>
				<title>Renocity</title>
			</Helmet>
			<div className="">
				{/* SECTION HERO */}
				<div className="w-full">
					<img src={Banner} width="100%" alt="" />
					<div className="py-4">
					  <h2 className="text-xl font-medium text-neutral-600">
						<span className="">{getValue(188)}</span>
					  </h2>
					</div>
				</div>
			    <div className="benefit-page-snake-image"> 
				<div className="pt-10">
					<div className={`px-8  relative flex lg:justify-between flex-col lg:flex-row  ${className}`} data-nc-id="SectionSubscribe2">
						<div className="mb-10 w-1/2 lg:mb-0 lg:mr-10">
							<h2 className="font-semibold text-sm text-blue-500">{getValue(66)}</h2>
							<span className="whitespace_prewrap block text-neutral-700 dark:text-neutral-400">{getValue(67)}</span>
						</div>
						<div className="w-1/2 snake-image-overlap-hide">
							<NcImage src={Agenda} className="w-1/2" containerClassName="flex justify-end" />
						</div>
					</div>
				</div>
				<div className="pt-14">
					<div className={`px-8 lg:justify-between flex flex-col lg:flex-row  ${className}`} data-nc-id="SectionSubscribe2">
						<div className="w-1/2 snake-image-overlap-hide">
							<NcImage src={Agenda2} className="w-1/2" containerClassName="flex justify-start" />
						</div>
						<div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 flex justify-center snake-image-overlap-text-hide">
							<div>
								<h2 className="font-semibold text-sm text-blue-500">{getValue(69)}</h2>
								<span className="whitespace_prewrap block text-neutral-700 dark:text-neutral-400">{getValue(70)}</span>
								<div className="flex list-container py-4">
									<img src={CheckMarkImg} alt="" className="h-6" />
									<span className="px-2  text-neutral-700 dark:text-neutral-400">{getValue(71)}</span>
								</div>
								<div className="flex list-container py-4">
									<img src={CheckMarkImg} alt="" className="h-6" />
									<span className="px-2 text-neutral-700 dark:text-neutral-400">{getValue(72)}</span>
								</div>
								<div className="flex list-container py-4">
									<img src={CheckMarkImg} alt="" className="h-6" />
									<span className="px-2 text-neutral-700 dark:text-neutral-400">{getValue(73)}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="pt-14">
					<div className={`px-8  relative flex lg:justify-between flex-col lg:flex-row  ${className}`} data-nc-id="SectionSubscribe2">
						<div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 snake-image-overlap-text-hide">
							<h2 className="font-semibold text-sm text-blue-500">{getValue(75)}</h2>
							<span className="whitespace_prewrap block text-neutral-700 dark:text-neutral-400">{getValue(76)}</span>
							<ul className="space-y-4 py-4">
								<li className="flex items-center space-x-4">
									<span className="whitespace_prewrap text-neutral-700 dark:text-neutral-300">{getValue(15)}</span>
								</li>
							</ul>
						</div>
						<div className="w-1/2 snake-image-overlap-hide">
							<NcImage src={Agenda3} className="w-1/2" containerClassName="flex justify-end" />
						</div>
					</div>
				</div>
				<div className="pt-14">
					<div className={`px-8 lg:justify-between flex flex-col lg:flex-row  ${className}`} data-nc-id="SectionSubscribe2">
						<div className="w-1/2 snake-image-overlap-hide">
							<NcImage src={Agenda4} className="w-1/2" containerClassName="flex justify-start" />
						</div>
						<div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 flex justify-center snake-image-overlap-text-hide">
							<div>
								<h2 className="font-semibold text-sm text-blue-500">{getValue(78)}</h2>
								<span className="whitespace_prewrap block text-neutral-700 dark:text-neutral-400">{getValue(79)}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="pt-14">
					<div className={`px-8  relative flex lg:justify-between flex-col lg:flex-row  ${className}`} data-nc-id="SectionSubscribe2">
						<div className="mb-10 w-1/2 lg:mb-0 lg:mr-10 snake-image-overlap-text-hide">
							<h2 className="font-semibold text-sm text-blue-500">{getValue(81)}</h2>
							<span className="whitespace_prewrap block text-neutral-700 dark:text-neutral-400">{getValue(82)}</span>
							<ul className="space-y-4 py-4">
								<li className="flex items-center space-x-4">
									<span className="whitespace_prewrap text-neutral-700 dark:text-neutral-300">{getValue(23)}</span>
								</li>
							</ul>
						</div>
						<div className="w-1/2 snake-image-overlap-hide">
							<NcImage src={Agenda5} className="w-1/2" containerClassName="flex justify-end" />
						</div>
					</div>
				</div>
				</div>
			</div>
			<div className="pt-4 flex justify-center items-center">
				<SectionCalculateBenefit
					title={getValue ? getValue(84) : "How we can help you to reach label A ?"}
					searchText={getValue ? getValue(85) : "Search Address"}
					buttonText={getValue ? getValue(86) : "Mijn diagnose"}
					modelText={getValue ? getValue(197) : ""}
				/>
			</div>
			{/* <div className="container relative py-16 mt-0 w-2/3">
				<SectionHomeTestimonials getValue={[]} />
			</div> */}
		</div>
	);
}

export default withRouter(PageHome);
