import React, { FC, useEffect } from "react";
import imagePng from "images/homepage_img/img_group2.png";
import AutoCompleteSearch from "shared/AutoCompleteSearch/AutoCompleteSearch";
import CheckMarkImg from "images/home/check.svg";
import Notification from "shared/Notification/Notification";

export interface SectionHeroProps {
  className?: string;
  getValue?: any;
  width?: any;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  getValue,
  width,
}) => {
  console.log("className", className);
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row">
        <div className="form-section flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 lg:pb-14">
          <h2 className="font-medium text-4xl leading-[110%]  landing-page-section1-title">
            {getValue(0)}
          </h2>
          <p className="whitespace_prewrap landing-page-section1-title form-info text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            {getValue(1)}
          </p>
          <div>
            <div className="flex mt-2">
              <img
                src={CheckMarkImg}
                alt=""
                className="landing-page-check-mark"
              />
              <span className="px-2 check-mark-text-vertical-centered text-base md:text-lg text-neutral-500 dark:text-neutral-400">
                {getValue(3)}
              </span>
            </div>
            <div className="flex mt-2">
              <img
                src={CheckMarkImg}
                alt=""
                className="landing-page-check-mark"
              />
              <span className="px-2 check-mark-text-vertical-centered text-base md:text-lg text-neutral-500 dark:text-neutral-400">
                {getValue(4)}
              </span>
            </div>
            <div className="flex mt-2">
              <img
                src={CheckMarkImg}
                alt=""
                className="landing-page-check-mark"
              />
              <span className="px-2 check-mark-text-vertical-centered text-base md:text-lg text-neutral-500 dark:text-neutral-400">
                {getValue(5)}
              </span>
            </div>
          </div>
          {/* main_container_input_box */}
          <div className={`${width ? "w-full" : ""}`}>
            <AutoCompleteSearch
              getValue={getValue}
              bottomText="true"
              className={width ? "responsive_autoSearch" : className}
              searchText={
                getValue
                  ? getValue(6)?.props?.content
                    ? getValue(6)?.props?.content
                    : getValue(6)
                  : "Search Address"
              }
              buttonText={getValue ? getValue(38) : "Mijn diagnose"}
              modelText={getValue ? getValue(83) : ""}
            />
          </div>
        </div>
        <div>
          <div className="right-img-container">
            <img className="w-full right-image" src={imagePng} alt="hero" />
            <Notification
              className="desktop_box hidden lg:flex top-8"
              textColor="text-primary-400"
              title={getValue ? getValue(40) : ""}
              content={getValue ? getValue(41) : ""}
            />
            <Notification
              className="desktop_box hidden lg:flex notification-from-top"
              textColor="text-green-400"
              title={getValue ? getValue(42) : ""}
              content={getValue ? getValue(43) : ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
