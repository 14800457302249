import React, { FC, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";
import { withRouter } from "react-router";
import AutoCompleteSearch from "shared/AutoCompleteSearch/AutoCompleteSearch";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Button } from "antd";
import Avatar from "shared/Avatar/Avatar";
import Phone from "images/resultPage/userDetail/telephone.png";

const MainNav1: FC<any> = (props: any) => {
  let { isTop, history, getValue, openModal } = props;
  const [login, setLogin] = useState(false);
  useEffect(() => {
    let token = cookie.load("token");
    if (token) {
      let decoded = jwt_decode(token);
      if (decoded) {
        setLogin(true);
      } else {
        history.push(
          "/listing-renovate-detail/5e8dd6b7-37ff-4e7f-814f-b3bf846faa3e"
        );
      }
    }
  }, [cookie?.load("token")]);

  const logoutHandler = () => {
    let token = cookie.load("token");
    if (token) {
      setLogin(false);
      cookie.remove("token", { maxAge: 15000, path: "/" });
      history.push("/");
    }
  };

  return (
    <div
      className={`nc-MainNav1 ant_buttons_container relative bg-white z-10 shadow-lg  ${
        isTop ? "onTop " : "notOnTop backdrop-filter "
      }`}
    >
      <div className="container result-header-container py-4 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start items-center items-center ">
          <Logo />
        </div>
        {/* <div className="absolute flex justify-center"> */}
        <div className="hidden lg:flex">
          <AutoCompleteSearch
            searchText={getValue(24)?.props?.content}
            buttonText={getValue(25)}
          />
        </div>
        {/* </div> */}
        <div className="flex-shrink-0 profile-header-container flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="items-center xl:flex space-x-1">
            <div className="px-1" />
            <div className="flex items-center">
              <Button
                danger
                className="rounded ml-1 h-full flex items-center"
                onClick={() => {
                  logoutHandler();
                }}
              >
                <span className="px-2">{getValue(31)}</span>
              </Button>
              <div className="icons_container">
                <Button
                  className="rounded ant_secondary_button ml-2 p-0 lg-500 flex border-neutral-400 p-2 cursor-pointer items-center h-fit-content"
                  onClick={openModal}
                >
                  <img src={Phone} alt="" className="h-6 w-6 " />
                </Button>
              </div>
            </div>
          </div>
          <div className="hidden">
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MainNav1);
