import React, { useState } from "react";
import Heading from "components/Heading/Heading";
import { RenovationsType } from "data/types";
import Computer from "images/landingPage/section2/computer.svg";
import HigherComfort from "images/landingPage/section2/higher_comfort.svg";
import Process from "images/landingPage/section2/process.svg";
import Stronger from "images/landingPage/section2/stronger_together.svg";
import Subsidies from "images/landingPage/section2/subsidies.svg";

export interface SectionGridCategoryBoxProps {
  categories?: RenovationsType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
  getValue?: any;
}

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categories,
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  getValue,
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3",
}) => {
  const [activeBox, setActiveBox] = useState(0);
  const Renovations1: any = [
    {
      id: 1,
      href: "#",
      name: getValue(9),
      text: "See more",
      taxonomy: "category",
      hoverContent: getValue(45),
      count: 1882,
      thumbnail: HigherComfort,
    },
    {
      id: 2,
      href: "#",
      name: getValue(10),
      text: "See more",
      taxonomy: "category",
      hoverContent: getValue(46),
      count: 8288,
      thumbnail: Stronger,
    },
    {
      id: 3,
      href: "#",
      name: getValue(11),
      text: "See more",
      taxonomy: "category",
      hoverContent: getValue(47),
      count: 1288,
      thumbnail: Subsidies,
    },
  ];
  const Renovations2: any = [
    {
      id: 4,
      href: "#",
      name: getValue(14),
      text: "See more",
      taxonomy: "category",
      hoverContent: getValue(48),
      count: 1288,
      thumbnail: Subsidies,
    },
    {
      id: 5,
      href: "#",
      name: getValue(15),
      text: "See more",
      taxonomy: "category",
      hoverContent: getValue(49),
      count: 112,
      thumbnail: Computer,
    },
    {
      id: 6,
      href: "#",
      name: getValue(16),
      text: "See more",
      taxonomy: "category",
      hoverContent: getValue(50),
      count: 323,
      thumbnail: Process,
    },
  ];
  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading desc="" isCenter className="m-0">
        {getValue(7)}
      </Heading>
      <div>
        <div className="flex lg:items-center py-8">
          <div className={`flex justify-around flex-wrap w-full`}>
            {Renovations1.map((item: any, i: any) => (
              <div
                className={`mobile_box  ${
                  item.id === activeBox && "active-box-container"
                }`}
                key={i}
              >
                <div
                  key={i}
                  onMouseOver={() => {
                    item.id === activeBox
                      ? setActiveBox(0)
                      : setActiveBox(item.id);
                  }}
                  className={`nc-CardCategoryBox1 pt-4 justify-center items-center  h-full w-full rounded-lg flex shadow-lg relative [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}
                  `}
                  data-nc-id="CardCategoryBox1"
                >
                  {item.id === activeBox && (
                    <div className="active-box text-center px-2 py-2 flex items-center justify-center pointer-events-none">
                      <p className="whitespace_prewrap">{item.hoverContent}</p>
                    </div>
                  )}
                  <div className=" mt-4 flex-grow overflow-hidden text-center benefit-title-vertically-center p-3 sm:p-6 pointer-events-none">
                    <h2 className="landing-benefit-text font-bold pb-4 ">
                      <span className="">{item?.name}</span>
                    </h2>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex lg:items-center lg:py-8">
          <div className={`flex justify-around flex-wrap w-full`}>
            {Renovations2.map((item: any, i: any) => (
              <div
                className={`mobile_box ${
                  item.id === activeBox && "active-box-container"
                }`}
                key={i}
              >
                <div
                  key={i}
                  onMouseOver={() => {
                    item.id === activeBox
                      ? setActiveBox(0)
                      : setActiveBox(item.id);
                  }}
                  className={`nc-CardCategoryBox1 pt-4 items-center h-full w-full rounded-lg flex shadow-lg relative [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}
                  `}
                  data-nc-id="CardCategoryBox1"
                >
                  {item.id === activeBox && (
                    <div className="active-box text-center px-2 py-2 flex items-center justify-center pointer-events-none">
                      <p className="whitespace_prewrap">{item.hoverContent}</p>
                    </div>
                  )}
                  <div className=" mt-4 flex-grow overflow-hidden text-center benefit-title-vertically-center p-3 sm:p-6 pointer-events-none">
                    <h2 className="landing-benefit-text font-bold pb-4">
                      <span className="">{item?.name}</span>
                    </h2>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
