import React from "react";
import Banner from "images/Banner-new1.png";
import GroupImage from "images/landing-page/group.png";
import Content from "components/Content/Content";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import Aalst from "images/landing-page/aalst_color.svg";
import Bosch from "images/landing-page/agc_color.svg";
import Vlaanderen from "images/footer/vlaanderen_grey.svg";

function PageHome(props: any) {
  let { getValue } = props;
  return (
    <div className="nc-whoWeArePage-container relative overflow-hidden pb-8">
      <div className="nc-whoWeArePage-banner-img flex items-center justify-center">
        <span className="banner-heading">{getValue ? getValue(0) : ""}</span>
      </div>
      <div className="flex justify-center">
        <div className="pt-8 text-center w-2/3">
          <p className="whitespace_prewrap mt-2 md:mt-3 font-normal block text-lg text-neutral-500">
            {getValue ? getValue(1) : ""}
          </p>
        </div>
      </div>
      <div className="mt-0 pb-2 container">
        <div className="flex justify-center">
          <div className="col-span-2 px-10 md:col-span-1">
            <img className="h-40" src={Vlaanderen} alt="hero" />
          </div>
          <div className="col-span-2 px-10 md:col-span-1">
            <img className="h-40" src={Aalst} alt="hero" />
          </div>
          <div className="col-span-2 px-10 md:col-span-1">
            <img className="h-40" src={Bosch} alt="hero" />
          </div>
        </div>
      </div>
      <div className="container py-8">
        <img src={GroupImage} alt="" />
      </div>
      <div className="container flex justify-center">
        <div className="w-2/3 pt-8 pb-14 flex justify-center items-center">
          <SectionCalculateBenefit
            title={
              getValue ? getValue(6) : "How we can help you to reach label A ?"
            }
            searchText={getValue ? getValue(7) : "Search Address"}
            buttonText={getValue ? getValue(8) : "Mijn diagnose"}
            modelText={getValue ? getValue(25) : ""}
          />
        </div>
      </div>
      {/* <div className="container relative py-24 mt-0 w-2/3"> 
        <SectionHomeTestimonials getValue={[]} />
      </div> */}
    </div>
  );
}

export default PageHome;
