import React, { useEffect, useState } from "react";
import SectionHero from "components/SectionHero/SectionHero";
import SectionCity from "components/SectionCity/SectionCity";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import RenocityBenefits from "components/RenocityBenefits/RenocityBenefits";
import LabelA from "components/LabelA/LabelA";
import { GetContentByService } from "../../services/homepage";
import Notification from "shared/Notification/Notification";
import { Helmet } from "react-helmet";
import imagePng from "images/homepage_img/img_group2.png";
import { Markup } from "interweave";
import NotificationModal from "shared/Modal/EmailNotificationModal";

function PageHome() {
  const [sectionContent, setSectionContent]: any = useState([]);
  const [modal, setModal] = useState(false);
  const [pageWidth, setPageWidth]: any = useState(false);
  useEffect(() => {
    const hasWindow = typeof window !== "undefined";
    const width: any = hasWindow ? window.innerWidth : null;

    if (width < 1023) {
      setModal(true);
      setPageWidth(true);
    } else {
      setPageWidth(false);
    }
    let cityId: any = { id: 1 };
    GetContentByService(cityId)
      .then((response) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };
  console.log("sectionContent", sectionContent);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Renocity</title>
      </Helmet>
      {modal && <NotificationModal setModal={setModal} getValue={getValue} />}
      <div className=" relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <div className="mt-0">
          <div className="container relative">
            <SectionHero
              className="pt-14"
              getValue={getValue}
              width={pageWidth}
            />
          </div>
        </div>
        {/* desktop house image */}
        <div className="block lg:hidden mt-0 responsive_notification_box">
          <Notification
            className="lg:hidden block w-3/4"
            textColor="text-primary-400"
            title={getValue ? getValue(40) : ""}
            content={getValue ? getValue(41) : ""}
          />
          <Notification
            className="lg:hidden block w-3/4 second_notification_box"
            textColor="text-green-400"
            title={getValue ? getValue(42) : ""}
            content={getValue ? getValue(43) : ""}
          />
          <img className="right-image" src={imagePng} alt="hero" />
        </div>

        <div className="container">
          <RenocityBenefits
            categoryCardType="card5"
            itemPerRow={5}
            getValue={getValue}
          />
        </div>
        <div className="section2-container py-8">
          <div className="container py-8">
            <SectionGridCategoryBox getValue={getValue} />
          </div>
        </div>
        <div className="section3-container mt-0 py-8">
          <div className="container relative py-16">
            <LabelA getValue={getValue} />
          </div>
        </div>
        {/* SectionCity */}
        <div className="container homePage_sectionCity_container">
          <SectionCity getValue={getValue} />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
