import React from "react";
import Aalst from "images/footer/aalst_grey.png";
import Vlaanderen from "images/footer/vlaanderen_grey.png";
import { withRouter } from "react-router";

const Footer = (props: any) => {
  let { getValue, history, match } = props;
  console.log(
    "history",
    history?.location?.pathname?.includes("forget-password")
  );
  console.log("match", match);
  return (
    <div
      className={`nc-Footer h-full flex flex-col justify-end ${
        history?.location?.pathname?.includes("forget-password")
          ? "absolute w-full top-80 "
          : ""
      }`}
    >
      <div className="bg-dark-gray ">
        <div className="container">
          <div className="flex justify-center">
            <div className="px-6 ">
              <a href="https://www.aalst.be/" target="_blank">
                <img className="h-40" src={Aalst} alt="hero" />
              </a>
            </div>
            <div className="px-6 ">
              <a href="https://www.vlaanderen.be/" target="_blank">
                <img className="h-40" src={Vlaanderen} alt="hero" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary-400">
        <div className="container">
          <div className="flex flex-col lg:flex-row justify-between py-4 px-2 items-center">
            <div className="col-span-2 md:col-span-1 py-2">
              <p className="text-md text-center text-white m-0">
                {getValue && getValue(80)}
              </p>
            </div>
            <div className="col-span-2 md:col-span-1 py-2">
              <a href="https://agifly.be/" target="_blank">
                <p className="text-md text-center text-white m-0">
                  {getValue && getValue(81)}
                </p>
              </a>
            </div>
            <div className="col-span-2md:col-span-1 py-2">
              <p className="text-md text-center text-white m-0">
                {getValue && getValue(82)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Footer);
