import React, { FC } from "react";
import SectionRenovates from "components/Renovates";
import { Helmet } from "react-helmet";

export interface RenovatePageProps {}

const RenovatePage: FC<RenovatePageProps> = () => {
  return (
    <div className="nc-renovate relative overflow-hidden">
      <Helmet>
        <title>Renocity</title>
      </Helmet>
      <div className="container pt-8 pb-14">
        <SectionRenovates />
      </div>
    </div>
  );
};

export default RenovatePage;
