import React, { useState, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Page } from "./types";
// import Cookie from "shared/Cookie/Cookie";
import Header from "shared/Header/Header";
import Footer from "shared/Footer/Footer";
import Menus from "shared/MenuTitles/MenuTitles";
import ScrollToTop from "./ScrollToTop";
import PageHome from "containers/PageHome/PageHome";
import ForgetPassword from "containers/ForgetPassword/ForgetPassword";
import RenovatePage from "containers/Renovate/Renovate";
import PrivacyPolicy from "containers/PrivacyPolicy/PageAbout";
import Benefits from "containers/Benefits/Benefits";
import ProcessPage from "containers/Process/Process";
import WhoWeAre from "containers/WhoWeAre/WhoWeAre";
import Profile from "containers/Profile/Profile";
import FAQ from "containers/FAQ/FAQ";
import Page404 from "containers/Page404/Page404";
import ContactUs from "containers/ContactUs/ContactUs";
import RenovateResult from "containers/Results/Results";
import { GetContentByService } from "../services/homepage";
import BookAnAuditModal from "shared/Modal/BookAnAuditForm";
import { Helmet } from "react-helmet";
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useCookieConsent } from "use-cookie-consent";
import { Markup } from "interweave";
require("dotenv").config();

export const pages: Page[] = [
  // AGC routes starts from here
  { path: "/", exact: true, component: PageHome },
  { path: "/benefits", component: Benefits },
  { path: "/renovates", component: RenovatePage },
  { path: "/process", component: ProcessPage },
  { path: "/who-we-are", component: WhoWeAre },
  { path: "/faq", component: FAQ },
  { path: "/listing-renovate-detail/:id", component: RenovateResult },
  { path: "/profile", component: Profile },
  { path: "/contact-us", component: ContactUs },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/forget-password/:id", component: ForgetPassword },
];

const Routes = (props: any) => {
  let { history } = props;
  const [header, setHeader] = useState("single-header");
  const [login, setLogin] = useState(false);
  const [menus, setMenus]: any = useState([]);
  const [cookieBanner, setCookieBanner] = useState("");
  const [sectionContent, setSectionContent]: any = useState([]);
  const [auditModal, setAuditModal] = useState(false);
  const { consent, acceptAllCookies, acceptCookies } = useCookieConsent();

  const sortOrder = [
    "What we renovate for you",
    "Benefits",
    "The process",
    "Who we are",
    "FAQ",
    "Contact us",
    "Profile",
  ];
  const sortedRenovation = (renovations: any) => {
    // method to sort array
    let sortedArray: any = [];
    const orderForIndexVals = sortOrder.slice(0).reverse();
    return (sortedArray = renovations.sort((a: any, b: any) => {
      const aIndex = -orderForIndexVals.indexOf(a.decription);
      const bIndex = -orderForIndexVals.indexOf(b.decription);
      return aIndex - bIndex;
    }));
  };

  // useEffect to check user and to get content
  useEffect(() => {
    let token = cookie.load("token");
    if (token) {
      let decoded = jwt_decode(token);
      if (decoded) {
        setLogin(true);
      }
    } else {
      setLogin(false);
    }
    if (Object.keys(consent).length > 0) {
      setCookieBanner("true");
    }
    //  to get content
    let cityId: any = { id: 1 };
    GetContentByService(cityId)
      .then((response) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cookie.load("token")]);

  const listenScrollEvent = () => {
    if (window.scrollY < 300) {
      return setHeader("single-header");
    } else if (window.scrollY > 300) {
      return setHeader("header-added");
    }
  };
  // useEffect to switch different headers
  useEffect(() => {
    if (history.location.pathname !== "/") {
      setHeader("header-added");
    } else {
      window.addEventListener("scroll", listenScrollEvent);
      return () => window.removeEventListener("scroll", listenScrollEvent);
    }
  }, [history.location.pathname]);

  // useEffect to
  useEffect(() => {
    let payload = { city_id: 1, datatype: "menu" };
    axios
      .post(process.env.REACT_APP_APP_URL + `/getCityDataByType`, payload)
      .then((res) => {
        let sortedMenus = sortedRenovation(res?.data?.data?.pages);
        setMenus(sortedMenus);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const logoutHandler = () => {
    let token = cookie?.load("token");
    if (token) {
      cookie.remove("token", { maxAge: 15000, path: "/" });
      setLogin(false);
    }
  };

  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/25186371.js"
        ></script>
      </Helmet>
      {/* cookie */}
      {/* {cookieBanner !== "true" && (
        <Cookie
          setCookieBanner={setCookieBanner}
          consent={consent}
          acceptAllCookies={acceptAllCookies}
          acceptCookies={acceptCookies}
          getValue={getValue}
        />
      )} */}
      {auditModal && (
        <BookAnAuditModal
          setModal={setAuditModal}
          modal={auditModal}
          getValue={getValue}
        />
      )}
      {/* routes */}
      <div id="hs-script-loader"></div>
      {history?.location?.pathname === "/" ||
      history?.location?.pathname === "/benefits" ||
      history?.location?.pathname === "/renovates" ||
      history?.location?.pathname === "/process" ||
      history?.location?.pathname === "/promoted-city" ||
      history?.location?.pathname === "/who-we-are" ||
      history?.location?.pathname === "/faq" ||
      history?.location?.pathname === "/privacy-policy" ||
      history?.location?.pathname === "/contact-us" ? (
        <React.Fragment>
          <Header
            header={header}
            menus={menus}
            login={login}
            setLogin={setLogin}
            logoutHandler={logoutHandler}
            getValue={getValue}
            setAuditModal={setAuditModal}
          />
          <Menus header={header} menus={menus} />
        </React.Fragment>
      ) : (
        ""
      )}
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer getValue={getValue} />
    </>
  );
};

export default withRouter(Routes);
