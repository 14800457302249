import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Button } from "antd";
import { Helmet } from "react-helmet";
import { TimePicker } from "antd";
// import HubspotForm from "react-hubspot-form";
import { SetUserProfileStatusByService } from "services/ResultPage";

const { RangePicker } = TimePicker;

const Modal = (props: any) => {
  let {
    isOpenModal,
    closeModal,
    getValue,
    allRenovationRecord,
    setUserStatus,
    userData,
    houseDetails,
  } = props;

  const [timer, setTimer] = useState(true);
  const [show, setShow] = useState("book-an-audit");

  const submitMeeting = () => {
    let payload = {
      userId: userData?.id,
      userDataId: houseDetails?.id,
      status: 2,
    };
    if (payload) {
      SetUserProfileStatusByService(payload)
        .then((profileResponse: any) => {
          // success
          setUserStatus(profileResponse);
        })
        .catch((error: any) => {});
      setShow("success");
    }
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 10 seconds set the show value to false
      setTimer(false);
    }, 10000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full">
              <Helmet>
                {/* <script
                  type="text/javascript"
                  src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
                /> */}
                <script
                  type="text/javascript"
                  src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
                ></script>
              </Helmet>
              <div className="inline-flex flex-col w-full lg:w-1/2 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h3
                    className="text-sm m-0 text-left text-gray-500"
                    id="headlessui-dialog-title-70"
                  >
                    {getValue(159)}
                  </h3>
                  <span className="absolute right-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>
                {show === "book-an-audit" ? (
                  <React.Fragment>
                    <div className="px-8 py-8">
                      {/* right side */}
                      <div className="px-4 flex flex-col">
                        <span className="text-left text-lg font-medium text-primary-500">
                          {getValue(160)}
                        </span>
                        <span className="text-left py-4 text-sm">
                          {getValue(161)}
                        </span>
                      </div>
                      {/* <div className="px-4 py-4">
                        <div className="w-32 border-b border-neutral-200 dark:border-neutral-700"></div>
                        <div className="flex justify-between py-4 pr-4">
                          <div className="flex items-center space-x-3">
                            <Avatar
                              sizeClass="h-10 w-10"
                              radius="rounded-full"
                            />
                            <div className="flex flex-col">
                              <span className="text-md">{getValue(163)}</span>
                              <span className="text-md py-1">
                              {getValue(177)}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center space-x-3">
                            <Avatar
                              sizeClass="h-10 w-10"
                              radius="rounded-full"
                            />
                            <div className="flex flex-col">
                              <span className="text-md">{getValue(165)}</span>
                              <span className="text-md py-1">{getValue(178)}</span>
                            </div>
                          </div>
                          <div className="flex items-center space-x-3">
                            <Avatar
                              sizeClass="h-10 w-10"
                              radius="rounded-full"
                            />
                            <div className="flex flex-col">
                              <span className="text-md">{getValue(167)}</span>
                              <span className="text-md py-1">{getValue(179)}</span>
                            </div>
                          </div>
                        </div>
                        <div className="w-32 border-b border-neutral-200 dark:border-neutral-700"></div>
                      </div> */}
                      <div className="flex px-4 py-4">
                        <h5 className="text-lg">{getValue(168)}</h5>
                      </div>
                      <div className="flex px-4">
                        <div className="w-full calendar_container">
                          <div
                            className="meetings-iframe-container"
                            data-src="https://meetings-eu1.hubspot.com/jellen/gratis-audit?embed=true"
                          ></div>
                          {/* <div
                            className="meetings-iframe-container"
                            data-src="https://meetings-eu1.hubspot.com/arnaud-castagne?embed=true"
                          ></div> */}
                        </div>
                      </div>
                    </div>
                    <div className="px-6 py-4 flex justify-between border-b border-neutral-100 bg-neutral-100 dark:border-neutral-800 text-center">
                      <Button
                        className="rounded-4xl mx-2"
                        size={"large"}
                        onClick={() => closeModal()}
                      >
                        Annuleer
                      </Button>
                      <Button
                        type="primary"
                        className="rounded-4xl"
                        size={"large"}
                        disabled={timer ? true : false}
                        onClick={() => submitMeeting()}
                      >
                        Bevestig audit
                      </Button>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="px-8 py-8 flex flex-col items-center">
                      {/* right side */}
                      <div className="px-4">
                        <span className="text-center font-medium text-primary-500">
                          {getValue(171)}
                          {/* Audit Booked ! */}
                        </span>
                      </div>
                      {/* left side  */}
                      <div className="py-4">
                        {getValue(172)}
                        {/* <Avatar sizeClass="h-40 w-40" radius="rounded-full" /> */}
                      </div>
                    </div>
                    <div className="px-6 py-4 flex justify-end border-b border-neutral-100 bg-neutral-100 dark:border-neutral-800 text-center">
                      <Button
                        className="rounded-4xl mx-2"
                        size={"large"}
                        onClick={() => closeModal()}
                      >
                        Annuleer
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
export default Modal;
