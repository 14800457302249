import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo/color_2.png";
import logoLightImg from "images/logo/color_2.png";

export interface LogoProps {
	img?: string;
	imgLight?: string;
	className?: string;
}

const Logo: React.FC<LogoProps> = ({ img = logoImg, imgLight = logoLightImg, className = "" }) => {
	return (
		<Link to="/" className={`ttnc-logo inline-block text-primary-6000 ${className}`}>
			{/* THIS USE FOR MY CLIENT */}
			{/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
			{img ? <img className={`block header-logo-dutch header-logo ${imgLight ? "dark:hidden" : ""}`} src={img} alt="Logo" /> : "Logo Here"}
			{imgLight && <img className="hidden h-16 dark:block" src={imgLight} alt="Logo-Light" />}
		</Link>
	);
};

export default Logo;
