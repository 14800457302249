import { existRenovationHandler } from "../../containers/Results/constants";

// right side modal handlers
export const heatingValuesToNumbersHandler = (
  heating_type: any,
  subtype: any,
  fuel: any
) => {
  if (heating_type === "boiler" && subtype === "standard" && fuel === "oil") {
    return 1;
  } else if (
    heating_type === "boiler" &&
    subtype === "standard" &&
    fuel === "gas"
  ) {
    return 2;
  } else if (
    heating_type === "electric_heater" &&
    subtype === "standard" &&
    fuel === "electricity"
  ) {
    return 3;
  } else if (
    heating_type === "boiler" &&
    subtype === "condensing" &&
    fuel === "gas"
  ) {
    return 4;
  } else if (
    heating_type === "heat_pump" &&
    subtype === "air_water" &&
    fuel === "electricity"
  ) {
    return 5;
  } else if (
    heating_type === "heat_pump" &&
    subtype === "ground_water" &&
    fuel === "electricity"
  ) {
    return 6;
  } else {
    return "";
  }
};

export const heatingNumbersToValuesHandler = (value: any) => {
  if (value === "") {
    return "";
  }
  if (value === 1) {
    return { heating_type: "boiler", subtype: "standard", fuel: "oil" };
  }
  if (value === 2) {
    return { heating_type: "boiler", subtype: "standard", fuel: "gas" };
  }
  if (value === 3) {
    return {
      heating_type: "electric_heater",
      subtype: "standard",
      fuel: "electricity",
    };
  }
  if (value === 4) {
    return {
      heating_type: "boiler",
      subtype: "condensing",
      fuel: "gas",
    };
  }
  if (value === 5) {
    return {
      heating_type: "heat_pump",
      subtype: "air_water",
      fuel: "electricity",
    };
  }
  if (value === 6) {
    return {
      heating_type: "heat_pump",
      subtype: "ground_water",
      fuel: "electricity",
    };
  }
};

export const wallValuesHandler = (value: any) => {
  if (value === "") {
    return "";
  }
  if (value === 1) {
    return { u_value: 1.6, has_cavity: true };
  }
  if (value === 2) {
    return { u_value: 0.65, has_cavity: true };
  }
  if (value === 3) {
    return { u_value: 0.55, has_cavity: true };
  }
  if (value === 4) {
    //   before
    return { u_value: 0.3, has_cavity: true };
  }
  if (value === 5) {
    return { u_value: 2.04, has_cavity: null };
  }
  if (value === 6) {
    return { u_value: 0.4, has_cavity: false };
  }
  if (value === 7) {
    return { u_value: 0.3, has_cavity: false };
  }
  if (value === 8) {
    return { u_value: 0.2, has_cavity: false };
  }
};
// Single single 5.5 Old double (<2000) ordinary_double 3.0 New double (>2000) hr_double_after_2000 1.5 Triple triple 0.9
export const windowTypeHandler = (value: any) => {
  if (value === "") {
    return "";
  }
  if (value === "single") {
    return 5.5;
  }
  if (value === "ordinary_double") {
    return 3.0;
  }
  if (value === "hr_double_after_2000") {
    return 1.5;
  }
  if (value === "triple") {
    return 0.9;
  }
};

export const ventillationValuesHandler = (mechanical_type: any) => {
  if (mechanical_type === "natural") {
    return "";
  }
  if (mechanical_type === "system_c") {
    return "system_c";
  }
  if (mechanical_type === "system_cplus") {
    return "system_cplus";
  }
  if (mechanical_type === "system_d") {
    return "system_d";
  }
};

export const mechanicalValuesHandler = (mechanical_type: any) => {
  if (mechanical_type === "natural") {
    return "natural";
  } else {
    return "mechanical";
  }
};

export const roofValuesHandler = (value: any) => {
  if (value === "") {
    return "";
  }
  if (value === 1) {
    return { u_value: 2.8, roof_type: "" };
  }
  if (value === 2) {
    return { u_value: 0.8, roof_type: "attic" };
  }
  if (value === 3) {
    return { u_value: 0.4, roof_type: "attic" };
  }
  if (value === 4) {
    return { u_value: 0.2, roof_type: "attic" };
  }
  if (value === 5) {
    return { u_value: 0.8, roof_type: "interior" };
  }
  if (value === 6) {
    return { u_value: 0.4, roof_type: "interior" };
  }
  if (value === 7) {
    return { u_value: 0.2, roof_type: "interior" };
  }
  if (value === 8) {
    return { u_value: 0.4, roof_type: "exterior" };
  }
  if (value === 9) {
    return { u_value: 0.2, roof_type: "exterior" };
  }
  if (value === 10) {
    return { u_value: 0.15, roof_type: "exterior" };
  }
};

// method for call 2 and call 3 models
export const updateRenovations = (
  response: any,
  userModalRenovations: any,
  houseDetails: any,
  setAllUserSelectedData: any,
  setEpcA: any,
  renovationPostProcessingForPackB: any,
  renovationsInvestmentPrice: any,
  defaultRenovations: any,
  setRenovations: any,
  setUserExistRenovations: any,
  setSelectedUserCombination: any,
  renovation_refinements?: any,
  setFutureEpc?: any,
  setCustomStars?: any,
  setSelectedPack?: any
) => {
  let allRecords: any = response?.data[0]?.user_selected_data;

  // if (allRecords?.length !== 0) {
  // method for allRenovation
  let exitRenovation: any = [];
  let selected = existRenovationHandler(allRecords, exitRenovation);
  setUserExistRenovations(selected);
  setAllUserSelectedData(allRecords);
  // pack A

  let packA: any = renovationPostProcessingForPackB(
    allRecords,
    renovation_refinements,
    houseDetails
  );

  // to get and store pack label a records
  if (packA) {
    let newArray: any = [];
    let duplicateCall0: any = [...userModalRenovations];
    let addingNewRenovation: any = [
      {
        ...duplicateCall0[1],
        renovation: "glazing",
      },
      {
        ...duplicateCall0[1],
        total_subsidy: packA[0].solar_subsidy,
        renovation: "solar",
      },
    ];
    // new array with solar and glazing
    let allCombinedRenovations = [
      ...addingNewRenovation,
      ...userModalRenovations,
    ];
    let record: any = [...allCombinedRenovations]?.filter((content: any) =>
      packA[0]?.renovation
        ?.split("_")
        ?.some((key: any) => content.renovation === key)
    );
    record?.map((renovations: any) =>
      newArray.push({
        ...renovations,
        active: 1,
      })
    );
    setEpcA(packA[0]?.renovation);
    
    if (newArray) {
      newArray = renovationsInvestmentPrice(newArray, packA[0]);
    }
    // newArray = defaultRenovations(response?.data, newArray);
    // remove records if null
    let removingNullRenovation = newArray?.filter(
      (renovation: any) => renovation?.investment_cost !== null
    );
    // remove records if price is 0
    let remaingRenovations = removingNullRenovation?.filter(
      (renovation: any) => parseInt(renovation?.investment_cost) !== 0
    );
    setRenovations(remaingRenovations);
    if (allRecords[0]?.epc_label && parseInt(allRecords[0]?.epc_label) === 1) {
      setSelectedUserCombination(packA[0]);
      setFutureEpc("1");
      setCustomStars(5);
    } else {
      setSelectedUserCombination({});
      setFutureEpc("2");
      setCustomStars(4);
    }
    setSelectedPack("packB");
  }
};
// };
