import Avatar from "shared/Avatar/Avatar";
import Window from "images/resultPage/renovations/window_double@2x.png";
import Solar_panels from "images/resultPage/renovations/solar_panels@2x.png";
import HeatPump from "images/resultPage/renovations/heatpump@2x.png";
import Roof_Attic from "images/renovations/image/roof_attic.png";
import VentilationC from "images/resultPage/renovations/ventilation_c@2x.png";
import Wall_Cavity from "images/resultPage/renovations/wall_cavity@2x.png";

function SideNav(props: any) {
  let { setCurrentPage, CurrentPage, getValue } = props;
  const sideNav = [
    {
      id: 1,
      name: ` Window & doors`,
      class: CurrentPage === "Window-doors" ? "active" : "inActive",
      image: Window,
    },
    {
      id: 2,
      name: ` Solar Panel`,
      class: CurrentPage === "Solar-panel" ? "active" : "inActive",
      image: Solar_panels,
    },
    {
      id: 3,
      name: ` Roofs`,
      class: CurrentPage === "roofs" ? "active" : "inActive",
      image: Roof_Attic,
    },
    {
      id: 4,
      name: ` Wall Insulation`,
      class: CurrentPage === "Wall-insulation" ? "active" : "inActive",
      image: Wall_Cavity,
    },
    {
      id: 5,
      name: ` Heating System`,
      class: CurrentPage === "Heating-system" ? "active" : "inActive",
      image: HeatPump,
    },
    {
      id: 6,
      name: ` Ventilation System`,
      class: CurrentPage === "Ventilation-system" ? "active" : "inActive",
      image: VentilationC,
    },
  ];

  const setPageHandler = (value: any) => {
    switch (value) {
      case ` Window & doors`:
        return setCurrentPage("Window-doors");
      case ` Solar Panel`:
        return setCurrentPage("Solar-panel");
      case ` Roofs`:
        return setCurrentPage("roofs");
      case ` Wall Insulation`:
        return setCurrentPage("Wall-insulation");
      case ` Heating System`:
        return setCurrentPage("Heating-system");
      case ` Ventilation System`:
        return setCurrentPage("Ventilation-system");
      default:
        return setCurrentPage("Window-doors");
    }
  };
  const setNamesHandler = (value: any) => {
    switch (value) {
      case ` Window & doors`:
        return getValue(140);
      case ` Solar Panel`:
        return getValue(141);
      case ` Roofs`:
        return getValue(142);
      case ` Wall Insulation`:
        return getValue(143);
      case ` Heating System`:
        return getValue(144);
      case ` Ventilation System`:
        return getValue(145);
    }
  };
  return (
    <div className="nc-sidenav-components">
      {sideNav?.map((nav) => (
        <div
          className={`rounded-xl flex hover_shadow_lg my-4 py-4 px-4 nav_box flex items-center font-bold ${nav?.class}`}
          onClick={() => {
            setPageHandler(nav?.name);
          }}
        >
          <div>
            <Avatar sizeClass="h-18 w-20" radius="" imgUrl={nav?.image} />
          </div>
          <div className="px-4">
            <span className="">{setNamesHandler(nav?.name)}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SideNav;
