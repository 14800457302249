import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import Mail from "images/resultPage/userDetail/mail.svg";
import Phone from "images/resultPage/userDetail/phone.svg";
import Location from "images/resultPage/userDetail/location.svg";
import Calendar from "images/resultPage/userDetail/calendar.svg";
import User from "images/resultPage/userDetail/user.svg";

export interface SectionHeroProps {
  className?: string;
  userStatus?: any;
  getResultContent?: any;
}

const SectionDiagnosis: FC<SectionHeroProps> = ({ className, userStatus, getResultContent }) => {
  const activeStatusHanlder = (userStatus: any) => {

    switch (userStatus) {
      case 2:
        return getResultContent(252);
      case 3:
        return getResultContent(253);
      case 4:
        return getResultContent(254);
      case 5:
        return getResultContent(255);
      default:
        return "";
    }
  };

  const statusBackgroundColor = (userStatus: any) => {
    switch (userStatus) {
      case 2:
        return "statusBg-primary-400";
      case 3:
        return "statusBg-primary-400";
      case 4:
        return "statusBg-primary-400";
      case 5:
        return "bg-green-500";
      default:
        return "";
    }
  };
  return (
    <>
      {parseInt(userStatus?.data?.status) > 1 && (
        <div
          className={`section-renvoationSteps-container section_width my-0 p-0 ${className}`}
        >
          {/* header to show step status */}
          <div
            className={`py-8 text-center ${statusBackgroundColor(
              parseInt(userStatus?.data?.status)
            )}`}
          >
            <h3 className="text-white m-0 text-lg">
              {activeStatusHanlder(parseInt(userStatus?.data?.status))}
            </h3>
          </div>
          <div className="bg-neutral-100">
            <div className="container mx-auto">
              <div className="flex flex-col lg:flex-row justify-between flex-grow py-4 pr-4">
                <div className="flex items-center space-x-3 py-2">
                  <Avatar sizeClass="h-8 w-8" imgUrl={User} radius="rounded" />
                  <div className="flex flex-col">
                    <span className="text-md">
                      {getResultContent(256)}
                    {/* Energy auditer */}
                    </span>
                    <span className="text-md">
                    {getResultContent(257)}
                      {/* Maggy Dewitte */}
                      </span>
                  </div>
                </div>
                <div className="flex items-center space-x-3 py-2">
                  <Avatar sizeClass="h-8 w-8" imgUrl={Mail} radius="rounded" />
                  <div className="flex flex-col">
                    <span className="text-md"> 
                    {getResultContent(258)}
                    {/* Email */}
                    </span>
                    <span className="text-md">
                    {getResultContent(259)}
                      {/* maggy@renocity.be */}
                      </span>
                    {/* <span className="text-md">0475/23.45.89</span> */}
                  </div>
                </div>
                {/* <div className="flex items-center space-x-3">
                  <Avatar
                    sizeClass="h-8 w-8"
                    imgUrl={Calendar}
                    radius="rounded"
                  />
                  <div className="flex flex-col">
                    <span className="text-md">When</span>
                    <span className="text-md ">5 november 2021 ,</span>
                    <span className="text-md">16:00 - 16:45</span>
                  </div>
                </div> */}
                <div className="flex items-center space-x-3">
                <Avatar sizeClass="h-8 w-8" 
                imgUrl={Phone} 
                radius="rounded" />
                  <div className="flex flex-col">
                    <span className="text-md">
                    {getResultContent(260)}
                      {/* Phone Number */}
                      </span>
                    <span className="text-md">
                       {getResultContent(261)}
                    {/* 0475/23.45.89 */}
                    </span>
                  </div>
                  {/* <Avatar
                    sizeClass="h-8 w-8"
                    imgUrl={Location}
                    radius="rounded"
                  />
                  <div className="flex flex-col">
                    <span className="text-md">Location</span>
                    <span className="text-md">Albertlaan 39 - 3000 Leuven</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SectionDiagnosis;
