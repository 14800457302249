import Avatar from "images/new-assets/Banner/benefits/2.png";
import CheckMarkImg from "images/home/check.svg";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import { withRouter } from "react-router";

function Benefits(props: any) {
  let { getValue } = props;
  return (
    <div className="w-full">
      <div className="w-full">
        <img src={Avatar} className="h-400 w-full" alt="" />
      </div>
      {/* <div>
        <p className="text-md text-neutral-500 py-4 whitespace_prewrap">
          {getValue(17)}
        </p>
      </div> */}
      <div className="py-4">
        <h2 className="text-xl font-medium text-neutral-600">
          <span className=""> {getValue(17)}</span>
        </h2>
      </div>
      <div className="flex py-8">
        {/* 1st */}
        <div className=" w-1/3 pr-4">
          <div className="flex">
            <div>
              <img src={CheckMarkImg} alt="" className="w-7 h-7" />
            </div>
            <span className="px-2 text-base md:text-lg text-neutral-500 dark:text-neutral-400 benefits-checkmark-title">
              {getValue(18)}
            </span>
          </div>
          <div>
            <p className="whitespace_prewrap text-md text-neutral-500 py-4">
              {getValue(21)}
            </p>
          </div>
        </div>
        {/* 2nd */}
        <div className=" w-1/3 px-4">
          <div className="flex">
            <div>
              <img src={CheckMarkImg} alt="" className="w-7 h-7" />
            </div>
            <span className="px-2 text-base md:text-lg text-neutral-500 dark:text-neutral-400 benefits-checkmark-title">
              {getValue(19)}
            </span>
          </div>
          <div>
            <p className="text-md text-neutral-500 py-4 whitespace_prewrap">
              {getValue(22)}
            </p>
          </div>
        </div>
        {/* 3rd */}
        <div className=" w-1/3 px-4">
          <div className="flex">
            <div>
              <img src={CheckMarkImg} alt="" className="w-7 h-7" />
            </div>
            <span className="px-2 text-base md:text-lg text-neutral-500 dark:text-neutral-400 benefits-checkmark-title">
              {getValue(20)}
            </span>
          </div>
          <div>
            <p className="whitespace_prewrap text-md text-neutral-500 py-4">
              {getValue(23)}
            </p>
          </div>
        </div>
      </div>
      <div className="py-4">
        <h2 className="text-md text-neutral-500">{getValue(34)}</h2>
      </div>
      {/* <div className="flex py-8">
       
        <div className=" w-1/3 pr-4">
          <div className="flex">
            <span className="text-greenBlue-gradient text-5xl font-medium text-neutral-500 dark:text-neutral-400">
              3%
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">{getValue(27)}</span>
            </h2>
          </div>
        </div>
   
        <div className=" w-1/3 px-4">
          <div className="flex">
            <span className="text-greenBlue-gradient text-5xl font-medium text-neutral-500 dark:text-neutral-400">
              5%
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4 ">
              <span className="whitespace_prewrap">{getValue(28)}</span>
            </h2>
          </div>
        </div>

        <div className=" w-1/3 px-4">
          <div className="flex">
            <span className="text-greenBlue-gradient text-5xl font-medium text-neutral-500 dark:text-neutral-400">
              6%
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="whitespace_prewrap">{getValue(29)}</span>
            </h2>
          </div>
        </div>
      </div> */}
      <div className="pt-4 flex justify-center items-center">
        <SectionCalculateBenefit
          title={
            getValue ? getValue(109) : "How we can help you to reach label A ?"
          }
          searchText={getValue ? getValue(25) : "Search Address"}
          buttonText={getValue ? getValue(26) : "Mijn diagnose"}
          modelText={getValue ? getValue(197) : ""}
        />
      </div>
      {/* <div className="container relative py-16 mt-0 w-2/3">
        <SectionHomeTestimonials getValue={[]} />
      </div> */}
    </div>
  );
}

export default withRouter(Benefits);
