import Avatar from "images/renovations/banner/3.png";
import SectionHomeTestimonials from "components/SectionHomeTestimonials/SectionHomeTestimonials";
import SectionCalculateBenefit from "components/SectionCalculateBenefits/SectionCalculateBenefits";
import Roof_Attic from "images/resultPage/renovations/roof_attic@2x.png";
import Roof_Exterior from "images/renovations_images/roof_exterior.png";
import Roof_Interior from "images/renovations_images/roof_interior.png";
import { withRouter } from "react-router";

function Benefits(props: any) {
  let { getValue } = props;
  return (
    <div className="w-full">
      <div className="w-full">
        <img src={Avatar} className="h-400 w-full" alt="" />
      </div>
      <div>
        <h2 className="text-xl text-neutral-600 py-4">
          <span className="whitespace_prewrap">{getValue(10)}</span>
        </h2>
      </div>
      <div>
        <p className="text-md text-neutral-500 py-4 whitespace_prewrap">
          {getValue(11)}
        </p>
      </div>
      <div className="flex py-8">
        {/* 1st */}
        <div className=" w-1/2 pr-4">
          <div className="flex">
            <span className="text-base font-bold  md:text-lg text-neutral-500 dark:text-neutral-400">
              {getValue(12)}
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4 whitespace_prewrap">
              {getValue(13)}
            </h2>
          </div>
        </div>
        {/* 2nd */}
        <div className=" w-1/2">
          <div className="flex justify-center full-width-responsive">
            <div className="w-full">
              <div>
                <img src={Roof_Exterior} alt="" className="h-40" />
              </div>
              <div>
                <h4 className="text-md text-neutral-500 py-4 text-left">
                  {getValue(15)}
                </h4>
              </div>
            </div>
            <div>
              <div className="w-full">
                <img src={Roof_Interior} alt="" className="h-40" />
              </div>
              <div>
                <h4 className="text-md text-neutral-500 py-4 left">
                  {getValue(18)}
                </h4>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div>
              <img src={Roof_Attic} alt="" className="h-40" />
            </div>
            <div>
              <h4 className="text-md text-neutral-500 py-4 text-left">
                {getValue(138)}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className={getValue(16) && "flex py-8"}>
        {/* 1st */}
        <div className=" w-1/2 pr-4">
         {getValue(16) && <div className="flex">
            <span className="text-base font-bold md:text-lg text-neutral-500 dark:text-neutral-400">
              {getValue(16)}
            </span>
          </div>}

          {getValue(17) && <div>
            <h2 className="text-md text-neutral-500 py-4">{getValue(17)}</h2>
          </div>}
        </div>
        {/* 2nd */}
        {/* <div className=" w-1/2 px-4">
          <div className="flex justify-center">
            <div>
              <div>
                <img src={Roof_Interior} alt="" className="h-40" />
              </div>
              <div>
                <h4 className="text-md text-neutral-500 py-4 text-center">
                  {getValue(18)}
                </h4>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className={getValue(60) && "flex py-8"}>
        {/* 1st */}
        <div className=" w-1/2 pr-4">
          {getValue(60) && <div className="flex">
            <span className="text-base font-bold md:text-lg text-neutral-500 dark:text-neutral-400">
              {getValue(60)}
            </span>
          </div>}
          {getValue(61) && <div>
            <h2 className="text-md text-neutral-500 py-4 whitespace_prewrap">
              {getValue(61)}
            </h2>
          </div>}
        </div>
        {/* 2nd */}
        {/* <div className=" w-1/2 px-4">
          <div className="flex justify-center">
            <div>
              <div>
                <img src={Roof_Attic} alt="" className="h-40" />
              </div>
              <div>
                <h4 className="text-md text-neutral-500 py-4 text-center whitespace_prewrap">
                  {getValue(138)}
                </h4>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="flex py-8"> */}
        {/* 1st */}
        {/* <div className=" w-1/2 pr-4">
          <div className="flex">
            <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
              title
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4">
              <span className="">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </span>
            </h2>
          </div>
        </div> */}
        {/* 2nd */}
        {/* <div className=" w-1/2 px-4">
          <div className="flex">
            <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
              title
            </span>
          </div>
          <div>
            <h2 className="text-md text-neutral-500 py-4 ">
              <span className="">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </span>
            </h2>
          </div>
        </div> */}
      {/* </div> */}
      <div className="flex justify-center items-center">
        <SectionCalculateBenefit
          title={
            getValue ? getValue(62) : "How we can help you to reach label A ?"
          }
          searchText={getValue ? getValue(65) : "Search Address"}
          buttonText={getValue ? getValue(63) : "Mijn diagnose"}
          modelText={getValue ? getValue(146) : ""}
        />
      </div>
      {/* <div className="container relative py-16 mt-0 w-2/3">
        <SectionHomeTestimonials getValue={[]} />
      </div> */}
    </div>
  );
}

export default withRouter(Benefits);
