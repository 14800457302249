import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Slider, Checkbox, Radio, Button, Tooltip, Input } from "antd";
import { UpdateBuildingRefinementCall3ByService } from "services/ResultPage";
import { GetContentByService } from "../../services/homepage";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { updateRenovations } from "./constant";
import { renovationPostProcessingForPackB } from "../../containers/Results/constants";
import { Markup } from "interweave";

const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

const Modal = (props: any) => {
  let {
    isOpenModal,
    closeModal,
    allRenovationRecord,
    userData,
    setActiveModal,
    setIsOpenModal,
    setRefinementCall3Record,
    setLoader,
    setHouseCallRecordUpdated,
    houseCallRecordUpdated,
    //
    setAllUserSelectedData,
    setEpcA,
    renovationsInvestmentPrice,
    defaultRenovations,
    setRenovations,
    userModalRenovations,
    houseDetails,
    setUserExistRenovations,
    openModal,
    setSelectedUserCombination,
    setSelectedPack,
    setFutureEpc,
    setCustomStars,
    setIsCallWaiting,
    setDownloadedRecord,
  } = props;

  const marks = {
    1900: "<1900",
    2021: "2021",
  };
  const marks2 = {
    0: "1",
    25: "2",
    50: "3",
    75: "4",
    100: "5+",
  };
  const convertValueToSteps = (value: any) => {
    if (value === 0) {
      return 1;
    }
    if (value === 25) {
      return 2;
    }
    if (value === 50) {
      return 3;
    }
    if (value === 75) {
      return 4;
    }
    if (value === 100) {
      return 5;
    }
  };
  const [sectionContent, setSectionContent]: any = useState([]);
  useEffect(() => {
    let cityId: any = { id: 167 };
    GetContentByService(cityId)
      .then((response) => {
        setSectionContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Method to return value through index.
  const getValue = (index: number): any => {
    return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
      <Markup content={sectionContent[index]?.value} />
    ) : (
      ""
    );
  };

  const [buildingValues, setBuildingValues]: any = useState({
    building_type:
      allRenovationRecord && allRenovationRecord?.building_type !== undefined
        ? allRenovationRecord?.building_type
        : allRenovationRecord?.builiding_type !== null
        ? allRenovationRecord?.builiding_type
        : "",
    construction_year:
      allRenovationRecord?.construction_year !== null
        ? parseInt(allRenovationRecord?.construction_year)
        : 0,
    floors:
      allRenovationRecord?.floors !== null
        ? (Number(allRenovationRecord?.floors) === 1 && 0) ||
          (Number(allRenovationRecord?.floors) === 2 && 25) ||
          (Number(allRenovationRecord?.floors) === 3 && 50) ||
          (Number(allRenovationRecord?.floors) === 4 && 75) ||
          (Number(allRenovationRecord?.floors) >= 5 && 100)
        : 0,
    residents:
      allRenovationRecord?.residents !== undefined
        ? (Number(allRenovationRecord?.residents) === 1 && 0) ||
          (Number(allRenovationRecord?.residents) === 2 && 25) ||
          (Number(allRenovationRecord?.residents) === 3 && 50) ||
          (Number(allRenovationRecord?.residents) === 4 && 75) ||
          (Number(allRenovationRecord?.residents) >= 5 && 100)
        : // default
        allRenovationRecord?.num_residents !== null
        ? (Number(allRenovationRecord?.num_residents) === 1 && 0) ||
          (Number(allRenovationRecord?.num_residents) === 2 && 25) ||
          (Number(allRenovationRecord?.num_residents) === 3 && 50) ||
          (Number(allRenovationRecord?.num_residents) === 4 && 75) ||
          (Number(allRenovationRecord?.num_residents) >= 5 && 100)
        : 0,
    has_basement:
      allRenovationRecord?.has_basement !== null &&
      allRenovationRecord?.has_basement === 1
        ? allRenovationRecord?.has_basement
        : 0,
    energyConsumer: [],
    has_attic: 0,
  });

  const submitRenovationData = () => {
    setIsCallWaiting(true);
    let house_payload: any = {
      building_type: buildingValues?.building_type,
      construction_year: buildingValues?.construction_year,
      floors: convertValueToSteps(buildingValues?.floors),
      residents: convertValueToSteps(buildingValues?.residents),
      has_big_energy_consumers:
        buildingValues?.buildingValues?.length !== 0 ? true : false,
      has_basement: buildingValues?.has_basement,
      building_id: allRenovationRecord?.building_id,
      floor_area: allRenovationRecord?.floor_area,
    };

    if (userData?.id != undefined) {
      house_payload.userId = userData.id;
    } else {
      const temp: any = localStorage.getItem("user_temporary_id");
      const local_storage: any = JSON.parse(temp);
      house_payload.user_temporary_id =
        local_storage[allRenovationRecord?.building_id]["user_temporary_id"];
    }

    UpdateBuildingRefinementCall3ByService(house_payload)
      .then((response: any) => {
        setIsCallWaiting(false);
        setActiveModal("call2&3");
        // to update the state to get current data from response and to show inside form  using this state condition
        let duplicate = { ...houseCallRecordUpdated };
        duplicate.leftSide = "updated";
        setHouseCallRecordUpdated(duplicate);
        setRefinementCall3Record(response?.data?.data?.building_refinements);
        // userRenovations
        let data = {
          response: {
            data: [response.data.data],
          },
        };
        let downloadResponse = {
          call_three_input_request: response?.data?.call_three_input_request,
          callThreeResponse: response?.data?.callThreeResponse,
        };
        setDownloadedRecord(downloadResponse);
        // var zip = new JSZip();
        // zip.file("Call3-Response.txt", JSON.stringify(downloadResponse));
        // zip.generateAsync({ type: "blob" }).then(function (content) {
        //   saveAs(content, "call-3");
        // });
        let callzeroHouseRecord = {
          data: [houseDetails],
        };
        updateRenovations(
          data?.response,
          userModalRenovations,
          callzeroHouseRecord,
          setAllUserSelectedData,
          setEpcA,
          renovationPostProcessingForPackB,
          renovationsInvestmentPrice,
          defaultRenovations,
          setRenovations,
          setUserExistRenovations,
          setSelectedUserCombination,
          response?.data?.data?.renovation_refinements,
          setFutureEpc,
          setCustomStars,
          setSelectedPack
        );
        // updateRenovations(data?.response, userModalRenovations);
      })
      .catch((err: any) => {
        setIsCallWaiting(false);
      });
    closeModal();
  };

  const options = [
    { label: getValue(144), value: "pool" },
    { label: getValue(145), value: "electric" },
    { label: getValue(146), value: "sauna" },
  ];

  console.log("buildingValues", buildingValues);

  return (
    <div>
      <Transition appear show={isOpenModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto result_common_model_container"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full refinement_house_modal_one_container">
                <div className="inline-flex flex-col w-full lg:w-1/2 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-sm m-0 text-left text-gray-500"
                      id="headlessui-dialog-title-70"
                    >
                      {getValue(134)}
                    </h3>
                    <span className="absolute right-3 top-3">
                      <ButtonClose onClick={closeModal} />
                    </span>
                  </div>
                  <div className="px-8 py-4 w-full">
                    <span className="block text-sm mt-2 text-neutral-500 dark:text-neutral-400">
                      {getValue(135)}
                    </span>
                  </div>
                  <div className="relative flex bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-sm m-0 text-left text-gray-500"
                      id="headlessui-dialog-title-70"
                    >
                      {getValue(136)}
                    </h3>
                  </div>
                  <div className="px-6 py-4">
                    <RadioGroup
                      onChange={(e) => {
                        let duplicate = { ...buildingValues };
                        duplicate.building_type = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                      value={buildingValues?.building_type}
                    >
                      <div>
                        <Radio value={"terraced_building"}>
                          {getValue(139)}
                        </Radio>
                      </div>
                      <div className="py-2">
                        <Radio value="detached_building">{getValue(137)}</Radio>
                      </div>
                      <div>
                        <Radio value="semi_detached_building">
                          {getValue(138)}
                        </Radio>
                      </div>
                    </RadioGroup>
                  </div>
                  <div className="relative flex bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-sm m-0 text-left text-gray-500"
                      id="headlessui-dialog-title-70"
                    >
                      {getValue(140)}
                    </h3>
                  </div>
                  <div className="space-y-6">
                    <div className="px-8">
                      <div className="py-4 w-full">
                        {/* <Tooltip title={buildingValues?.construction_year}> */}
                        <div className="flex justify-center">
                          <div className="w-32">
                            <Input
                              className="text-center"
                              disabled
                              value={buildingValues?.construction_year}
                            />
                          </div>
                        </div>
                        <Slider
                          tooltipVisible={false}
                          marks={marks}
                          min={1900}
                          max={2021}
                          value={buildingValues?.construction_year}
                          onChange={(e: any) => {
                            let duplicate = { ...buildingValues };
                            duplicate.construction_year = e;
                            setBuildingValues(duplicate);
                          }}
                          defaultValue={buildingValues?.construction_year}
                        />
                        {/* </Tooltip> */}
                      </div>
                    </div>
                  </div>
                  <div className="relative flex bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-sm m-0 text-left text-gray-500"
                      id="headlessui-dialog-title-70"
                    >
                      {getValue(141)}
                    </h3>
                  </div>
                  <div className="space-y-6">
                    <div className="px-8">
                      <div className="py-4 w-full">
                        <Slider
                          marks={marks2}
                          tipFormatter={null}
                          value={buildingValues?.floors}
                          onChange={(e: any) => {
                            let duplicate: any = { ...buildingValues };
                            duplicate.floors = e;
                            setBuildingValues(duplicate);
                          }}
                          step={null}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative flex bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-sm m-0 text-left text-gray-500"
                      id="headlessui-dialog-title-70"
                    >
                      {getValue(142)}
                    </h3>
                  </div>
                  <div className="space-y-6">
                    <div className="px-8">
                      <div className="py-4 w-full">
                        <Slider
                          marks={marks2}
                          value={buildingValues?.residents}
                          tipFormatter={null}
                          onChange={(e: any) => {
                            let duplicate: any = { ...buildingValues };
                            duplicate.residents = e;
                            setBuildingValues(duplicate);
                          }}
                          step={null}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative flex bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-sm m-0 text-left text-gray-500"
                      id="headlessui-dialog-title-70"
                    >
                      {getValue(143)}
                    </h3>
                  </div>
                  <div className="px-6 py-4">
                    <div>
                      <CheckboxGroup
                        options={options}
                        className="energyConsumer_container"
                        value={buildingValues?.energyConsumer}
                        onChange={(e: any) => {
                          let duplicate: any = { ...buildingValues };
                          duplicate.energyConsumer = e;
                          setBuildingValues(duplicate);
                        }}
                      ></CheckboxGroup>
                    </div>
                  </div>
                  <div className="relative flex bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-sm m-0 text-left text-gray-500"
                      id="headlessui-dialog-title-70"
                    >
                      {getValue(147)}
                    </h3>
                  </div>
                  <div className="px-6 py-4">
                    <RadioGroup
                      onChange={(e: any) => {
                        let duplicate: any = { ...buildingValues };
                        duplicate.has_basement = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                      value={buildingValues?.has_basement}
                    >
                      <div>
                        <Radio value={1}>{getValue(148)}</Radio>
                      </div>
                      <div className="py-2">
                        <Radio value={0}>{getValue(149)}</Radio>
                      </div>
                    </RadioGroup>
                  </div>
                  <div className="relative flex bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-sm m-0 text-left text-gray-500"
                      id="headlessui-dialog-title-70"
                    >
                      {getValue(150)}
                    </h3>
                  </div>
                  <div className="px-6 py-4">
                    <RadioGroup
                      value={buildingValues?.has_attic}
                      onChange={(e: any) => {
                        let duplicate = { ...buildingValues };
                        duplicate.has_attic = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                    >
                      <div>
                        <Radio value={1}>{getValue(151)}</Radio>
                      </div>
                      <div className="py-2">
                        <Radio value={0}>{getValue(152)}</Radio>
                      </div>
                    </RadioGroup>
                  </div>
                  <div className="px-6 py-4 flex justify-between items-center border-b border-neutral-100 bg-neutral-100 dark:border-neutral-800 text-center">
                    <div className="flex items-center">
                      <Button
                        className="rounded-4xl mx-2"
                        size={"large"}
                        onClick={() => closeModal()}
                      >
                        {getValue(289)}
                      </Button>
                    </div>
                    <div>
                      <p className="text-red-500 m-0">{getValue(282)}</p>
                    </div>
                    <Button
                      type="primary"
                      size={"large"}
                      onClick={submitRenovationData}
                    >
                      {getValue(153)}
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
export default Modal;
