import React, { FC, useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { RenocityBenefitsType } from "data/types";
import ncNanoId from "utils/ncNanoId";
import CardCategory6 from "components/CardCategory6/CardCategory6";
import NextPrev from "shared/NextPrev/NextPrev";
import Card5 from "images/landingPage/Group-12.png";
import Card1 from "images/landingPage/Group-13.png";
import Card2 from "images/landingPage/Group-14.png";
import Card3 from "images/landingPage/Group-15.png";
import Card4 from "images/landingPage/Group-16.png";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  getValue?: any;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  className = "",
  itemClassName = "",
  itemPerRow = 4 | 5,
  categoryCardType = "card3",
  sliderStyle = "style1",
  getValue,
}) => {
  const [UNIQUE_CLASS, setUNIQUE_CLASS]=useState("glide_" + ncNanoId());
  const RENOCITY_BENEFITS: RenocityBenefitsType[] = [
    {
      id: "1",
      href: "#",
      name: getValue(21),
      title: getValue(21),
      hoverText: getValue(22),
      taxonomy: "category",
      count: 17288,
      thumbnail: Card5,
    },
    {
      id: "3",
      href: "#",
      name: getValue(23),
      title: getValue(23),
      hoverText: getValue(25),
      taxonomy: "category",
      count: 2118,
      thumbnail: Card1,
    },
    {
      id: "4",
      href: "#",
      name: getValue(26),
      title: getValue(26),
      hoverText: getValue(28),
      taxonomy: "category",
      count: 36612,
      thumbnail: Card2,
    },
    {
      id: "4",
      href: "#",
      name: getValue(29),
      title: getValue(29),
      hoverText: getValue(31),
      taxonomy: "category",
      count: 17288,
      thumbnail: Card3,
    },
    {
      id: "5",
      href: "#",
      name: getValue(70),
      title: getValue(70),
      hoverText: getValue(71),
      taxonomy: "category",
      count: 188288,
      thumbnail: Card4,
    },
  ];
  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      new Glide(`.${UNIQUE_CLASS}`, {
        perView: itemPerRow,
        gap: 32,
        bound: true,
        breakpoints: {
          1280: {
            perView: itemPerRow,
          },
          1024: {
            gap: 20,
            perView: itemPerRow,
          },
          768: {
            gap: 20,
            perView: itemPerRow - 3,
          },
          640: {
            gap: 20,
            perView: itemPerRow - 3,
          },
          500: {
            gap: 20,
            perView: 1,
          },
        },
      }).mount();
    }
  }, []);

  const renderCard = (item: RenocityBenefitsType, index: number) => {
    switch (categoryCardType) {
      case "card5":
        return <CardCategory6 taxonomy={item} index={index} />;
      default:
        return <CardCategory6 taxonomy={item} index={index} />;
    }
  };

  return (
    <div
      className={`nc-SectionSliderNewCategories timeline_container ${className}`}
    >
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading desc={""} isCenter>
          {getValue(12)}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {RENOCITY_BENEFITS.map((item, index) => (
              <li
                key={index}
                className={`glide__slide ${itemClassName} ${
                  index % 2 && "mt-8"
                }`}
              >
                {renderCard(item, index)}
              </li>
            ))}
          </ul>
        </div>
        <div className="pt-10">
          <div className="flex justify-center timeline_container">
            <div className="image_timeLine1">{getValue(72)}</div>
            <div className="image_timeLine2">{getValue(73)}</div>
            <div className="image_timeLine2">{getValue(74)}</div>
          </div>
        </div>
        <NextPrev className="justify-center mt-16" />
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
